import { createAction, props } from '@ngrx/store';
import { ApiError } from 'src/domain/api-error';
import { HomeVM } from '@shop/pages/home/home.vm';
import { HeroVM } from '@shop/pages/home/components/hero/hero.vm';
import { DatoApiError } from '../../../../../domain/dato-api-error';

const feature = '[Home]';

export const getHomepageData = createAction(`${feature} get homepage data`);
export const getHomepageDataSuccess = createAction(`${feature} get homepage data success`, props<{ homepage: HomeVM }>());
export const getHomepageDataError = createAction(`${feature} get homepage data error`, props<{ error: ApiError }>());

export const getHeroData = createAction(`${feature} get hero data`);
export const getHeroDataSuccess = createAction(`${feature} get hero data success`, props<{ hero: HeroVM }>());
export const getHeroDataError = createAction(`${feature} get hero data error`, props<{ error: DatoApiError }>());
