export interface DatoConfigurationParameters {
  datoUrl: string;
  datoReadToken: string;
}

export class DatoConfiguration {
  public datoUrl?: string;
  public datoReadToken?: string;
  constructor(configurationParameters: DatoConfigurationParameters) {
    this.datoUrl = configurationParameters.datoUrl;
    this.datoReadToken = configurationParameters.datoReadToken;
  }
}
