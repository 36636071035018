import { ActionReducer, MetaReducer } from '@ngrx/store';
import { Keys, localStorageSync } from 'ngrx-store-localstorage';

export const localStorageSyncReducer = (): MetaReducer<unknown> => {
  const keys: Keys = [{ cart: ['cartId'] }, { auth: ['isSecondFactorAuthenticated'] }];

  return (reducer: ActionReducer<unknown>): ActionReducer<unknown> => {
    return localStorageSync({
      keys,
      rehydrate: true
    })(reducer);
  };
};
