import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollSectionDirective } from '@shared/directives/scroll-manager/scroll-section.directive';
import { ScrollAnchorDirective } from '@shared/directives/scroll-manager/scroll-anchor.directive';
import { ScrollManagerService } from '@shared/directives/scroll-manager/scroll-manager.service';

@NgModule({
  declarations: [ScrollSectionDirective, ScrollAnchorDirective],
  imports: [CommonModule],
  exports: [ScrollSectionDirective, ScrollAnchorDirective]
})
export class ScrollManagerModule {
  public static forRoot(): ModuleWithProviders<ScrollManagerModule> {
    return {
      ngModule: ScrollManagerModule,
      providers: [ScrollManagerService]
    };
  }
}
