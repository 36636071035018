<!-- eslint-disable @angular-eslint/template/use-track-by-function -->
<nav
  *ngrxLet="breakpointService.upMd$ as upMd"
  class="d-flex flex-column flex-lg-row align-items-lg-center py-3 px-1-5 p-lg-0 gap-2 gap-md-1 h-100"
>
  @if (!selectedMenuItem()) {
    @if (!upMd) {
      <div class="color-text500 body-large-bold px-3 pt-2">{{ 'navigation.menu-title' | translate }}</div>
      @if (!selectedMenuItem()) {
        <hr class="mx-3 color-text200" />
      }
    }
    @for (navigationItem of items; track navigationItem.id) {
      @if (navigationItem.type === 'DynamicMenuEntry' && upMd) {
        <button
          ddButton
          class="navigation-item body-small-regular rounded-1 px-1-5"
          #button
          level="tertiary"
          [ngClass]="{ 'font-weight-bold': selectedMenuItemId === navigationItem.id }"
          (mouseenter)="setSelectedMenuItemWithEmission({ elementRef: button.elementRef, menuItem: navigationItem }, true)"
        >
          {{ navigationItem.title }}
        </button>
      } @else if (navigationItem.type === 'DynamicMenuEntry') {
        <button
          ddButton
          class="navigation-item body-small-regular rounded-1 d-flex justify-content-start"
          level="tertiary"
          (click)="setSelectedMenuItem(navigationItem)"
        >
          <span class="flex-grow-1 dynamic-menu-entry">
            <div class="d-flex align-items-center gap-1 flex-grow-1 justify-content-between">
              {{ navigationItem.title }}
              <svg-icon key="arrow-right" class="color-text500" size="md"></svg-icon>
            </div>
          </span>
        </button>
      }
      @if (navigationItem.type === 'NavigationItem' && upMd) {
        <a
          class="navigation-item body-small-regular rounded-1"
          [routerLink]="navigationItem.routerLink | localizeUrl"
          [routerLinkActiveOptions]="{ exact: !!navigationItem.exactMatch }"
          routerLinkActive="font-weight-bold"
          (click)="closeDropdown()"
          [appPushTagOnClick]="{
            event: TagEvent.HeaderMenuClick,
            menu_name: navigationItem.nameTranslationKey
          }"
        >
          <span class="flex-grow-1 px-1-5">
            {{ navigationItem.nameTranslationKey | translate }}
          </span>
        </a>
      } @else if (navigationItem.type === 'NavigationItem') {
        <a
          class="navigation-item body-small-regular rounded-1"
          [routerLink]="navigationItem.routerLink | localizeUrl"
          [routerLinkActiveOptions]="{ exact: !!navigationItem.exactMatch }"
          routerLinkActive="font-weight-bold"
          (click)="closeDropdown()"
          [appPushTagOnClick]="{
            event: TagEvent.HeaderMenuClick,
            menu_name: navigationItem.nameTranslationKey | translate
          }"
        >
          <div class="d-flex align-items-center gap-1 flex-grow-1 justify-content-between px-3">
            {{ navigationItem.nameTranslationKey | translate }}
            <svg-icon key="arrow-right" class="color-text500" size="md"></svg-icon>
          </div>
        </a>
      }
    }
  }

  @if (selectedMenuItem(); as selectedMenuItem) {
    <app-dynamic-menu-item [menu]="selectedMenuItem" (closeMenu)="setSelectedMenuItem()"></app-dynamic-menu-item>
  }

  <ng-container *ngIf="extraItems && !selectedMenuItem()">
    <hr class="my-1-5 mx-3 color-text200" />
    <ng-container *ngFor="let extraItem of extraItems">
      <ng-container *ngTemplateOutlet="extraItem"></ng-container>
    </ng-container>
  </ng-container>
</nav>
