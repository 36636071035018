import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LanguageService, SUPPORTED_LANGUAGES } from '@core/modules/language/language.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageParamGuard {
  constructor(
    private readonly router: Router,
    private readonly languageService: LanguageService,
    private readonly translateService: TranslateService
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    return this.verifyOrAppendLanguage(route, state);
  }

  public verifyOrAppendLanguage(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const unverifiedLanguageId = route.paramMap.get('languageId');

    let verifiedLanguageId = '';

    const matchingLang = SUPPORTED_LANGUAGES.find((lang) => {
      return lang === unverifiedLanguageId || unverifiedLanguageId?.startsWith(lang);
    });

    verifiedLanguageId = matchingLang ?? this.translateService.currentLang;

    if (unverifiedLanguageId === verifiedLanguageId) {
      return true;
    } else {
      this.languageService.switchLanguage(verifiedLanguageId, false);
      const params = state.url.split('?')[1];
      const queryParams = params
        ? params.split('&').reduce((acc, item) => {
            const [key, value] = item.split('=');
            return { ...acc, [key]: value };
          }, {})
        : {};

      const pathName = `/${verifiedLanguageId}${state.url.split('?')[0]}`;

      return this.router.createUrlTree([pathName], { queryParams });
    }
  }
}
