import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations';
import { ExpandState } from '../enums/expand-state.enum';

const animationTiming = '150ms ease-in-out';

// TODO move accordion to this.
export const expandAnimation: AnimationTriggerMetadata = trigger('expandAnimation', [
  state(
    ExpandState.Expanded,
    style({
      height: '*',
      opacity: 1
    })
  ),
  state(
    ExpandState.Collapsed,
    style({
      height: 0,
      opacity: 0
    })
  ),
  transition(`${ExpandState.Expanded} <=> ${ExpandState.Collapsed}`, [animate(animationTiming)])
]);
