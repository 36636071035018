import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeUrlPipe } from '../../pipes/localize-url/localize-url.pipe';
import { LocalizedTextComponent } from './components/localized-text/localized-text.component';

@NgModule({
  declarations: [LocalizedTextComponent],
  imports: [CommonModule, RouterModule, TranslateModule, LocalizeUrlPipe],
  exports: [LocalizedTextComponent]
})
export class ComplexTranslationModule {}
