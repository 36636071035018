import { Injectable } from '@angular/core';
import { DatoService } from '@core/services/dato/dato.service';
import { gql } from '@apollo/client';
import { map, Observable, throwError } from 'rxjs';
import { PageService } from '@shared/services/page.service';
import { MediaBlockFragment } from '@core/services/dato/fragments/media-block.fragment';
import { DatoCampaignPage } from '@core/services/dato/interfaces/campaign-page.interface';
import { ProductWithImagesFragment } from '@core/services/dato/fragments/product.fragment';
import { CtaFragment } from '@core/services/dato/fragments/cta.fragment';

@Injectable({
  providedIn: 'root'
})
export class DatoCampaignPageDataService {
  constructor(
    private readonly datoService: DatoService,
    private readonly pageService: PageService
  ) {}

  public getCampaignPageSlugs$(locale: string = 'en', disableCache = false): Observable<string[]> {
    return this.datoService
      .get$<{ allCampaignPages: { slug: string }[] }>(
        gql`
          query getCampaignPageSlugs {
            allCampaignPages(locale: ${locale}) {
              slug
            }
          }
        `,
        disableCache,
        { locale }
      )
      .pipe(
        map((res) => {
          return res.allCampaignPages.map((campaignPage) => campaignPage.slug);
        })
      );
  }

  public getCampaignPageData$(locale: string = 'en', slug: string, disableCache = false): Observable<DatoCampaignPage> {
    return this.datoService
      .get$<{ campaignPage: DatoCampaignPage }>(
        gql`
          ${MediaBlockFragment}
          ${ProductWithImagesFragment}
          ${CtaFragment}
          query getCampaignPage {
            campaignPage(locale: ${locale}, filter: { slug: {eq: "${slug}"} })  {
              id
              title
              breadcrumb
              slug
              locale @client
              recommendedProductsTitle
              recommendedProducts {
                ...ProductWithImage
              }
              recommendedProductsCta {
                ...Cta
              }
              mediaBlocks {
                ...MediaBlock
                image {
                  url
                  alt
                  responsiveImage(imgixParams: { fit: crop, ar:"5:4.5", h:420, crop: focalpoint}, sizes: "(max-width: 786px) 400px, 100%") {
                    src
                    srcSet
                    sizes
                    webpSrcSet
                  }
                }
              }
              meta: _seoMetaTags {
                content
                attributes
                tag
              }
            }
          }
        `,
        disableCache,
        { locale }
      )
      .pipe(
        map((res) => {
          if (res?.campaignPage) {
            this.pageService.setMeta(res.campaignPage.meta);
          }
          if (!res?.campaignPage) {
            throwError(() => new Error('No campaign page found'));
          }
          return res.campaignPage;
        })
      );
  }
}
