import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKeyReviews, FeatureStateReviews } from './reviews.reducer';

const selectReviewsFeature = createFeatureSelector<FeatureStateReviews>(featureKeyReviews);

export const selectAreStoreReviewsLoading = createSelector(selectReviewsFeature, (state) => {
  return state.storeReviews.isLoading;
});

export const selectStoreReviews = createSelector(selectReviewsFeature, (state) => {
  return state.storeReviews.data ?? undefined;
});
