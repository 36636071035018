<button ddButton class="position-absolute top-0 end-0 mt-3 me-3" size="small" type="icon" level="tertiary" (click)="closeDialog()">
  <svg-icon key="close" fontSize="24px"></svg-icon>
</button>

<div *ngrxLet="profile$ as profile" class="d-flex flex-column align-items-center w-100">
  <ng-container *ngrxLet="errorNotification$ as errorNotification">
    <div *ngIf="errorNotification" class="text-center whitespace-prewrap rounded-3 background-error400 px-4 py-3 mb-5 w-100 mt-4 mt-md-0">
      <p class="body-normal mb-0">{{ errorNotification | translate }}</p>
    </div>
  </ng-container>
  <form
    *ngIf="(isSecondFactorAuth$ | async) && !!profile.personalDetails; else firstFactorAuth"
    [formGroup]="secondFactorForm"
    (ngSubmit)="onSubmitSecondFactorForm()"
  >
    <div *ngrxLet="secondFactorAuth$ as secondFactorAuth">
      <div *ngIf="!secondFactorAuth.isAuthenticated; else secondFactorAuthenticated" class="d-flex flex-column align-items-center">
        <ng-container *ngrxLet="secondFactorCodeRequest$ as secondFactorCodeRequest">
          <div
            *ngIf="codeResent$ | async"
            class="text-center whitespace-prewrap rounded-3 background-success300 px-4 py-3 mb-5 w-100 mt-4 mt-md-0"
            [ngClass]="secondFactorCodeRequest.error ? 'background-error400' : 'background-success300'"
          >
            <p class="body-normal mb-0">{{ resentOtpCodeMessage$(secondFactorCodeRequest.error?.status) | async }}</p>
          </div>
        </ng-container>
        <ng-container [ngTemplateOutlet]="profilePicture"></ng-container>
        <h1 class="header-h6 mb-1 text-center">{{ 'auth.one-more-thing' | translate }}</h1>
        <p class="mb-4 color-text400 w-75 text-center">
          {{ 'auth.2fa-text' | translate: { phoneNumber: profile.personalDetails.phoneNumber | phoneObfuscate } }}
        </p>
        <app-otp-input
          [control]="secondFactorForm.controls.code"
          [touch]="!!(secondFactorForm.controls.code.touch$ | async)"
        ></app-otp-input>
        <div class="d-flex justify-content-center align-items-center gap-1 mt-2 body-small">
          <svg-icon key="clock-outline" fontSize="24px"></svg-icon>
          <span>{{ 'auth.code-is-valid-for-minutes' | translate: { minutes: codeValidityTime } }}</span>
        </div>
        <div class="d-flex flex-column mt-4 gap-2 w-100 align-items-center">
          <button ddButton htmlType="submit" class="w-75" size="large" [disabled]="secondFactorAuth.isLoading">
            {{ (secondFactorAuth.isLoading ? 'common.loading' : 'auth.verify') | translate }}
          </button>
          <button ddButton class="w-75" size="large" level="tertiary" (click)="onResendClick($event)">
            {{ 'auth.resend' | translate }}
          </button>
          <button
            ddButton
            class="contact-support-button p-0 body-small-medium"
            size="large"
            level="tertiary"
            htmlType="button"
            (click)="onContactSupport($event)"
          >
            {{ 'auth.contact-support' | translate }}
          </button>
        </div>
      </div>
      <ng-template #secondFactorAuthenticated>
        <div class="d-flex flex-column align-items-center justify-content-center gap-2">
          <div class="d-flex justify-content-center align-items-center rounded-circle background-neutral100 p-2">
            <svg-icon key="sign-ok" fontSize="54px"></svg-icon>
          </div>
          <p class="header-h5 mb-0">{{ 'auth.second-factor-success' | translate }}</p>
          <p class="mb-0">{{ 'auth.you-are-being-redirected' | translate }}</p>
        </div>
      </ng-template>
    </div>
  </form>

  <ng-template #firstFactorAuth>
    <form [formGroup]="form" (ngSubmit)="onSubmitLoginForm()" class="d-flex flex-column align-items-center w-100">
      <ng-container *ngrxLet="auth$ as auth">
        <ng-container [ngTemplateOutlet]="profilePicture"></ng-container>
        <h1 class="header-h6 mb-1">{{ 'auth.login' | translate }}</h1>
        <p class="mb-0 text-center">{{ 'auth.login-text' | translate }}</p>
        <div class="d-flex flex-column gap-5 mt-4 w-100">
          <div *ngIf="auth.error" class="text-center whitespace-prewrap rounded-3 background-error400 px-4 py-2">
            <p class="body-tiny mb-0">{{ 'auth.login-error' | translate }}</p>
          </div>
          <app-input
            type="email"
            placeholder="example@example.nl"
            size="large"
            [label]="'auth.email' | translate"
            [formControl]="form.controls.email"
            [error]="errorMessage$(form.controls.email) | async"
          ></app-input>
          <app-input
            type="password"
            [placeholder]="'auth.password' | translate"
            size="large"
            [label]="'auth.password' | translate"
            [formControl]="form.controls.password"
            [error]="errorMessage$(form.controls.password) | async"
            [suffix]="true"
          ></app-input>
        </div>
        <a
          class="body-tiny align-self-end mt-1 me-3"
          [routerLink]="[RouteSegment.Root, RouteSegment.ForgotPassword]"
          (click)="closeDialog()"
        >
          {{ 'auth.forgot' | translate }}
        </a>
        <div class="d-flex mt-4 align-self-stretch">
          <button ddButton htmlType="submit" class="w-100" size="large" [disabled]="auth.isLoading">
            {{ (auth.isLoading ? 'common.loading' : 'auth.login') | translate }}
          </button>
        </div>
      </ng-container>
    </form>
  </ng-template>
</div>

<ng-template #profilePicture>
  <app-profile-picture class="mb-2" size="128px"></app-profile-picture>
</ng-template>
