/* eslint-disable @angular-eslint/no-host-metadata-property */
import { AnimationEvent } from '@angular/animations';
import { FocusTrap, FocusTrapFactory, InteractivityChecker } from '@angular/cdk/a11y';
import { coerceArray } from '@angular/cdk/coercion';
import { _getFocusedElementPierceShadowDom } from '@angular/cdk/platform';
import { BasePortalOutlet, CdkPortalOutlet, ComponentPortal, DomPortal, TemplatePortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  HostBinding,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { BreakpointService } from '@core/services/breakpoint.service';
import { Subscription } from 'rxjs';
import { bottomSheetAndDialogAnimations } from './bottom-sheet-and-dialog-animations';
import { BottomSheetAndDialogConfig } from './bottom-sheet-and-dialog-config';

@Component({
  selector: 'app-bottom-sheet-and-dialog-container',
  templateUrl: 'bottom-sheet-and-dialog-container.html',
  styleUrls: ['bottom-sheet-and-dialog-container.scss'],
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
  animations: [bottomSheetAndDialogAnimations.bottomSheetState],
  host: {
    class: 'app-bottom-sheet-and-dialog-container',
    tabindex: '-1',
    role: 'dialog',
    'aria-modal': 'true',
    '[attr.aria-label]': 'bottomSheetConfig?.ariaLabel',
    '[@state]': '_animationState',
    '(@state.start)': '_onAnimationStart($event)',
    '(@state.done)': '_onAnimationDone($event)'
  }
})
export class BottomSheetAndDialogContainerComponent extends BasePortalOutlet implements OnInit, OnDestroy {
  /** The portal outlet inside of this container into which the content will be loaded. */
  @ViewChild(CdkPortalOutlet, { static: true }) public _portalOutlet!: CdkPortalOutlet;

  @HostBinding('class.dialog-mode') public dialogMode = false;

  public config?: BottomSheetAndDialogConfig<unknown>;

  /** The state of the bottom sheet animations. */
  public _animationState: 'void' | 'visible' | 'hidden' | 'visibleDialog' = 'void';

  /** Emits whenever the state of the animation changes. */
  public _animationStateChanged = new EventEmitter<AnimationEvent>();

  private readonly _breakpointSubscription: Subscription;

  /** The class that traps and manages focus within the bottom sheet. */
  private _focusTrap!: FocusTrap;

  /** Element that was focused before the bottom sheet was opened. */
  private _elementFocusedBeforeOpened: HTMLElement | null = null;

  /** Server-side rendering-compatible reference to the global document object. */
  private readonly _document: Document;

  /** Whether the component has been destroyed. */
  private _destroyed!: boolean;

  constructor(
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _focusTrapFactory: FocusTrapFactory,
    private readonly _interactivityChecker: InteractivityChecker,
    private readonly _ngZone: NgZone,
    private readonly breakpointService: BreakpointService,
    @Optional() @Inject(DOCUMENT) document: Document,
    /** The bottom sheet configuration. */
    public bottomSheetConfig: BottomSheetAndDialogConfig
  ) {
    super();

    this._document = document;
    this._breakpointSubscription = breakpointService.upMd$.subscribe(() => {
      const shouldBeADialog = this.getDialogMode();

      if (this.dialogMode !== shouldBeADialog) {
        this.dialogMode = shouldBeADialog;
        this._changeDetectorRef.markForCheck();
      }

      if (shouldBeADialog && this._animationState === 'visible') {
        this._animationState = 'visibleDialog';
        this._changeDetectorRef.markForCheck();
      }

      if (!shouldBeADialog && this._animationState === 'visibleDialog') {
        this._animationState = 'visible';
        this._changeDetectorRef.markForCheck();
      }
    });
  }

  @HostBinding('class.no-padding')
  public get noPaddingClass(): boolean {
    return (
      (this.dialogMode && this.config?.noPaddingWhileDialogMode) ||
      (!this.dialogMode && this.config?.noPaddingWhileInBottomSheetMode) ||
      false
    );
  }

  public ngOnInit(): void {
    const shouldBeADialog = this.getDialogMode();
    this.dialogMode = shouldBeADialog;
  }

  /** Attach a component portal as content to this bottom sheet container. */
  public attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
    this._validatePortalAttached();
    this._setPanelClass();
    this._savePreviouslyFocusedElement();
    return this._portalOutlet.attachComponentPortal(portal);
  }

  /** Attach a template portal as content to this bottom sheet container. */
  public attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
    this._validatePortalAttached();
    this._setPanelClass();
    this._savePreviouslyFocusedElement();
    return this._portalOutlet.attachTemplatePortal(portal);
  }

  /**
   * Attaches a DOM portal to the bottom sheet container.
   *
   * @deprecated To be turned into a method.
   * @breaking-change 10.0.0
   */
  public attachDomPortal = (portal: DomPortal): void => {
    this._validatePortalAttached();
    this._setPanelClass();
    this._savePreviouslyFocusedElement();
    return this._portalOutlet.attachDomPortal(portal);
  };

  /** Begin animation of bottom sheet entrance into view. */
  public enter(): void {
    if (!this._destroyed) {
      this._animationState = this.getDialogMode() ? 'visibleDialog' : 'visible';
      this._changeDetectorRef.detectChanges();
    }
  }

  /** Begin animation of the bottom sheet exiting from view. */
  public exit(): void {
    if (!this._destroyed) {
      this._animationState = 'hidden';
      this._changeDetectorRef.markForCheck();
    }
  }

  public ngOnDestroy(): void {
    this._breakpointSubscription.unsubscribe();
    this._destroyed = true;
  }

  public _onAnimationDone(event: AnimationEvent): void {
    if (event.toState === 'hidden') {
      this._restoreFocus();
    } else if (event.toState === 'visible') {
      this._trapFocus();
    }

    this._animationStateChanged.emit(event);
  }

  public _onAnimationStart(event: AnimationEvent): void {
    this._animationStateChanged.emit(event);
  }

  private getDialogMode(): boolean {
    return this.config?.alwaysDialog || (!this.config?.alwaysBottomSheet && this.breakpointService.upMd);
  }

  private _validatePortalAttached() {
    if (this._portalOutlet.hasAttached()) {
      throw new Error('Attempting to attach bottom sheet content after content is already attached');
    }
  }

  private _setPanelClass() {
    const element: HTMLElement = this._elementRef.nativeElement;
    element.classList.add(...coerceArray(this.bottomSheetConfig.panelClass || []));
  }

  /**
   * Focuses the provided element. If the element is not focusable, it will add a tabIndex
   * attribute to forcefully focus it. The attribute is removed after focus is moved.
   *
   * @param element The element to focus.
   */
  private _forceFocus(element: HTMLElement, options?: FocusOptions) {
    if (!this._interactivityChecker.isFocusable(element)) {
      element.tabIndex = -1;
      // The tabindex attribute should be removed to avoid navigating to that element again
      this._ngZone.runOutsideAngular(() => {
        const callback = () => {
          element.removeEventListener('blur', callback);
          element.removeEventListener('mousedown', callback);
          element.removeAttribute('tabindex');
        };

        element.addEventListener('blur', callback);
        element.addEventListener('mousedown', callback);
      });
    }
    element.focus(options);
  }

  /**
   * Focuses the first element that matches the given selector within the focus trap.
   *
   * @param selector The CSS selector for the element to set focus to.
   */
  private _focusByCssSelector(selector: string, options?: FocusOptions) {
    const elementToFocus = this._elementRef.nativeElement.querySelector(selector) as HTMLElement | null;
    if (elementToFocus) {
      this._forceFocus(elementToFocus, options);
    }
  }

  /**
   * Moves the focus inside the focus trap. When autoFocus is not set to 'bottom-sheet',
   * if focus cannot be moved then focus will go to the bottom sheet container.
   */
  private _trapFocus() {
    const element = this._elementRef.nativeElement;

    if (!this._focusTrap) {
      this._focusTrap = this._focusTrapFactory.create(element);
    }

    // If were to attempt to focus immediately, then the content of the bottom sheet would not
    // yet be ready in instances where change detection has to run first. To deal with this,
    // we simply wait for the microtask queue to be empty when setting focus when autoFocus
    // isn't set to bottom sheet. If the element inside the bottom sheet can't be focused,
    // then the container is focused so the user can't tab into other elements behind it.
    switch (this.bottomSheetConfig.autoFocus) {
      case false:
      case 'dialog': {
        const activeElement = _getFocusedElementPierceShadowDom();
        // Ensure that focus is on the bottom sheet container. It's possible that a different
        // component tried to move focus while the open animation was running. See:
        // https://github.com/angular/components/issues/16215. Note that we only want to do this
        // if the focus isn't inside the bottom sheet already, because it's possible that the
        // consumer specified `autoFocus` in order to move focus themselves.
        if (activeElement !== element && !element.contains(activeElement)) {
          element.focus();
        }
        break;
      }
      case true:
      case 'first-tabbable': {
        this._focusTrap.focusInitialElementWhenReady();
        break;
      }
      case 'first-heading': {
        this._focusByCssSelector('h1, h2, h3, h4, h5, h6, [role="heading"]');
        break;
      }
      default: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this._focusByCssSelector(this.bottomSheetConfig.autoFocus!);
        break;
      }
    }
  }

  /** Restores focus to the element that was focused before the bottom sheet was opened. */
  private _restoreFocus() {
    const toFocus = this._elementFocusedBeforeOpened;

    // We need the extra check, because IE can set the `activeElement` to null in some cases.
    if (this.bottomSheetConfig.restoreFocus && toFocus && typeof toFocus.focus === 'function') {
      const activeElement = _getFocusedElementPierceShadowDom();
      const element = this._elementRef.nativeElement;

      // Make sure that focus is still inside the bottom sheet or is on the body (usually because a
      // non-focusable element like the backdrop was clicked) before moving it. It's possible that
      // the consumer moved it themselves before the animation was done, in which case we shouldn't
      // do anything.
      if (!activeElement || activeElement === this._document.body || activeElement === element || element.contains(activeElement)) {
        toFocus.focus();
      }
    }

    if (this._focusTrap) {
      this._focusTrap.destroy();
    }
  }

  /** Saves a reference to the element that was focused before the bottom sheet was opened. */
  private _savePreviouslyFocusedElement() {
    this._elementFocusedBeforeOpened = _getFocusedElementPierceShadowDom();

    // The `focus` method isn't available during server-side rendering.
    if (this._elementRef.nativeElement.focus) {
      this._ngZone.runOutsideAngular(() => {
        Promise.resolve().then(() => this._elementRef.nativeElement.focus());
      });
    }
  }
}
