import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '@core/modules/language/language.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs';

/**
 * A pipe that takes a url from a [routerLink] and returns its localized form containing the current language code.
 *
 * @example
 *  <a [routerLink]="['', 'somewhere'] | localizeUrl">
 */
@UntilDestroy()
@Pipe({
  name: 'localizeUrl',
  pure: false,
  standalone: true
})
export class LocalizeUrlPipe implements PipeTransform {
  private language = '';
  private cachedStringifiedResult = '';
  private cachedResult: string[] = [];

  constructor(
    private readonly languageService: LanguageService,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.languageService.currentLanguage$.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe((lang) => {
      this.language = lang;
      this.cdr.markForCheck();
    });
  }

  public transform(url: string[] | string): string[] | string {
    const urlArray = Array.isArray(url) ? url.slice(1) : url.split('/');
    const result = ['', this.language, ...urlArray];
    const stringifiedResult = JSON.stringify(result);
    if (stringifiedResult !== this.cachedStringifiedResult) {
      this.cachedResult = result.at(-1)?.includes('#') ? result.slice(0, -1) : result;
      this.cachedStringifiedResult = stringifiedResult;
    }
    return this.cachedResult;
  }
}
