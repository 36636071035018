<div
  class="position-relative"
  [ngClass]="{
    error: hasError,
    'input-has-prefix': prefix,
    'input-has-suffix': suffix,
    'input-sm': size === 'small',
    'input-md': size === 'medium',
    'input-lg': size === 'large'
  }"
>
  <label *ngIf="label" title="{{ label }}" (click)="focusInput()" class="form-label body-tiny-medium">
    {{ label }}
    <span class="color-text300" *ngIf="showOptionalLabel && !isRequired">({{ 'common.optional' | translate }})</span>
  </label>
  <div *ngIf="prefix" class="input-prefix">
    <ng-content select="[prefix]"></ng-content>
  </div>
  <select
    #element
    class="form-input"
    [attr.disabled]="(control.disabled$ | async) || null"
    [attr.autocomplete]="autocomplete || null"
    [class.color-text300]="isEmpty"
    [formControl]="control"
  >
    <option *ngIf="(isRequired && isEmpty) || !isRequired" value="" class="color-bg400" [attr.selected]="isEmpty || null" disabled>
      {{ placeholder }}
    </option>
    <ng-container *ngIf="options">
      <option
        *ngFor="let option of options; trackBy: trackByValue"
        value="{{ option.value }}"
        [attr.selected]="control.value === option.value || null"
      >
        {{ option.label }}
      </option>
    </ng-container>
    <ng-content></ng-content>
  </select>
  <div class="input-suffix">
    <button *ngIf="clearable && !isEmpty" class="input-suffix-button" (click)="onClearIconClick()">
      <svg-icon key="close" size="lg"></svg-icon>
    </button>
    <ng-container *ngIf="suffix">
      <ng-content select="[suffix]"></ng-content>
    </ng-container>
    <svg-icon key="arrow-down" [class]="control.disabled ? 'color-text200' : 'color-primary500'" size="lg"></svg-icon>
  </div>

  <small *ngIf="hint || (hasError && error)" class="form-hint body-tiny">
    {{ hasError ? error : hint }}
  </small>
</div>
