import { NewsletterVM } from '@shop/pages/home/components/newsletter/newsletter.vm';
import { DatoNewsletter } from '@core/services/dato/interfaces/newsletter.interface';

export const mapNewsletterContentToNewsletterVM = (newsletter: DatoNewsletter): NewsletterVM => {
  return {
    title: newsletter.newsletterTitle,
    content: newsletter.newsletterContent,
    subscribedTitle: newsletter.newsletterSubscribedTitle,
    subscribedContent: newsletter.newsletterSubscribedContent
  };
};
