<div *ngrxLet="breakpointService.downMd$; let downMd" class="container h-100 d-flex justify-content-between align-items-center">
  <div class="d-flex align-items-center">
    <a
      [routerLink]="[RouteSegment.Root] | localizeUrl"
      class="dd-logo me-md-4 flex-shrink-0"
      [appPushTagOnClick]="{
        event: TagEvent.HeaderMenuClick,
        menu_name: 'Logo - Home'
      }"
    >
      <svg-icon key="dd-logo"></svg-icon>
    </a>
    <app-navigation
      *ngIf="!downMd"
      [items]="navigationItems()"
      [selectedMenuItemId]="selectedItemId()"
      (dynamicMenuOpen)="openDynamicMenu($event)"
    ></app-navigation>
  </div>
  <div class="d-flex align-items-center gap-2 gap-lg-1-5">
    <a
      ddButton
      [class]="breakpointService.downSm ? 'body-tiny-medium' : 'body-normal-medium'"
      [routerLink]="[RouteSegment.Root, RouteSegment.Dashboard] | localizeUrl"
      [size]="breakpointService.downSm ? 'small' : 'normal'"
      level="nav"
      [appPushTagOnClick]="{
        event: TagEvent.HeaderMenuClick,
        menu_name: 'navigation.my-dashboard' | translate
      }"
    >
      {{ 'navigation.my-dashboard' | translate }}
    </a>
    <app-cart></app-cart>
    <app-language-picker
      *ngIf="!downMd"
      (languagePickerOpened)="setDropdownExpandedState(true)"
      (languagePickerClosed)="setDropdownExpandedState(false)"
    ></app-language-picker>
    <button
      *ngIf="downMd"
      #trigger
      ddButton
      [size]="breakpointService.downSm ? 'small' : 'normal'"
      type="icon"
      level="nav"
      class="hamburger-button border-0 ms-1 ms-sm-3"
      [class.opened]="navigationExpanded$ | async"
      (click)="openDropdown()"
    >
      <div class="d-flex flex-column align-items-center">
        <svg-icon [key]="(navigationExpanded$ | async) ? 'close' : 'hamburger-menu'" fontSize="24px"></svg-icon>
        <span class="font-weight-medium hamburger-text">Menu</span>
      </div>
    </button>
  </div>
</div>

<ng-template #dropdownLanguagePicker>
  <app-language-picker (languageChanged)="openDropdown()"></app-language-picker>
</ng-template>
