import { Injectable } from '@angular/core';
import { DatoService } from '@core/services/dato/dato.service';
import { gql } from '@apollo/client';
import { map, Observable } from 'rxjs';
import { ProductFragment, ProductWithImagesFragment } from '@core/services/dato/fragments/product.fragment';
import { PageService } from '@shared/services/page.service';
import { MediaBlockFragment } from '@core/services/dato/fragments/media-block.fragment';
import { CtaFragment } from '@core/services/dato/fragments/cta.fragment';
import { DatoHero } from '@core/services/dato/interfaces/hero.interface';
import { SecondCampaignPage } from '../../../../../domain/second-campaign-page';

@Injectable({
  providedIn: 'root'
})
export class DatoSecondCampaignDataService {
  constructor(
    private readonly datoService: DatoService,
    private readonly pageService: PageService
  ) {}

  public getCampaignPageSlugs$(locale: string = 'en', disableCache = false): Observable<string[]> {
    return this.datoService
      .get$<{ allSecondCampaignPages: { slug: string }[] }>(
        gql`
          query getCampaignPageSlugs {
          allSecondCampaignPages(locale: ${locale}) {
              slug
            }
          }
        `,
        disableCache,
        { locale }
      )
      .pipe(
        map((res) => {
          return res.allSecondCampaignPages.map((campaignPage) => campaignPage.slug);
        })
      );
  }

  public getSecondCampaignHeroData$(slug: string, locale: string = 'en', disableCache = false): Observable<DatoHero> {
    return this.datoService
      .get$(
        gql`
          ${CtaFragment}
          query getSecondCampaignPageHero {
            hero: secondCampaignPage(locale: ${locale},  filter: { slug: {eq: "${slug}"} }, fallbackLocales: [nl]) {
              id
              locale @client
              heroTitle
              heroContent
              heroCta {
                ...Cta
              }
              backgroundColor {
                hex
              }
              heroBoxBackgroundColor {
                hex
              }
              heroImage {
                id
                title
                format
                url
                focalPoint {
                  x,
                  y
                },
                responsiveImage(imgixParams: { fit: crop, w:1980, crop: focalpoint, fm: webp }) {
                  src
                  srcSet
                  sizes
                  webpSrcSet
                }
              }
              introductionBlockTitle
              introductionBlockContent
            }
          }
        `,
        disableCache,
        { locale }
      )
      .pipe(
        map((res) => {
          return res.hero;
        })
      );
  }

  public getSecondCampaignPageData$(slug: string, locale: string = 'en', disableCache = false): Observable<SecondCampaignPage> {
    return this.datoService
      .get$(
        gql`
          ${MediaBlockFragment}
          ${ProductWithImagesFragment}
          ${ProductFragment}
          ${CtaFragment}
          query getSecondCampaignPage {
            secondCampaignPage(locale: ${locale}, filter: { slug: {eq: "${slug}"} }, fallbackLocales: [nl]) {
              slug
              recommendedProductsTitle
              recommendedProducts {
                ...ProductWithImage
              }
              recommendedProductsCta {
                ...Cta
              }
              testimonials {
                ... on UserTestimonialRecord {
                  id
                  title
                  subtitle
                  testimonialImage {
                    url
                    alt
                    responsiveImage(imgixParams: { fit: crop, w: 700, crop: focalpoint }) {
                      src
                      srcSet
                      sizes
                      webpSrcSet
                    }
                  }
                  content
                  age
                  productCta
                  productSubtitle
                  product {
                    ...Product,
                    images: productImages {
                      url
                      alt
                      responsiveImage(imgixParams: { fit: crop, w: 300, crop: focalpoint, fm: webp }) {
                        src
                        srcSet
                        sizes
                        webpSrcSet
                      }
                    }
                  }
                }
              }
              testProcessTitle
              testProcess {
                title
                content
                icon
              }
              testProcessCta {
                ctaTitle
                route
              }
              servicesTitle
              services {
                title
                icon
                content
              }
              mediaBlock {
                ...MediaBlock
              }
              meta: _seoMetaTags {
                content
                attributes
                tag
              }
            }
          }
        `,
        disableCache,
        { locale }
      )
      .pipe(
        map((res) => {
          this.pageService.setMeta(res.secondCampaignPage.meta);
          return res.secondCampaignPage as SecondCampaignPage;
        })
      );
  }
}
