<svg viewBox="0 0 440 288" width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M328.632 201.145c25.646-6.465 29.562-32.727 28.314-45.05-6.106-81.52-116.673-152.06-229.899-102.414-50.75 22.251-94.742 74.068-54.178 115.236 27.365 27.773 59.866-12.349 106.445-4.972 19.89 3.15 117.26 45.281 149.318 37.2ZM340 34c0 15.464 12.536 28 28 28h36c8.837 0 16-7.163 16-16s-7.163-16-16-16a15.93 15.93 0 0 0-8.059 2.175C395.001 17.56 382.851 6 368 6c-15.464 0-28 12.536-28 28Z"
    [attr.fill]="cloudColor"
  />
  <path
    d="M59 83a9 9 0 1 0-2.048-17.766C56.306 56.713 49.187 50 40.5 50c-6.813 0-12.662 4.13-15.18 10.023A12.441 12.441 0 0 0 18.5 58C11.596 58 6 63.596 6 70.5S11.596 83 18.5 83H59Z"
    [attr.fill]="cloudColor"
  />
  <ellipse cx="235.5" cy="275" rx="57.5" ry="8" [attr.fill]="shadowColor" />
  <ellipse cx="113" cy="273.5" rx="14" ry="5.5" [attr.fill]="shadowColor" />
  <path
    d="M155.492 199.399c-22.86-74.804 51.366-123.546 66.202-173.263 28.653 46.51 136.972 54.08 117.329 151.531-19.643 97.452-160.672 96.536-183.531 21.732Z"
    fill="#fff"
  />
  <path
    d="m212.253 24.204 1.277-.786-1.757-2.852-.957 3.21 1.437.428Zm-1.437-.428c-3.639 12.192-10.944 24.4-19.619 37.11-8.62 12.63-18.662 25.85-27.448 39.741-17.64 27.887-30.753 59.253-19.132 97.279l2.869-.877c-11.239-36.778 1.331-67.184 18.798-94.799 8.768-13.86 18.635-26.823 27.391-39.652 8.701-12.75 16.236-25.279 20.015-37.945l-2.874-.857Zm-66.199 174.13c11.707 38.312 53.531 57.416 94.502 54.789 41.072-2.635 81.947-27.114 91.934-76.663l-2.941-.593c-9.655 47.904-49.116 71.692-89.185 74.262-40.169 2.576-80.29-16.18-91.441-52.672l-2.869.877Zm186.436-21.874c4.969-24.651 1.863-43.751-6.155-59.235-7.999-15.447-20.813-27.15-35.005-37.156-14.124-9.96-29.924-18.43-43.655-27.11-13.855-8.76-25.738-17.8-32.708-29.113l-2.554 1.573c7.357 11.941 19.759 21.288 33.659 30.075 14.023 8.865 29.448 17.098 43.53 27.027 14.014 9.88 26.387 21.249 34.069 36.084 7.663 14.797 10.731 33.188 5.878 57.262l2.941.593Z"
    fill="#42649B"
  />
  <path
    d="M326.945 123.4c9.516 3.317 27.134 12.4 21.477 22.198s-11.642 14.507-14.411 15.767l5.641 3.665"
    stroke="#42649B"
    stroke-width="3"
  />
  <path
    d="M277.913 114.28c-3.585.788-11.211 2.59-12.005 7.357 3.476-2.484 7.554-2.334 11.756-.561"
    stroke="#42649B"
    stroke-width="3"
    stroke-linejoin="round"
  />
  <circle cx="191.04" cy="132.505" r="5.387" transform="rotate(-15 191.04 132.505)" fill="#42649B" />
  <path d="M219.953 180.021c.391-5.626 7.575-16.938 27.39-15.622" stroke="#42649B" stroke-width="3" />
  <path d="M243.505 159.734c1.837 1.06 4.656 4.239 5.098 7.434" stroke="#42649B" stroke-width="3" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M184.413 194.203c34.408-9.22 54.828-44.588 45.608-78.997-9.22-34.408-44.587-54.828-78.996-45.608-34.408 9.22-54.828 44.588-45.608 78.996 4.03 15.041 13.057 27.409 24.816 35.797l-24.167 42.797c-2.641 4.676-1.059 10.871 3.405 13.854 4.261 2.847 9.502 1.46 12.092-2.963 8.61-14.706 26.528-44.931 28.032-44.382 12.02 4.386 21.52 4.069 34.818.506Zm-2.847-10.626c28.54-7.647 45.477-36.983 37.83-65.524-7.647-28.54-36.983-45.477-65.524-37.83-28.54 7.648-45.477 36.984-37.83 65.524 7.647 28.541 36.983 45.478 65.524 37.83Z"
    fill="#B5CBEE"
  />
  <path
    d="M146.617 192.239c11.123 3.372 23.294 3.779 35.347.549 34.408-9.219 54.828-44.587 45.608-78.996-9.22-34.408-44.588-54.828-78.996-45.608-34.409 9.22-54.828 44.587-45.609 78.996 4.276 15.957 14.379 29.372 27.198 37.751 4.393 2.872 8.495-.105 6.502-2.782-5.119-6.869-24.756 30.116-32.721 45.835-1.976 3.898-.676 8.608 2.962 11.03 4.111 2.737 9.671 1.531 12.279-2.663l27.43-44.112Zm0 0-5.206-2.043m75.536-73.557c7.647 28.541-9.29 57.877-37.831 65.524-28.54 7.647-57.876-9.29-65.523-37.83-7.648-28.541 9.289-57.877 37.83-65.524 28.54-7.647 57.876 9.29 65.524 37.83Z"
    stroke="#42649B"
    stroke-width="3"
  />
  <mask id="a" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="110" y="75" width="111" height="111">
    <circle cx="165.269" cy="130.486" r="53.5" transform="rotate(-15 165.269 130.486)" fill="#fff" stroke="#42649B" stroke-width="3" />
  </mask>
  <g mask="url(#a)">
    <circle cx="165.269" cy="130.486" r="53.5" transform="rotate(-15 165.269 130.486)" fill="#fff" />
    <g opacity=".7">
      <path
        d="M126.923 209.609c-30.325-99.233 68.142-163.893 87.822-229.847 38.011 61.699 181.705 71.74 155.647 201.018-26.058 129.278-213.144 128.062-243.469 28.829Z"
        fill="#E8F0FD"
      />
      <path
        d="m204.809-13.141 1.277-.787-1.757-2.852-.957 3.21 1.437.429Zm-1.437-.43c-4.85 16.253-14.579 32.5-26.091 49.367-11.457 16.788-24.75 34.283-36.402 52.705-23.373 36.95-40.68 78.402-25.327 128.643l2.869-.876c-14.971-48.993 1.793-89.487 24.993-126.163 11.633-18.392 24.752-35.63 36.345-52.617 11.538-16.907 21.497-33.475 26.487-50.2l-2.874-.858Zm-87.82 230.715c15.441 50.527 70.645 75.813 124.866 72.336 54.32-3.484 108.314-35.845 121.509-101.307l-2.941-.592c-12.863 63.816-65.442 95.486-118.76 98.905-53.419 3.426-106.92-21.512-121.805-70.218l-2.869.876Zm246.375-28.971c6.572-32.607 2.458-57.829-8.12-78.257-10.56-20.392-27.487-35.862-46.284-49.116-18.731-13.207-39.629-24.405-57.893-35.95-18.386-11.624-34.235-23.667-43.544-38.778l-2.554 1.574c9.696 15.738 26.063 28.087 44.495 39.74 18.556 11.73 39.08 22.69 57.767 35.866 18.62 13.128 35.106 28.264 45.349 48.043 10.223 19.742 14.299 44.255 7.843 76.286l2.941.592Z"
        fill="#42649B"
      />
      <circle cx="176.669" cy="130.528" r="7.147" transform="rotate(-15 176.669 130.528)" fill="#42649B" />
      <path d="M215.024 193.561c.518-7.463 10.048-22.469 36.335-20.723" stroke="#42649B" stroke-width="3" />
    </g>
    <circle cx="169.979" cy="136.471" r="56.5" transform="rotate(-15 169.979 136.471)" stroke="#B5CBEE" stroke-width="7" />
    <circle cx="169.461" cy="134.539" r="53.5" transform="rotate(-15 169.461 134.539)" stroke="#42649B" stroke-width="3" />
    <circle opacity=".4" cx="165.269" cy="130.486" r="53.5" transform="rotate(-15 165.269 130.486)" fill="#E8F0FD" />
    <circle cx="165.269" cy="130.486" r="53.5" transform="rotate(-15 165.269 130.486)" stroke="#42649B" stroke-width="3" />
    <path
      d="M202.562 158.262c13.184-17.7 11.89-41.705-1.807-57.842m-7.71-7.227a46.947 46.947 0 0 1 4.219 3.544"
      stroke="#fff"
      stroke-width="3"
    />
  </g>
  <path d="M120.507 216.502c-15.206-15.99-8.511-28.1 3.511-36.14" stroke="#42649B" stroke-width="3" />
</svg>
