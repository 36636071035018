<ng-container [ngSwitch]="type">
  <svg-icon class="flex-shrink-0" *ngSwitchCase="ToastMessageType.Success" key="check" fontSize="24px"></svg-icon>
  <svg-icon class="flex-shrink-0" *ngSwitchCase="ToastMessageType.Warning" key="road-sign" fontSize="24px"></svg-icon>
  <svg-icon class="flex-shrink-0" *ngSwitchCase="ToastMessageType.Error" key="road-sign" fontSize="24px"></svg-icon>
  <svg-icon class="flex-shrink-0" *ngSwitchDefault key="info-circle" fontSize="24px"></svg-icon>
</ng-container>
<div class="message body-small body-normal-md">
  <ng-content></ng-content>
  <ng-template cdkPortalOutlet></ng-template>
</div>
<button
  *ngIf="closeable"
  ddButton
  level="tertiary"
  type="icon"
  (click)="onCloseClick()"
  class="close-button ms-auto btn-unstyled flex-shrink-0"
  [ngClass]="{
    'color-neutral600': type !== ToastMessageType.Info,
    'color-error500': type === ToastMessageType.Error,
    'color-success600': type === ToastMessageType.Success,
    'color-bg600': type === ToastMessageType.Warning
  }"
>
  <svg-icon key="close" fontSize="24px"></svg-icon>
</button>
