import { createReducer, on } from '@ngrx/store';
import { Loadable } from '@shared/interfaces/loadable.interface';
import { DatoFooter } from '@core/services/dato/interfaces/footer.interface';
import { LoadableStateReducerHelper } from '@shared/helpers/loadable-state-reducer.helper';
import { getFooter, getFooterError, getFooterSuccess } from '@shop/modules/footer/store/footer.actions';
import { DatoApiError } from '../../../../../domain/dato-api-error';

export const featureKeyFooter = 'footer';

export interface FeatureStateFooter {
  footer: Loadable<DatoFooter, DatoApiError>;
}

export interface AppState {
  [featureKeyFooter]: FeatureStateFooter;
}

export const initialStateFooter: FeatureStateFooter = {
  footer: { isLoading: false }
};

export const reducerFooter = createReducer(
  initialStateFooter,
  on(getFooter, (state) => ({
    ...state,
    footer: LoadableStateReducerHelper.startLoad(state.footer)
  })),
  on(getFooterSuccess, (state, { footer }) => ({
    ...state,
    footer: LoadableStateReducerHelper.loadSuccess(footer)
  })),
  on(getFooterError, (state, { error }) => ({
    ...state,
    footer: LoadableStateReducerHelper.loadError(state.footer, error)
  }))
);
