import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TagEvent } from '@shared/enums/tags/tag-event.enum';
import { Store } from '@ngrx/store';
import { RouteSegment } from '../../enums/route-segment.enum';
import { selectProfileVM } from '../../store/profile/profile.selectors';

@Component({
  selector: 'app-barcode-activation-success',
  templateUrl: './barcode-activation-success.component.html',
  styleUrls: ['./barcode-activation-success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BarcodeActivationSuccessComponent {
  public readonly RouteSegment = RouteSegment;
  public readonly TagEvent = TagEvent;
  public readonly profileVM$ = this.store.select(selectProfileVM);

  constructor(private readonly store: Store) {}
}
