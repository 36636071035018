import { createAction, props } from '@ngrx/store';
import { ApiError } from 'src/domain/api-error';
import { NewsletterStatus } from 'src/domain/newsletter/newsletter-status';
import { DatoNewsletter } from '@core/services/dato/interfaces/newsletter.interface';
import { DatoApiError } from '../../../../domain/dato-api-error';

const feature = '[Newsletter]';

export const getNewsletterContent = createAction(`${feature} get newsletter content`);
export const getNewsletterContentSuccess = createAction(
  `${feature} get newsletter content success`,
  props<{
    content: DatoNewsletter;
  }>()
);
export const getNewsletterContentError = createAction(
  `${feature} get newsletter content error`,
  props<{
    error: DatoApiError;
  }>()
);

export const getSubscriptionStatus = createAction(`${feature} subscription status to newsletter`);
export const getSubscriptionStatusSuccess = createAction(
  `${feature} subscription status to newsletter success`,
  props<{ status: NewsletterStatus }>()
);
export const getSubscriptionStatusError = createAction(
  `${feature} subscription status to newsletter error`,
  props<{
    error: ApiError;
  }>()
);

export const subscribeToNewsletter = createAction(
  `${feature} subscribe to newsletter`,
  props<{
    email: string;
    showToast: boolean;
  }>()
);
export const subscribeToNewsletterSuccess = createAction(`${feature} subscribe to newsletter success`);
export const subscribeToNewsletterError = createAction(
  `${feature} subscribe to newsletter error`,
  props<{
    error: ApiError;
  }>()
);
export const subscribeToNewsletterReset = createAction(`${feature} subscribe to newsletter reset`);

export const unsubscribeFromNewsletter = createAction(
  `${feature} unsubscribe from newsletter`,
  props<{
    showToast: boolean;
  }>()
);
export const unsubscribeFromNewsletterSuccess = createAction(`${feature} unsubscribe from newsletter success`);
export const unsubscribeFromNewsletterError = createAction(
  `${feature} unsubscribe from newsletter error`,
  props<{
    error: ApiError;
  }>()
);
