import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CampaignVM } from '@shop/pages/campaign/campaign.vm';
import { featureKeyCampaign, FeatureStateCampaign } from './campaign.reducer';

const selectAboutUsFeature = createFeatureSelector<FeatureStateCampaign>(featureKeyCampaign);

export const selectCampaignPageIsLoading = createSelector(selectAboutUsFeature, (state): boolean => {
  return state.campaignPage.isLoading;
});
export const selectCampaignPage = createSelector(selectAboutUsFeature, (state): CampaignVM | undefined => {
  return state.campaignPage.data ?? undefined;
});
