import { Injectable } from '@angular/core';
import { DatoService } from '@core/services/dato/dato.service';
import { gql } from '@apollo/client';
import { map, Observable } from 'rxjs';
import { DatoCookiesList } from '@core/services/dato/interfaces/cookies-list.interface';
import { CookiesListItemFragment } from '@core/services/dato/fragments/cookies-list-item.fragment';

@Injectable()
export class CookieListDataService {
  constructor(private readonly datoService: DatoService) {}

  public getCookiesList$(locale: string = 'en', disableCache = false): Observable<DatoCookiesList> {
    return this.datoService
      .get$<{ allCookiesLists: DatoCookiesList[] }>(
        gql`
          ${CookiesListItemFragment}
          query getCookiesList {
            allCookiesLists(locale: ${locale}) {
              id
              locale @client
              advertisingCookiesDescription
              advertisingCookiesList {
                ...CookiesListItem
              }
              strictlyNecessaryCookiesDescription
              strictlyNecessaryCookiesList {
                ...CookiesListItem
              }
              statisticalCookiesDescription
              statisticalCookiesList {
                ...CookiesListItem
              }
            }
          }
        `,
        disableCache,
        { locale }
      )
      .pipe(
        map((res) => {
          return res.allCookiesLists[0];
        })
      );
  }
}
