import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgOtpInputModule } from 'ng-otp-input';
import { LetDirective } from '@ngrx/component';
import { OtpInputComponent } from './otp-input.component';

@NgModule({
  declarations: [OtpInputComponent],
  imports: [CommonModule, NgOtpInputModule, LetDirective],
  exports: [OtpInputComponent]
})
export class OtpInputModule {}
