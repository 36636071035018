import { createAction, props } from '@ngrx/store';
import { ProductItem } from '@shared/interfaces/product.interface';
import { ApiError } from 'src/domain/api-error';
import { DatoArticle } from '@core/services/dato/interfaces/article.interface';

const feature = '[Knowledge Base Article]';

export const getArticle = createAction(`${feature} load`, props<{ slug: string }>());
export const getArticleSuccess = createAction(`${feature} success`, props<{ article: DatoArticle }>());
export const getArticleError = createAction(`${feature} error`, props<{ error: ApiError }>());

export const getArticleRelatedProductSuccess = createAction(`${feature} related product success`, props<{ product: ProductItem }>());
export const getArticleRelatedProductError = createAction(`${feature} related product error`, props<{ error: ApiError }>());
