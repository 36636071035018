import { Directive, Input, OnChanges, Renderer2, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { SkeletonLoaderComponent } from '@shared/directives/skeleton-loader/skeleton-loader.component';

const MIN_RANDOM_WIDTH = 60;
const MAX_RANDOM_WIDTH = 100;
const random = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

@Directive({
  selector: '[appSkeleton]'
})
export class SkeletonLoaderDirective implements OnChanges {
  @Input('appSkeleton') public isLoading = true;
  @Input('appSkeletonRepeat') public repeat = 1;
  @Input('appSkeletonWidth') public width?: string;
  @Input('appSkeletonHeight') public height?: string;
  @Input('appSkeletonClassName') public className!: string | string[];

  constructor(
    private readonly tpl: TemplateRef<unknown>,
    private readonly vcr: ViewContainerRef,
    private readonly rendered: Renderer2
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.isLoading) {
      this.vcr.clear();

      if (changes.isLoading.currentValue) {
        Array.from({ length: this.repeat }).forEach(() => {
          const ref = this.vcr.createComponent(SkeletonLoaderComponent);

          Object.assign(ref.instance, {
            width: this.width === 'rand' ? `${random(MIN_RANDOM_WIDTH, MAX_RANDOM_WIDTH)}%` : this.width,
            height: this.height,
            className: this.className
          });
        });
      } else {
        this.vcr.createEmbeddedView(this.tpl, { implicit$: this.isLoading });
      }
    }
  }
}
