import { asyncScheduler, concat, connect, debounceTime, OperatorFunction, SchedulerLike, take, ThrottleConfig, throttleTime } from 'rxjs';

const defaultThrottleConfig: ThrottleConfig = { leading: true, trailing: false };
export const throttleTimeAfter = <T = unknown>(
  amount: number,
  dueTime: number,
  scheduler: SchedulerLike = asyncScheduler,
  config: ThrottleConfig = defaultThrottleConfig
): OperatorFunction<T, T> => {
  return connect((value$) => concat(value$.pipe(take(amount)), value$.pipe(throttleTime(dueTime, scheduler, config))));
};

export const debounceTimeAfter = <T = unknown>(
  amount: number,
  dueTime: number,
  scheduler: SchedulerLike = asyncScheduler
): OperatorFunction<T, T> => {
  return connect((value$) => concat(value$.pipe(take(amount)), value$.pipe(debounceTime(dueTime, scheduler))));
};

export const debounceTimeAfterFirst = <T = unknown>(dueTime: number, scheduler: SchedulerLike = asyncScheduler): OperatorFunction<T, T> => {
  return debounceTimeAfter(1, dueTime, scheduler);
};

export const throttleTimeAfterFirst = <T = unknown>(
  dueTime: number,
  scheduler: SchedulerLike = asyncScheduler,
  config: ThrottleConfig = defaultThrottleConfig
): OperatorFunction<T, T> => {
  return throttleTimeAfter(1, dueTime, scheduler, config);
};
