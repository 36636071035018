import { AutofillEvent } from '@angular/cdk/text-field';
import { AsyncPipe, CommonModule } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Optional,
  Output,
  Self,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormsModule, NgControl, Validators } from '@angular/forms';
import { ControlValueAccessor, FormControl } from '@ngneat/reactive-forms';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { enUS, nl } from 'date-fns/locale';
import { format, isValid, parse, parseISO } from 'date-fns';
import { MatIconModule } from '@angular/material/icon';
import { LanguageService } from '@core/modules/language/language.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DateAdapter } from '@angular/material/core';
import { distinctUntilChanged } from 'rxjs';
import { NgxMaskPipe, provideNgxMask } from 'ngx-mask';
@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatDatepickerModule, MatInputModule, SvgIconComponent, FormsModule, AsyncPipe, CommonModule, TranslateModule, MatIconModule],
  providers: [provideNgxMask(), NgxMaskPipe]
})
export class InputComponent extends ControlValueAccessor<string> implements AfterContentInit, OnChanges {
  private readonly ngxMaskPipe = inject(NgxMaskPipe);

  @Input() public label?: string;
  @Input() public hint?: string;
  @Input() public type = 'text';
  @Input() public prefix = false;
  @Input() public suffix = false;
  @Input() public placeholder?: string = '';
  @Input() public clearable = false;
  @Input() public error?: string | null;
  @Input() public minLength?: number;
  @Input() public maxLength?: number;
  @Input() public min?: number | string;
  @Input() public max?: number | string;
  @Input() public step?: number;
  @Input() public showOptionalLabel = false;
  @Input() public autocomplete?: string;
  @Input() public inputmode?: string;
  @Input() public pattern?: string;
  @Input() public size: 'small' | 'medium' | 'large' = 'medium';
  @Output() public autocompleted: EventEmitter<AutofillEvent> = new EventEmitter<AutofillEvent>();
  @Output() public userInput: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();

  @ViewChild('input') public inputElement?: ElementRef;
  @ViewChild('dateInput') public dateInputElement?: ElementRef;

  public nativeInputValue: string | Date = '';
  public disabled: boolean | null = null;
  public currentType?: string;
  public onChange?: (value: string | Date | null) => void;
  public readonly dateValueTypes = ['date', 'datetime-local', 'month'];

  public constructor(
    @Optional() @Self() private readonly ngControl: NgControl,
    private readonly dateAdapter: DateAdapter<Date>,
    private readonly languageService: LanguageService,
    private readonly cdr: ChangeDetectorRef,
    private readonly destroyRef: DestroyRef
  ) {
    super();
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public get canMinMax(): boolean {
    return this.type === 'date' || this.type === 'number';
  }

  public get isRequired(): boolean {
    return this.control.hasValidator(Validators.required);
  }

  public get control(): FormControl<string | number | Date | null> {
    return this.ngControl.control as FormControl<string | number | Date>;
  }

  public get hasError(): boolean {
    return (this.control?.invalid || Object.values(this.control?.errors || []).length > 0) && !!this.control?.touched;
  }

  public ngAfterContentInit(): void {
    this.control.touch$.subscribe(() => this.cdr.markForCheck());
    if (this.type !== 'date') {
      this.currentType = this.type;
    }

    if (this.type === 'date') {
      this.languageService.currentLanguage$.pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged()).subscribe((language) => {
        this.dateAdapter.setLocale(language === 'nl' ? nl : enUS);
      });
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.type?.currentValue && changes.type?.currentValue !== 'date') {
      this.currentType = changes.type.currentValue;
    }
  }

  public detect(): void {
    this.cdr.markForCheck();
  }

  public onValueChange(value: string | Date): void {
    if (this.onChange) {
      if (this.dateValueTypes.includes(this.type)) {
        const date = value instanceof Date ? value : this.parseDate(value);
        const formattedNativeInput = this.ngxMaskPipe.transform(this.dateInputElement?.nativeElement.value, 'd0/M0/0000');
        const nativeInputDate = parse(`${formattedNativeInput}`, 'dd/MM/yyyy', new Date());
        if (date) {
          this.dateInputElement!.nativeElement.value = formattedNativeInput;
          this.control.setValue(date);
          this.onChange(date);
          return;
        }
        if (isValid(nativeInputDate) && nativeInputDate.getFullYear() > 1000) {
          this.dateInputElement!.nativeElement.value = formattedNativeInput;
          this.control.setValue(nativeInputDate);
          this.onChange(nativeInputDate);
          return;
        }
      }
      this.onChange(value);
    }
  }

  public onClearIconClick(event: Event): void {
    event.preventDefault();
    this.nativeInputValue = '';
    this.onValueChange(this.nativeInputValue);
    this.focusInput();
  }

  public focusInput(): void {
    this.inputElement?.nativeElement.focus();
    this.dateInputElement?.nativeElement.focus();
  }

  public writeValue(value: string | Date): void {
    if (this.nativeInputValue !== value) {
      this.nativeInputValue = value;
    }

    if (value && !(value instanceof Date) && this.dateValueTypes.includes(this.type)) {
      const date = this.parseDate(value);
      if (date) {
        this.nativeInputValue = format(date, 'yyyy/MM/dd', { locale: nl });
      }
    }
    this.cdr.markForCheck();
  }

  public onSuffixButtonClick(event: Event, picker?: MatDatepicker<unknown>): void {
    event.preventDefault();
    if (this.control.disabled) {
      return;
    }

    switch (this.type) {
      case 'password': {
        this.currentType = this.currentType === 'password' ? 'text' : 'password';
        this.cdr.detectChanges();
        break;
      }
      case 'text': {
        this.onClearIconClick(event);
        break;
      }
      case 'datetime-local':
      case 'date':
      case 'time':
      case 'month':
      case 'week': {
        picker?.open();
        break;
      }
      default: {
        break;
      }
    }
  }

  private parseDate(isoDateString: string | undefined): Date | undefined {
    if (!isoDateString || !isValid(isoDateString)) {
      return;
    }

    return parseISO(isoDateString);
  }
}
