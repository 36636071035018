import { Injectable } from '@angular/core';
import { DatoService } from '@core/services/dato/dato.service';
import { gql } from '@apollo/client';
import { map, Observable } from 'rxjs';
import { PageService } from '@shared/services/page.service';
import { DatoFooter } from '@core/services/dato/interfaces/footer.interface';
import { FooterSubGroupFragment } from '@core/services/dato/fragments/footer-sub-group.fragment';
import { FooterTextContentFragment } from '@core/services/dato/fragments/footer-text-content.fragment';

@Injectable({
  providedIn: 'root'
})
export class DatoFooterDataService {
  constructor(
    private readonly datoService: DatoService,
    private readonly pageService: PageService
  ) {}

  public getFooterData$(locale: string = 'en', disableCache = false): Observable<DatoFooter> {
    return this.datoService
      .get$<{ footer: DatoFooter }>(
        gql`
          ${FooterSubGroupFragment}
          ${FooterTextContentFragment}
          query getFooter {
            footer(locale: ${locale}) {
              id
              logos {
                url
                alt
              }
              groups {
                ... on FooterTextContentRecord {
                  id
                  type: __typename
                  title
                  content
                  imageSide
                  images {
                    url
                    alt
                  }
                }
                ... on FooterGroupRecord {
                  id
                  groupTitle
                  type: __typename
                  links {
                    ... on FooterGroupRecord {
                      id
                      groupTitle
                      type: __typename
                      links {
                        ... FooterTextContent
                        ... FooterSubGroup
                      }
                    }
                    ... FooterTextContent
                    ... FooterSubGroup
                  }
                }
              }
              socialMediaLinks {
                ... on SocialMediaLinkRecord {
                  id
                  title
                  url
                  measurementKey
                  measurementValue
                  icon {
                    url
                    alt
                  }
                }
              }
              textContents {
                ... on FooterTextContentRecord {
                  id
                  type: __typename
                  title
                  content
                  imageSide
                  images {
                    url
                    alt
                  }
                }
              }
            }
          }
        `,
        disableCache,
        { locale }
      )
      .pipe(
        map((res) => {
          return res.footer;
        })
      );
  }
}
