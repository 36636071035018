import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { appFbIcon } from '@icons/social/fb';
import { appIgIcon } from '@icons/social/ig';
import { appLinkedinIcon } from '@icons/social/linkedin';
import { appArrowRightIcon } from '@icons/arrow-right';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { appDdLogoIcon } from '@icons/dd-logo';
import { appHamburgerMenuIcon } from '@icons/hamburger-menu';
import { appCloseIcon } from '@icons/close';
import { appShoppingCartIcon } from '@icons/shopping-cart';
import { CookiePolicyModule } from '@shared/modules/cookie-policy/cookie-policy.module';
import { DropdownModule } from '@shared/components/dropdown/dropdown.module';
import { appUkFlagIcon } from '@icons/flags/uk-flag';
import { appNlFlagIcon } from '@icons/flags/nl-flag';
import { ToastMessageModule } from '@shared/modules/toast-message/toast-message.module';
import { IntersectionObserverModule } from '@ng-web-apis/intersection-observer';
import { ProfileStoreModule } from '@shared/store/profile/profile-store.module';
import { appBadgeIcon } from '@icons/accreditations/badge';
import { appDdFooterLogoIcon } from '@icons/dd-footer-logo';
import { LazyLoadBackgroundModule } from '@shared/directives/lazy-load-background/lazy-load-background.module';
import { BreadcrumbModule } from '@shared/modules/breadcrumb/breadcrumb.module';
import { FooterModule } from '@shop/modules/footer/footer.module';
import { ReviewsModule } from '@shared/modules/reviews/reviews.module';
import { ReviewsService } from '@shared/modules/reviews/reviews.service';
import { provideSvgIcons } from '@ngneat/svg-icon';
import { HeaderComponent } from './components/header/header.component';
import { ShopRoutingModule } from './shop-routing.module';
import { CartModule } from './modules/cart/cart.module';
import { LanguagePickerComponent } from './components/language-picker/language-picker.component';
import { ProductsEffects } from './store/products.effects';
import { featureKeyProducts, reducerProducts } from './store/products.reducer';
import { ProductItemStickyModule } from './pages/available-tests/pages/product/components/product-item-sticky/product-item-sticky.module';
import { ShopLayoutComponent } from './layout/shop-layout.component';
import { BarcodeActivationLayoutComponent } from './pages/barcode-activation/layout/barcode-activation-layout.component';

@NgModule({
  declarations: [ShopLayoutComponent, BarcodeActivationLayoutComponent, HeaderComponent, LanguagePickerComponent],
  imports: [
    DropdownModule,
    SharedModule,
    ShopRoutingModule,
    CartModule,
    FooterModule,
    CookiePolicyModule,
    ToastMessageModule,
    ProfileStoreModule,
    ProductItemStickyModule,
    IntersectionObserverModule,
    LazyLoadBackgroundModule,
    ReviewsModule,
    BreadcrumbModule.forRoot(),
    StoreModule.forFeature(featureKeyProducts, reducerProducts),
    EffectsModule.forFeature([ProductsEffects])
  ],
  providers: [
    provideSvgIcons([
      appDdLogoIcon,
      appDdFooterLogoIcon,
      appHamburgerMenuIcon,
      appCloseIcon,
      appShoppingCartIcon,
      appFbIcon,
      appIgIcon,
      appLinkedinIcon,
      appArrowRightIcon,
      appUkFlagIcon,
      appNlFlagIcon,
      appBadgeIcon
    ])
  ]
})
export class ShopModule {
  constructor(private readonly reviewsService: ReviewsService) {
    this.reviewsService.getStoreReviews();
  }
}
