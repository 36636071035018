import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ReactiveFormsModule } from '@angular/forms';
import { appTrashIcon } from '@icons/trash';
import { appVanIcon } from '@icons/van';
import { appVisaIcon } from '@icons/payment/Visa';
import { appMasterCardIcon } from '@icons/payment/MasterCard';
import { appIdealIcon } from '@icons/payment/IDEAL';
import { appPayPalIcon } from '@icons/payment/PayPal';
import { RouterModule } from '@angular/router';
import { ComplexTranslationModule } from '@shared/components/complex-translation/complex-translation.module';
import { IntersectionObserverModule } from '@ng-web-apis/intersection-observer';
import { appInfoIcon } from '@icons/info';
import { CtaBadgeModule } from '@shared/modules/cta-badge/cta-badge.module';
import { InfoMessageModule } from '@shared/components/info-message/info-message.module';
import { provideSvgIcons } from '@ngneat/svg-icon';
import { CartComponent } from './cart.component';
import { CartEffects } from './store/cart.effects';
import { featureKeyCart, reducerCart } from './store/cart.reducer';
import { CartProductCardComponent } from './components/cart-product-card/cart-product-card.component';
import { CartEmptyImageComponent } from './components/cart-empty-image/cart-empty-image.component';
import { CartDropdownComponent } from './components/cart-dropdown/cart-dropdown.component';

@NgModule({
  declarations: [CartComponent, CartProductCardComponent, CartDropdownComponent, CartEmptyImageComponent],
  imports: [
    SharedModule,
    ReactiveFormsModule,
    RouterModule,
    ComplexTranslationModule,
    IntersectionObserverModule,
    CtaBadgeModule,
    InfoMessageModule,
    StoreModule.forFeature(featureKeyCart, reducerCart),
    EffectsModule.forFeature([CartEffects])
  ],
  providers: [provideSvgIcons([appTrashIcon, appVanIcon, appInfoIcon, appVisaIcon, appMasterCardIcon, appIdealIcon, appPayPalIcon])],
  exports: [CartComponent]
})
export class CartModule {}
