import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  Optional,
  Self,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { NgControl, Validators } from '@angular/forms';
import { ControlValueAccessor, FormControl } from '@ngneat/reactive-forms';

@Component({
  selector: 'app-quantity-input',
  templateUrl: './quantity-input.component.html',
  styleUrls: ['./quantity-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuantityInputComponent extends ControlValueAccessor<number> implements AfterContentInit, OnChanges {
  @Input() public label?: string;
  @Input() public hint?: string;
  @Input() public error?: string | null;
  @Input() public min: number = Number.NEGATIVE_INFINITY;
  @Input() public max: number = Number.POSITIVE_INFINITY;
  @Input() public step = 1;
  @Input() public showOptionalLabel = false;

  @ViewChild('input') public inputElement?: ElementRef;

  public nativeInputValue = 0;
  public disabled: boolean | null = null;
  public focused = false;
  public currentType?: string;
  public onChange?: (value: string | number | null) => void;

  public constructor(
    @Optional() @Self() private readonly ngControl: NgControl,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public get control(): FormControl<number> {
    return this.ngControl.control as FormControl<number>;
  }

  public get hasError(): boolean {
    return (this.control?.invalid || Object.values(this.control?.errors || []).length > 0) && !!this.control?.touched;
  }

  public get required(): boolean {
    return this.control.hasValidator(Validators.required);
  }

  public ngAfterContentInit(): void {
    this.control.touch$.subscribe((_) => this.cdr.markForCheck());
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.type?.currentValue) {
      this.currentType = changes.type.currentValue;
    }
  }

  public detect(): void {
    this.cdr.markForCheck();
  }

  public onValueChange(value: string): void {
    if (this.onChange) {
      this.onChange(value);
    }
  }

  public onBlur(): void {
    this.onTouched?.();
  }

  public writeValue(value: number): void {
    if (this.nativeInputValue !== value) {
      this.nativeInputValue = value;
    }
    this.cdr.markForCheck();
  }

  public increaseValue(): void {
    if (this.control.value < this.max && this.control.value + this.step <= this.max) {
      this.control.patchValue(this.control.value + this.step);
    }
  }

  public decreaseValue(): void {
    if (this.control.value > this.min && this.control.value - this.step >= this.min) {
      this.control.patchValue(this.control.value - this.step);
    }
  }
}
