<div [ngClass]="['form-check', 'form-check-' + size]">
  <input
    #input
    class="form-check-input"
    type="radio"
    [name]="name"
    [id]="id"
    [disabled]="control.disabled$ | async"
    [checked]="control.value === value"
    [value]="value"
    (change)="onInputChange()"
  />
  <label class="form-check-label" [for]="id" (click)="onLabelClick($event)">
    <ng-content></ng-content>
  </label>
  <small class="form-hint body-tiny" *ngIf="hint || (hasError && error)">{{ hasError ? error : hint }}</small>
</div>
