/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Injectable } from '@angular/core';
import { DatoService } from '@core/services/dato/dato.service';
import { gql } from '@apollo/client';
import { map, Observable } from 'rxjs';
import { ProductFragment } from '@core/services/dato/fragments/product.fragment';
import { PageService } from '@shared/services/page.service';
import { MediaBlockFragment } from '@core/services/dato/fragments/media-block.fragment';
import { CtaFragment } from '@core/services/dato/fragments/cta.fragment';
import { DatoHero } from '@core/services/dato/interfaces/hero.interface';
import { DatoNewsletter } from '@core/services/dato/interfaces/newsletter.interface';
import { Homepage } from '../../../../../domain/homepage';

@Injectable({
  providedIn: 'root'
})
export class DatoHomeDataService {
  constructor(
    private readonly datoService: DatoService,
    private readonly pageService: PageService
  ) {}

  public getNewsletterContent$(locale: string = 'en', disableCache = false): Observable<DatoNewsletter> {
    return this.datoService
      .get$(
        gql`
          query getHomepage {
            newsletter: homepage(locale: ${locale}) {
              newsletterTitle
              newsletterContent
              newsletterSubscribedTitle
              newsletterSubscribedContent
            }
          }
        `,
        disableCache,
        { locale }
      )
      .pipe(
        map((res) => {
          return res.newsletter;
        })
      );
  }

  public getHeroData$(locale: string = 'en', disableCache = false): Observable<DatoHero> {
    return this.datoService
      .get$(
        gql`
          ${CtaFragment}
          query getHomepage {
            hero: homepage(locale: ${locale}) {
              id
              locale @client
              heroTitle
              heroContent
              heroCta {
                ...Cta
              }
              backgroundColor {
                hex
              }
              heroBoxBackgroundColor {
                hex
              }
              heroImage {
                id
                title
                format
                url
                focalPoint {
                  x
                  y
                }
                responsiveImage(imgixParams: { fit: crop, w:1920, crop: focalpoint, fm: webp }) {
                  src
                  srcSet
                  sizes
                  webpSrcSet
                }
              }
              introductionBlockTitle
              introductionBlockContent
            }
          }
        `,
        disableCache,
        { locale }
      )
      .pipe(
        map((res) => {
          return res.hero;
        })
      );
  }

  public getHomepageData$(locale: string = 'en', disableCache = false): Observable<Homepage> {
    return this.datoService
      .get$(
        gql`
          ${MediaBlockFragment}
          ${ProductFragment}
          ${CtaFragment}
          query getHomepage {
            homepage(locale: ${locale}, fallbackLocales: [nl]) {
              recommendedProductsTitle
              recommendedProducts {
                ...Product
                images: productImages {
                  url
                  alt
                  responsiveImage(imgixParams: { fit: crop, w: 300, crop: focalpoint, fm: webp }) {
                    src
                    srcSet
                    sizes
                    webpSrcSet
                  }
                }
              }
              recommendedProductsCta {
                ...Cta
              }
              testimonials {
                ... on UserTestimonialRecord {
                  id
                  title
                  subtitle
                  testimonialImage {
                    url
                    alt
                    responsiveImage(imgixParams: { fit: crop, w: 800, crop: focalpoint, fm: webp }) {
                      src
                      srcSet
                      sizes
                      webpSrcSet
                    }
                  }
                  content
                  age
                  productCta
                  productSubtitle
                  product {
                    ...Product
                    images: productImages {
                      url
                      alt
                      responsiveImage(imgixParams: { fit: crop, w: 300, crop: focalpoint, fm: webp }) {
                        src
                        srcSet
                        sizes
                        webpSrcSet
                      }
                    }
                  }
                }
              }
              testProcessTitle
              testProcess {
                title
                content
                icon
              }
              testProcessCta {
                ctaTitle
                route
              }
              servicesTitle
              services {
                title
                icon
                content
              }
              mediaBlock {
                ...MediaBlock
              }
              newsletterTitle
              newsletterContent
              newsletterSubscribedTitle
              newsletterSubscribedContent
              meta: _seoMetaTags {
                content
                attributes
                tag
              }
            }
          }
        `,
        disableCache,
        { locale }
      )
      .pipe(
        map((res) => {
          this.pageService.setMeta(res.homepage.meta);
          return res.homepage as Homepage;
        })
      );
  }
}
