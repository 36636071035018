import { gql } from '@apollo/client';
import { CtaFragment } from '@core/services/dato/fragments/cta.fragment';

export const MediaBlockFragment = gql`
  ${CtaFragment}
  fragment MediaBlock on MediaBlockRecord {
    id
    title
    cardBlock
    showBackground
    showBorder
    content: mediaBlockContent
    cta {
      ...Cta
    }
    side: mediaSide
    reverseContent: reverse
    image {
      url
      alt
      responsiveImage(imgixParams: { fit: crop, w: 620, crop: focalpoint, fm: webp }) {
        src
        srcSet
        sizes
        webpSrcSet
      }
    }
    video {
      id
      title
      videoSubtitles {
        subtitleLocale
        videoSubtitleFile {
          url
        }
      }
      videoPreviewImage {
        url
      }
      videoFile: video {
        title
        url
        format: mimeType
        videoThumbnail: video {
          url: thumbnailUrl
        }
      }
    }
  }
`;
