import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { appInfoCircleIcon } from '@icons/info-circle';
import { provideSvgIcons, SvgIconComponent } from '@ngneat/svg-icon';
import { InfoMessageComponent } from './info-message.component';

@NgModule({
  declarations: [InfoMessageComponent],
  imports: [CommonModule, SvgIconComponent],
  providers: [provideSvgIcons([appInfoCircleIcon])],
  exports: [InfoMessageComponent]
})
export class InfoMessageModule {}
