import { HttpErrorResponse } from '@angular/common/http';
import { ErrorResponse } from '@dd/shop-client-sdk';
import { ApiError } from '../api-error';

export const mapApiErrorResponse = (response: HttpErrorResponse): ApiError => {
  const error = response?.error as ErrorResponse;

  return {
    code: error?.code,
    message: error?.message ?? response.message,
    status: response.status,
    statusText: response.statusText
  };
};
