import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ScrollSectionDirective } from './scroll-section.directive';

@UntilDestroy()
@Injectable()
export class ScrollManagerService {
  private readonly sections = new Map<string, ScrollSectionDirective>();

  public scroll(id: string): void {
    const sectionId = this.sections.get(id);
    if (sectionId) {
      sectionId.scroll();
    }
  }

  public register(section: ScrollSectionDirective): void {
    this.sections.set(section.id, section);
  }

  public remove(section: ScrollSectionDirective): void {
    this.sections.delete(section.id);
  }
}
