import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BottomSheetAndDialog } from '@shared/components/bottom-sheet/bottom-sheet-and-dialog';
import { RouteSegment } from '@shared/enums/route-segment.enum';
import { getProfileIfNeeded } from '@shared/store/profile/profile.actions';
import { selectProfile } from '@shared/store/profile/profile.selectors';
import { combineLatest, filter, map, Observable, take } from 'rxjs';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { LoginFormDialogData } from './components/login-form/models/login-form-dialog-data';
import { clearInitialUrl, storeInitialUrlBeforeLogin } from './store/auth.actions';
import { selectSecondFactorAuth } from './store/auth.selectors';

@Injectable()
export class AuthGuard {
  constructor(
    private readonly dialog: BottomSheetAndDialog,
    private readonly router: Router,
    private readonly location: Location,
    private readonly store: Store
  ) {}

  public canLoad(): Observable<boolean> {
    this.store.dispatch(getProfileIfNeeded());
    const path = this.location.path(true);
    return this.getAuthenticationResult$().pipe(
      map((authenticationResult) => {
        if (!authenticationResult.needs2FA && authenticationResult.isLoggedIn) {
          return true;
        }

        this.showLoginDialog(authenticationResult.needs2FA);

        // If we try to access the Dashboard, we store_dum the path and redirect to the root first.
        if (path.includes(RouteSegment.Dashboard)) {
          this.store.dispatch(storeInitialUrlBeforeLogin({ initialUrl: `${path}` }));
          this.router.navigate([RouteSegment.Root]);
        }
        return false;
      })
    );
  }

  public canActivate(): Observable<boolean> {
    return this.getAuthenticationResult$().pipe(
      map((authenticationResult) => {
        if (!authenticationResult.needs2FA && authenticationResult.isLoggedIn) {
          return true;
        }
        this.showLoginDialog(authenticationResult.needs2FA);
        return false;
      })
    );
  }

  private showLoginDialog(is2FA: boolean): void {
    this.dialog
      .open<LoginFormComponent, LoginFormDialogData>(LoginFormComponent, {
        backdropClass: 'dd-blur-backdrop',
        data: {
          shouldNavigate: false,
          requires2FA: true,
          is2FA
        }
      })
      .afterDismissed()
      .subscribe(() => {
        // We clear the initialUrl if the user closes the dialog, regardless of whether the user has logged in or not.
        this.store.dispatch(clearInitialUrl());
      });
  }

  private getAuthenticationResult$(): Observable<{ isLoggedIn: boolean; needs2FA: boolean }> {
    // We check whether we have a loaded profile and wheter we have 2FA or not. We wait first for the loading to be finished
    return combineLatest([this.store.select(selectProfile), this.store.select(selectSecondFactorAuth)]).pipe(
      filter(([profile, secondFactorAuth]) => profile.isLoading === false && secondFactorAuth.isLoading === false),
      map(([profile, secondFactorAuth]) => ({
        isLoggedIn: !!profile.data,
        needs2FA: !!profile.data && !secondFactorAuth.isAuthenticated
      })),
      take(1)
    );
  }
}
