import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  Output,
  signal,
  WritableSignal
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextFieldModule } from '@angular/cdk/text-field';
import { RouterModule } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { BreakpointService } from '@core/services/breakpoint.service';
import { LinkRecordType } from '@core/services/dato/interfaces/link-record-type.enum';
import { ComplexTranslationModule } from '../complex-translation/complex-translation.module';
import { ScrollManagerModule } from '../../directives/scroll-manager/scroll-manager.module';
import { DROPDOWN_DATA } from '../dropdown/dropdown-trigger-config';
import { ButtonComponent } from '../button/button.component';
import { SafeHtmlPipe } from '../../pipes/safe-html/safe-html.pipe';
import { ScrollerComponent } from '../scroller/scroller.component';
import { RouteSegment } from '../../enums/route-segment.enum';
import { DropdownRef } from '../dropdown/dropdown-ref';
import { DynamicMenuItemType, DynamicMenuEntry, DynamicMenuItem } from './dynamic-menu-item.interface';

@Component({
  selector: 'app-dynamic-menu-item',
  standalone: true,
  imports: [
    CommonModule,
    NgxJsonLdModule,
    TranslateModule,
    ScrollManagerModule,
    FormsModule,
    ReactiveFormsModule,
    ComplexTranslationModule,
    TextFieldModule,
    RouterModule,
    LetDirective,
    SvgIconComponent,
    ButtonComponent,
    SafeHtmlPipe,
    ScrollerComponent
  ],
  templateUrl: './dynamic-menu-item.component.html',
  styleUrl: './dynamic-menu-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicMenuItemComponent {
  @Input() public menu?: DynamicMenuEntry | DynamicMenuItem;
  @Output() public closeMenu = new EventEmitter<void>();

  protected breakpointService = inject(BreakpointService);
  protected readonly dropdownRef = inject(DropdownRef, { optional: true });

  protected readonly DynamicMenuItemType = DynamicMenuItemType;
  protected readonly LinkRecordType = LinkRecordType;
  protected readonly RouteSegment = RouteSegment;
  protected selectedMenuItem: WritableSignal<DynamicMenuItem | null> = signal(null);
  protected injectedMenu: DynamicMenuEntry | DynamicMenuItem | null = inject<DynamicMenuEntry | DynamicMenuItem | null>(DROPDOWN_DATA, {
    optional: true
  });
  protected get dynamicMenu(): DynamicMenuEntry | DynamicMenuItem | undefined | null {
    if (this.menu || this.injectedMenu) {
      return this.menu || this.injectedMenu;
    } else {
      throw new Error('DynamicMenuItemComponent: You must provide either a menu input or provide one through the DROPDOWN_DATA token.');
    }
  }

  @HostListener('mouseleave') public onMouseLeave(): void {
    if (this.breakpointService.upMd) {
      this.selectMenuItem();
    }
  }

  public selectMenuItem(menuItem?: DynamicMenuItem, ignore?: boolean): void {
    if (ignore) {
      return;
    }
    if (menuItem) {
      this.selectedMenuItem.set(menuItem);
    } else {
      this.selectedMenuItem.set(null);
    }
  }

  protected closeMenuClick(): void {
    this.dropdownRef?.dismiss();
  }
}
