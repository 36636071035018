import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { appCheckIcon } from '@icons/check';
import { appCloseIcon } from '@icons/close';
import { appInfoCircleIcon } from '@icons/info-circle';
import { appRoadSignIcon } from '@icons/road-sign';
import { SharedModule } from '@shared/shared.module';
import { provideSvgIcons } from '@ngneat/svg-icon';
import { ToastMessageWrapperComponent } from './toast-message-wrapper.component';
import { ToastMessageComponent } from './toast-message.component';
import { ToastMessageService } from './toast-message.service';
import { ToastMessageAnchorDirective } from './toast-message-anchor.directive';

@NgModule({
  declarations: [ToastMessageComponent, ToastMessageWrapperComponent, ToastMessageAnchorDirective],
  imports: [CommonModule, OverlayModule, PortalModule, SharedModule],
  providers: [provideSvgIcons([appCheckIcon, appRoadSignIcon, appCloseIcon, appInfoCircleIcon])],
  exports: [ToastMessageComponent, ToastMessageAnchorDirective]
})
export class ToastMessageModule {
  public static forRoot(): ModuleWithProviders<ToastMessageModule> {
    return { ngModule: ToastMessageModule, providers: [ToastMessageService] };
  }
}
