import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { badgeRevealAnimation } from './animations/cta-badge.animations';

@Component({
  selector: 'app-cta-badge',
  templateUrl: './cta-badge.component.html',
  styleUrls: ['./cta-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [badgeRevealAnimation]
})
export class CtaBadgeComponent {
  @HostBinding('@revealAnimation') public get reveal(): boolean {
    return true;
  }
}
