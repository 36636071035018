interface MediaQueryBoundary {
  lower: number;
  upper: number;
}

// Keep these in sync with the breakpoints in _bootstrap-variables.scss
const mqs = {
  xs: {
    lower: 0,
    upper: 575
  },
  sm: {
    lower: 576,
    upper: 767
  },
  md: {
    lower: 768,
    upper: 991
  },
  lg: {
    lower: 992,
    upper: 1199
  },
  xl: {
    lower: 1200,
    upper: 1399
  },
  xxl: {
    lower: 1400,
    upper: Number.POSITIVE_INFINITY
  }
};

const getObserverFormOrMediaQuery = (mqAlias: keyof typeof mqs) => {
  return [minWidthStringOf(mqs[mqAlias]), maxWidthStringOf(mqs[mqAlias])];
};

const getObserverFormOfUpFromMediaQuery = (mqAlias: keyof typeof mqs): string => {
  switch (mqAlias) {
    case 'xs': {
      return minWidthStringOf(mqs['xs']);
    }
    case 'sm': {
      return minWidthStringOf(mqs['sm']);
    }
    case 'md': {
      return minWidthStringOf(mqs['md']);
    }
    case 'lg': {
      return minWidthStringOf(mqs['lg']);
    }
    case 'xl': {
      return minWidthStringOf(mqs['xl']);
    }
    default: {
      return minWidthStringOf(mqs['xxl']);
    }
  }
};

const getObserverFormOfDownFromMediaQuery = (mqAlias: keyof typeof mqs): string => {
  switch (mqAlias) {
    case 'xs': {
      return maxWidthStringOf(mqs['xs']);
    }
    case 'sm': {
      return maxWidthStringOf(mqs['sm']);
    }
    case 'md': {
      return maxWidthStringOf(mqs['md']);
    }
    case 'lg': {
      return maxWidthStringOf(mqs['lg']);
    }
    case 'xl': {
      return maxWidthStringOf(mqs['xl']);
    }
    default: {
      return maxWidthStringOf(mqs['xxl']);
    }
  }
};

const minWidthStringOf = (boundary: MediaQueryBoundary): string => {
  return `(min-width: ${boundary.lower}px)`;
};

const maxWidthStringOf = (boundary: MediaQueryBoundary): string => {
  return `(max-width: ${boundary.upper}px)`;
};

export const mediaQueries = {
  xs: getObserverFormOrMediaQuery('xs'),
  sm: getObserverFormOrMediaQuery('sm'),
  md: getObserverFormOrMediaQuery('md'),
  lg: getObserverFormOrMediaQuery('lg'),
  xl: getObserverFormOrMediaQuery('xl'),
  xxl: getObserverFormOrMediaQuery('xxl'),
  upXs: getObserverFormOfUpFromMediaQuery('xs'),
  upSm: getObserverFormOfUpFromMediaQuery('sm'),
  upMd: getObserverFormOfUpFromMediaQuery('md'),
  upLg: getObserverFormOfUpFromMediaQuery('lg'),
  upXl: getObserverFormOfUpFromMediaQuery('xl'),
  upXxl: getObserverFormOfUpFromMediaQuery('xxl'),
  downXs: getObserverFormOfDownFromMediaQuery('xs'),
  downSm: getObserverFormOfDownFromMediaQuery('sm'),
  downMd: getObserverFormOfDownFromMediaQuery('md'),
  downLg: getObserverFormOfDownFromMediaQuery('lg'),
  downXl: getObserverFormOfDownFromMediaQuery('xl'),
  downXxl: getObserverFormOfDownFromMediaQuery('xxl')
};
