import { NgModule } from '@angular/core';
import { RouterModule, ROUTES, Routes } from '@angular/router';
import { PageNotFoundComponent } from '@shared/components/page-not-found/page-not-found.component';
import { RouteParam, RouteSegment } from '@shared/enums/route-segment.enum';
import { HomeModule } from '@shop/pages/home/home.module';
import { of } from 'rxjs';
import { BreadcrumbProductNameResolver } from '@shared/modules/breadcrumb/resolvers/breadcrumb-product-name-resolver.service';
import { BarcodeActivationSuccessComponent } from '@shared/components/barcode-activation-success/barcode-activation-success.component';
import { DatoCampaignRoutesService } from '@core/services/dato/data-services/campaign-routes.service';
import { BreadcrumbArticleNameResolver } from '@shared/modules/breadcrumb/resolvers/breadcrumb-article-name-resolver.service';
import { BreadcrumbGenericPageNameResolver } from '@shared/modules/breadcrumb/resolvers/breadcrumb-generic-page-name-resolver.service';
import { ShopLayoutComponent } from './layout/shop-layout.component';
import { CheckoutGuard } from './pages/checkout/guards/checkout.guard';
import { CheckoutLayoutComponent } from './pages/checkout/layout/checkout-layout.component';
import { BarcodeActivationGuard } from './pages/barcode-activation/guard/barcode-activation.guard';

const provideRoutes = (datoCampaignRoutesService: DatoCampaignRoutesService): Routes => {
  return [
    {
      path: RouteSegment.Checkout,
      component: CheckoutLayoutComponent,
      canLoad: [CheckoutGuard],
      canActivate: [CheckoutGuard],
      runGuardsAndResolvers: 'always',
      loadChildren: () => import('./pages/checkout/checkout.module').then((m) => m.CheckoutModule)
    },
    {
      path: RouteSegment.Root,
      component: ShopLayoutComponent,
      data: { breadcrumb: 'breadcrumb.home' },
      children: [
        {
          path: RouteSegment.NotFound,
          title: 'page-title.not-found',
          component: PageNotFoundComponent
        },
        {
          path: RouteSegment.Root,
          loadChildren: () => of(HomeModule),
          pathMatch: 'full'
        },
        {
          path: RouteSegment.BarcodeActivation,
          data: { breadcrumb: 'breadcrumb.activate-tests' },
          children: [
            {
              path: RouteSegment.Root,
              loadChildren: () =>
                import('./pages/barcode-activation/screens/barcode-email-screen/barcode-email-screen.module').then(
                  (m) => m.BarcodeEmailScreenModule
                )
            },
            {
              path: RouteSegment.BarcodeActivationExisting,
              canActivate: [BarcodeActivationGuard],
              loadChildren: () => import('./pages/barcode-activation/barcode-activation.module').then((m) => m.BarcodeActivationModule)
            },
            {
              path: RouteSegment.BarcodeActivationNew,
              loadChildren: () => import('./pages/barcode-activation/barcode-activation.module').then((m) => m.BarcodeActivationModule)
            }
          ]
        },
        {
          path: RouteSegment.BarcodeActivationSuccess,
          component: BarcodeActivationSuccessComponent
        },
        {
          path: RouteSegment.AvailableTests,
          title: 'page-title.available-tests',
          data: { breadcrumb: 'breadcrumb.our-tests' },
          children: [
            {
              path: RouteSegment.Root,
              loadChildren: () => import('./pages/available-tests/available-tests.module').then((m) => m.AvailableTestsModule)
            },
            {
              path: `:${RouteParam.ProductSku}`,
              data: { productBreadcrumb: true },
              runGuardsAndResolvers: 'always',
              loadChildren: () => import('./pages/available-tests/pages/product/product.module').then((m) => m.ProductModule)
            }
          ]
        },
        {
          path: RouteSegment.Faq,
          data: { breadcrumb: 'breadcrumb.faq' },
          loadChildren: () => import('./pages/faq/faq.module').then((m) => m.FaqModule)
        },
        {
          path: RouteSegment.ContactUs,
          title: 'page-title.contact',
          data: { breadcrumb: 'breadcrumb.contact-us' },
          loadChildren: () => import('./pages/contact-us/contact-us.module').then((m) => m.ContactUsModule)
        },
        {
          path: RouteSegment.ContactUsB2b,
          title: 'page-title.contact',
          data: { breadcrumb: 'breadcrumb.contact-us' },
          loadChildren: () => import('./pages/contact-us-b2b/contact-us-b2b.module').then((m) => m.ContactUsB2bModule)
        },
        {
          path: RouteSegment.TheMethod,
          data: { breadcrumb: 'breadcrumb.how-it-works' },
          loadChildren: () => import('./pages/method/method.module').then((m) => m.MethodModule)
        },
        {
          path: RouteSegment.AboutUs,
          data: { breadcrumb: 'breadcrumb.about-us' },
          loadChildren: () => import('./pages/about-us/about-us.module').then((m) => m.AboutUsModule)
        },
        {
          path: RouteSegment.KnowledgeBase,
          data: { breadcrumb: 'breadcrumb.knowledge-base' },
          children: [
            {
              path: RouteSegment.Root,
              loadChildren: () => import('./pages/knowledge-base/knowledge-base.module').then((m) => m.KnowledgeBaseModule)
            },
            {
              path: `:${RouteParam.Slug}`,
              data: { articleBreadcrumb: true },
              loadChildren: () => import('./pages/knowledge-base/pages/article/article.module').then((m) => m.ArticleModule)
            }
          ]
        },
        {
          path: RouteSegment.Disclaimer,
          data: { breadcrumb: 'breadcrumb.disclaimer' },
          loadChildren: () => import('./pages/disclaimer/disclaimer.module').then((m) => m.DisclaimerModule)
        },
        {
          path: RouteSegment.Complaints,
          data: { breadcrumb: 'breadcrumb.complaints' },
          loadChildren: () => import('./pages/complaints/complaints.module').then((m) => m.ComplaintsModule)
        },
        {
          path: RouteSegment.PaymentMethods,
          data: { breadcrumb: 'breadcrumb.payment-methods' },
          loadChildren: () => import('./pages/payment-methods/payment-methods.module').then((m) => m.PaymentMethodsModule)
        },
        {
          path: RouteSegment.TestkitManual,
          title: 'page-title.testkit-manual',
          data: { breadcrumb: 'breadcrumb.testkit-manual' },
          loadChildren: () => import('./pages/testkit-manual/testkit-manual.module').then((m) => m.TestkitManualModule)
        },
        {
          path: RouteSegment.PrivacyPolicy,
          data: { breadcrumb: 'breadcrumb.privacy-policy' },
          loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule)
        },
        {
          path: RouteSegment.ReturnsPolicy,
          data: { breadcrumb: 'breadcrumb.returns-policy' },
          loadChildren: () => import('./pages/returns-policy/returns-policy.module').then((m) => m.ReturnsPolicyModule)
        },
        {
          path: RouteSegment.TermsAndConditions,
          data: { breadcrumb: 'breadcrumb.terms-and-conditions' },
          loadChildren: () => import('./pages/terms-and-conditions/terms-and-conditions.module').then((m) => m.TermsAndConditionsModule)
        },
        {
          path: RouteSegment.ForgotPassword,
          title: 'page-title.forgot-password',
          loadChildren: () => import('../core/auth/modules/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule)
        },
        {
          path: RouteSegment.ResetPassword,
          loadChildren: () => import('../core/auth/modules/reset-password/reset-password.module').then((m) => m.ResetPasswordModule)
        },
        {
          path: RouteSegment.Test,
          data: { breadcrumb: 'breadcrumb.test' },
          loadChildren: () => import('./pages/triage/triage.module').then((m) => m.TriageModule)
        },
        {
          path: RouteSegment.MeetTheTeam,
          data: { breadcrumb: 'breadcrumb.meet-the-team' },
          loadChildren: () => import('./pages/meet-the-team/meet-the-team.module').then((m) => m.MeetTheTeamModule)
        },
        ...datoCampaignRoutesService.campaignRoutes,
        {
          path: RouteSegment.AnyOther,
          title: 'page-title.not-found',
          component: PageNotFoundComponent
        }
      ]
    }
  ];
};

@NgModule({
  exports: [RouterModule],
  providers: [
    BreadcrumbProductNameResolver,
    BreadcrumbArticleNameResolver,
    BreadcrumbGenericPageNameResolver,
    {
      provide: ROUTES,
      useFactory: provideRoutes,
      deps: [DatoCampaignRoutesService],
      multi: true
    }
  ]
})
export class ShopRoutingModule {}
