import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { RouteSegment } from '@shared/enums/route-segment.enum';
import { PageService } from '@shared/services/page.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageNotFoundComponent implements OnInit {
  private readonly pageService = inject(PageService);
  public readonly RouteSegment = RouteSegment;

  public ngOnInit(): void {
    this.pageService.setMeta([
      {
        attributes: { name: 'robots', content: 'noindex, nofollow' }
      }
    ]);
  }
}
