import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';

import { EffectsModule } from '@ngrx/effects';
import { featureKeyReviews, reducerReviews } from './store/reviews.reducer';
import { ReviewsEffects } from './store/reviews.effects';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature(featureKeyReviews, reducerReviews), EffectsModule.forFeature([ReviewsEffects])]
})
export class ReviewsModule {}
