<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 499 221">
  <g opacity=".2">
    <path
      d="M129.839 37.493c10.674-16.367 34.648-16.367 45.322 0a30.054 30.054 0 0 0 33.905 12.34c18.697-5.676 37.062 9.733 34.718 29.133a30.055 30.055 0 0 0 18.041 31.247c17.972 7.669 22.135 31.279 7.87 44.633a30.055 30.055 0 0 0-6.266 35.533c8.838 17.427-3.149 38.189-22.66 39.249a30.055 30.055 0 0 0-27.64 23.193c-4.433 19.031-26.96 27.23-42.589 15.501a30.051 30.051 0 0 0-36.08 0c-15.629 11.729-38.157 3.53-42.589-15.501a30.055 30.055 0 0 0-27.64-23.193c-19.511-1.06-31.498-21.822-22.66-39.249a30.054 30.054 0 0 0-6.266-35.533c-14.265-13.354-10.102-36.964 7.87-44.633a30.055 30.055 0 0 0 18.04-31.247c-2.343-19.4 16.022-34.81 34.72-29.133a30.054 30.054 0 0 0 33.904-12.34Z"
      stroke="#fff"
      stroke-width="3"
    />
    <circle cx="152.129" cy="80.812" r="5.819" transform="rotate(45 152.129 80.812)" fill="#fff" />
    <circle cx="187.788" cy="116.471" r="5.819" transform="rotate(45 187.788 116.471)" fill="#fff" />
    <circle cx="223.446" cy="152.129" r="5.819" transform="rotate(45 223.446 152.129)" fill="#fff" />
    <circle cx="116.47" cy="116.471" r="5.819" transform="rotate(45 116.47 116.471)" fill="#fff" />
    <circle cx="152.129" cy="152.129" r="5.819" transform="rotate(45 152.129 152.129)" fill="#fff" />
    <circle cx="187.788" cy="187.788" r="5.819" transform="rotate(45 187.788 187.788)" fill="#fff" />
    <circle cx="80.811" cy="152.129" r="5.819" transform="rotate(45 80.811 152.129)" fill="#fff" />
    <circle cx="116.47" cy="187.788" r="5.819" transform="rotate(45 116.47 187.788)" fill="#fff" />
    <circle cx="152.129" cy="223.446" r="5.819" transform="rotate(45 152.129 223.446)" fill="#fff" />
  </g>
  <g opacity=".2">
    <path
      d="M410.134 144.645c5.14-6.997 15.592-6.997 20.732 0a15.86 15.86 0 0 0 17.605 5.721c8.271-2.64 16.727 3.504 16.772 12.185a15.863 15.863 0 0 0 10.881 14.977c8.243 2.725 11.473 12.666 6.407 19.716a15.86 15.86 0 0 0 0 18.512c5.066 7.05 1.836 16.991-6.407 19.716a15.863 15.863 0 0 0-10.881 14.977c-.045 8.681-8.501 14.825-16.772 12.185a15.86 15.86 0 0 0-17.605 5.721c-5.14 6.997-15.592 6.997-20.732 0a15.862 15.862 0 0 0-17.606-5.721c-8.27 2.64-16.726-3.504-16.771-12.185a15.863 15.863 0 0 0-10.881-14.977c-8.243-2.725-11.473-12.666-6.407-19.716a15.86 15.86 0 0 0 0-18.512c-5.066-7.05-1.836-16.991 6.407-19.716a15.863 15.863 0 0 0 10.881-14.977c.045-8.681 8.501-14.825 16.771-12.185a15.862 15.862 0 0 0 17.606-5.721Z"
      stroke="#fff"
      stroke-width="3"
    />
    <circle cx="420.309" cy="169.598" r="2.995" transform="rotate(45 420.309 169.598)" fill="#fff" />
    <circle cx="438.665" cy="187.954" r="2.995" transform="rotate(45 438.665 187.954)" fill="#fff" />
    <circle cx="457.02" cy="206.309" r="2.995" transform="rotate(45 457.02 206.309)" fill="#fff" />
    <circle cx="401.954" cy="187.954" r="2.995" transform="rotate(45 401.954 187.954)" fill="#fff" />
    <circle cx="420.309" cy="206.309" r="2.995" transform="rotate(45 420.309 206.309)" fill="#fff" />
    <circle cx="438.665" cy="224.665" r="2.995" transform="rotate(45 438.665 224.665)" fill="#fff" />
    <circle cx="383.598" cy="206.309" r="2.995" transform="rotate(45 383.598 206.309)" fill="#fff" />
    <circle cx="401.954" cy="224.665" r="2.995" transform="rotate(45 401.954 224.665)" fill="#fff" />
  </g>
  <g opacity=".2">
    <path
      d="M331.984 21.724c2.983-4.061 9.049-4.061 12.032 0a10.464 10.464 0 0 0 11.615 3.774c4.8-1.532 9.708 2.034 9.734 7.072a10.466 10.466 0 0 0 7.179 9.88c4.784 1.582 6.658 7.352 3.718 11.443a10.465 10.465 0 0 0 0 12.214c2.94 4.091 1.066 9.86-3.718 11.442a10.466 10.466 0 0 0-7.179 9.881c-.026 5.038-4.934 8.604-9.734 7.072a10.464 10.464 0 0 0-11.615 3.774c-2.983 4.061-9.049 4.061-12.032 0a10.464 10.464 0 0 0-11.615-3.774c-4.8 1.532-9.708-2.033-9.734-7.072a10.466 10.466 0 0 0-7.179-9.88c-4.784-1.582-6.658-7.352-3.718-11.443a10.465 10.465 0 0 0 0-12.214c-2.94-4.091-1.066-9.86 3.718-11.443a10.466 10.466 0 0 0 7.179-9.88c.026-5.038 4.934-8.604 9.734-7.072a10.464 10.464 0 0 0 11.615-3.774Z"
      stroke="#fff"
      stroke-width="3"
    />
    <circle cx="337.881" cy="36.966" r="1.87" transform="rotate(45 337.881 36.966)" fill="#fff" />
    <circle cx="349.339" cy="48.423" r="1.87" transform="rotate(45 349.339 48.423)" fill="#fff" />
    <circle cx="360.796" cy="59.881" r="1.87" transform="rotate(45 360.796 59.88)" fill="#fff" />
    <circle cx="326.423" cy="48.423" r="1.87" transform="rotate(45 326.423 48.423)" fill="#fff" />
    <circle cx="337.881" cy="59.881" r="1.87" transform="rotate(45 337.881 59.88)" fill="#fff" />
    <circle cx="349.339" cy="71.338" r="1.87" transform="rotate(45 349.339 71.338)" fill="#fff" />
    <circle cx="314.966" cy="59.881" r="1.87" transform="rotate(45 314.966 59.88)" fill="#fff" />
    <circle cx="326.423" cy="71.338" r="1.87" transform="rotate(45 326.423 71.338)" fill="#fff" />
    <circle cx="337.881" cy="82.796" r="1.87" transform="rotate(45 337.881 82.796)" fill="#fff" />
  </g>
</svg>
