import { gql } from '@apollo/client';

export const FooterSubGroupFragment = gql`
  fragment FooterSubGroup on FooterSubGroupRecord {
    id
    title
    type: __typename
    links {
      ... on RegularLinkRecord {
        id
        type: __typename
        title: linkText
        route
        fragment
        url
      }
      ... on ProductRecord {
        id
        type: __typename
        title
        sku
        slug
      }
    }
  }
`;
