import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CookieCategory } from '@shared/modules/cookie-policy/interfaces/cookie.interface';
import { mapCookiesToCookieCategories } from '../../../../../domain/mappers/map-cookies';
import { featureKeyCookiePolicy, FeatureStateCookiePolicy } from './cookie-policy.reducer';

const selectCookiePolicyFeature = createFeatureSelector<FeatureStateCookiePolicy>(featureKeyCookiePolicy);

export const selectCookiesList = createSelector(selectCookiePolicyFeature, (state): CookieCategory[] | undefined => {
  return state.cookiesList.data ? mapCookiesToCookieCategories(state.cookiesList.data) : undefined;
});
