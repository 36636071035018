import { Pipe, PipeTransform } from '@angular/core';

interface InterpolateArgs {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;

  key?: string;
}

@Pipe({
  name: 'interpolate'
})
export class InterpolatePipe implements PipeTransform {
  public transform(value: string, args: InterpolateArgs): string {
    const regex = /{{\s*(.*?)\s*}}/g;

    return value.replaceAll(regex, (match, key) => {
      if (args && args.hasOwnProperty(key)) {
        return args[key];
      }
      return match;
    });
  }
}
