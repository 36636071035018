import { createReducer, on } from '@ngrx/store';
import { DatoCookiesList } from '@core/services/dato/interfaces/cookies-list.interface';
import { ApiError } from '@datocms/cma-client-node';
import { Loadable } from '@shared/interfaces/loadable.interface';
import { getCookiesList, getCookiesListError, getCookiesListSuccess } from '@shared/modules/cookie-policy/store/cookie-policy.actions';
import { LoadableStateReducerHelper } from '@shared/helpers/loadable-state-reducer.helper';

export const featureKeyCookiePolicy = 'cookiePolicy';

export interface FeatureStateCookiePolicy {
  cookiesList: Loadable<DatoCookiesList, ApiError>;
}

export interface AppState {
  [featureKeyCookiePolicy]: FeatureStateCookiePolicy;
}

export const initialStateCookiePolicy: FeatureStateCookiePolicy = {
  cookiesList: { isLoading: false }
};

export const reducerCookiePolicy = createReducer(
  initialStateCookiePolicy,
  on(getCookiesList, (state) => ({
    ...state,
    cookiesList: LoadableStateReducerHelper.startLoad(state.cookiesList)
  })),
  on(getCookiesListSuccess, (state, { cookiesList }) => ({
    ...state,
    cookiesList: LoadableStateReducerHelper.loadSuccess(cookiesList)
  })),
  on(getCookiesListError, (state, { error }) => ({
    ...state,
    cookiesList: LoadableStateReducerHelper.loadError(state.cookiesList, error)
  }))
);
