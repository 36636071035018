import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, Optional, TrackByFunction } from '@angular/core';
import { ControlsOf, FormBuilder, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { TranslateService } from '@ngx-translate/core';
import { BottomSheetAndDialogRef } from '@shared/components/bottom-sheet/bottom-sheet-and-dialog-ref';
import { RouteSegment } from '@shared/enums/route-segment.enum';
import { BOTTOM_SHEET_AND_DIALOG_DATA } from '@shared/components/bottom-sheet/bottom-sheet-and-dialog-config';
import { Observable } from 'rxjs';
import { CookiePolicyService } from '../../cookie-policy.service';
import { CookieConsentPreference } from '../../enums/cookie-consents.enum';
import { CookieCategory, CookiePreferences } from '../../interfaces/cookie.interface';

@Component({
  selector: 'app-cookie-policy-dialog',
  templateUrl: './cookie-policy-dialog.component.html',
  styleUrls: ['./cookie-policy-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookiePolicyDialogComponent implements OnInit, OnDestroy {
  public readonly defaultCookieCategories = this.cookiePolicyService.defaultCookieCategories;
  public form: FormGroup<ControlsOf<Record<string, unknown>>> = this.formBuilder.group({});
  public readonly RouteSegment = RouteSegment;

  constructor(
    @Optional() @Inject(BOTTOM_SHEET_AND_DIALOG_DATA) public readonly cookieCategories$: Observable<CookieCategory[]>,
    private readonly dialogRef: BottomSheetAndDialogRef,
    private readonly cookiePolicyService: CookiePolicyService,
    private readonly formBuilder: FormBuilder,
    private readonly translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.buildFormControls();
  }

  public ngOnDestroy(): void {
    this.closeDialog();
  }

  public closeDialog(): void {
    this.dialogRef.dismiss();
  }

  public onSubmit(): void {
    this.cookiePolicyService.updatePreferences(this.mapFormValueToCookieConsentPreference(this.form.value as CookiePreferences));
    this.closeDialog();
  }

  public onRejectClick(): void {
    this.cookiePolicyService.rejectAll();
    this.closeDialog();
  }

  public hasTranslation(cookieId: string): boolean {
    // TODO: Really ugly solution for checking existence of a translation string.
    const translation = this.translateService.instant(`cookie-policy.cookies.${cookieId}`);
    return !translation.startsWith('cookie-policy.cookies.');
  }

  public trackByFn: TrackByFunction<CookieCategory> = (index) => index;

  private mapFormValueToCookieConsentPreference(form: CookiePreferences): CookiePreferences {
    this.defaultCookieCategories.forEach((category) => {
      form[category.type] = form[category.type] ? CookieConsentPreference.Allow : CookieConsentPreference.Deny;
    });
    return form;
  }

  private buildFormControls(): void {
    this.defaultCookieCategories
      .filter((category) => !category.required)
      .forEach((category) => {
        this.form.addControl(category.type, new FormControl(category.checked));
      });
  }
}
