import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '@shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { appEyeNoIcon } from '@icons/eye-no';
import { appEyeYesIcon } from '@icons/eye-yes';
import { RouterModule } from '@angular/router';
import { appCloseIcon } from '@icons/close';
import { ProfileStoreModule } from '@shared/store/profile/profile-store.module';
import { OtpInputModule } from '@shared/modules/otp-input/otp-input.module';
import { appClockOutlineIcon } from '@icons/clock-outline';
import { appSignOkIcon } from '@icons/sign-ok';
import { provideSvgIcons } from '@ngneat/svg-icon';
import { featureKeyAuth, reducerAuth } from './store/auth.reducer';
import { AuthEffects } from './store/auth.effects';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';

@NgModule({
  declarations: [LoginFormComponent],
  imports: [
    SharedModule,
    ReactiveFormsModule,
    RouterModule,
    ProfileStoreModule,
    OtpInputModule,
    StoreModule.forFeature(featureKeyAuth, reducerAuth),
    EffectsModule.forFeature([AuthEffects])
  ],
  providers: [AuthService, AuthGuard, provideSvgIcons([appEyeNoIcon, appEyeYesIcon, appCloseIcon, appClockOutlineIcon, appSignOkIcon])]
})
export class AuthModule {}
