import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  template: '<div [innerHTML]="(content$ | async)! | safeHtml"></div>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastMessageWrapperComponent {
  @Input() public content$!: Observable<string>;
}
