<label *ngIf="label" title="{{ label }}" class="form-label body-tiny-medium">
  {{ label }}
  <span class="color-text300" *ngIf="showOptionalLabel && !required">({{ 'common.optional' | translate }})</span>
</label>
<div
  class="d-flex"
  [ngClass]="{
    disabled: control.disabled,
    error: hasError
  }"
>
  <button
    ddButton
    class="quantity-button"
    type="icon"
    level="tertiary"
    [attr.disabled]="nativeInputValue === min || control.disabled || null"
    (click)="decreaseValue()"
    (focus)="focused = true"
    (blur)="focused = false; onBlur()"
  >
    <svg-icon key="minus" fontSize="16px"></svg-icon>
  </button>
  <input
    #input
    class="quantity-input font-weight-bold"
    [attr.min]="min"
    [attr.max]="max"
    [attr.disabled]="control.disabled || null"
    [(ngModel)]="nativeInputValue"
    (ngModelChange)="onValueChange($event)"
    [ngModelOptions]="{
      standalone: true
    }"
    type="number"
    (focus)="focused = true"
    (blur)="focused = false; onBlur()"
    readonly
    tabindex="-1"
  />
  <button
    ddButton
    class="quantity-button"
    type="icon"
    level="tertiary"
    size="extra-small"
    [attr.disabled]="nativeInputValue === max || control.disabled || null"
    (click)="increaseValue()"
    (focus)="focused = true"
    (blur)="focused = false; onBlur()"
  >
    <svg-icon key="plus" fontSize="16px"></svg-icon>
  </button>
</div>
<small *ngIf="hint || error" class="form-hint body-tiny-regular">
  {{ hasError ? error : hint }}
</small>
