import { Injectable } from '@angular/core';
import { getProduct, getProducts } from '@shop/store/products.actions';
import { selectProduct } from '@shop/store/products.selectors';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { getRouterSelectors } from '@ngrx/router-store';

const DEFAULT_PRODUCTS_LIMIT = 10;

@Injectable({
  providedIn: 'root'
})
export class ShopService {
  public readonly product$ = this.store.select(selectProduct);
  public readonly scrolledPastAddToCartButton$ = new BehaviorSubject<boolean>(false);

  private readonly routerSelectors = {
    ...getRouterSelectors()
  };

  constructor(private readonly store: Store) {}

  public getProducts(limit: number = DEFAULT_PRODUCTS_LIMIT): void {
    this.store.dispatch(getProducts({ limit }));
  }

  public getProduct(handle: string): void {
    this.store.dispatch(getProduct({ handle }));
  }

  public observeAddToCartButton(isAboveViewport: boolean): void {
    this.scrolledPastAddToCartButton$.next(!isAboveViewport);
  }
}
