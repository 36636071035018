import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService implements Storage {
  public get length(): number {
    return window.localStorage.length;
  }

  public getItem<T>(key: string): T | null {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }

  public setItem<T>(key: string, value: T): void {
    window.localStorage.setItem(key, JSON.stringify(value));
  }

  public removeItem(key: string): void {
    window.localStorage.removeItem(key);
  }

  public clear(): void {
    window.localStorage.clear();
  }

  public key(index: number): string | null {
    return window.localStorage.key(index);
  }
}
