import { AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@ngneat/reactive-forms';
import { onlyNumberKeys } from '@shared/utils/only-number-keys';
import { NgOtpInputComponent } from 'ng-otp-input';
import { Config } from 'ng-otp-input/lib/models/config';

@Component({
  selector: 'app-otp-input',
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class OtpInputComponent implements AfterViewInit {
  @Input() public control!: FormControl<string>;
  @Input() public hint?: string;
  @Input() public error?: string;
  @Input() public touch?: boolean;
  @ViewChild(NgOtpInputComponent, { static: false }) public input!: NgOtpInputComponent;

  public config: Config = {
    length: 6,
    allowNumbersOnly: true,
    inputClass: 'otp-input',
    containerClass: 'otp-input-wrapper'
  };

  public get hasError(): boolean {
    return !this.control.valid && !!this.touch && this.control.value.length === 0;
  }

  public onKeyDown(event: KeyboardEvent): void {
    onlyNumberKeys(event);
  }

  public ngAfterViewInit(): void {
    this.input.setValue(this.control.value);
  }
}
