import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, debounceTime, skip, tap } from 'rxjs';
import { UpdateCartItemRequest } from 'src/domain/cart/update-cart-item-request';
import { CartItemResponse } from '@shop/modules/cart/cart.interface';
import { RouteSegment } from '@shared/enums/route-segment.enum';
import { TagEvent } from '@shared/enums/tags/tag-event.enum';
import { Tag } from '@shared/interfaces/tags/tag.type';

@UntilDestroy()
@Component({
  selector: 'app-cart-product-card',
  templateUrl: './cart-product-card.component.html',
  styleUrls: ['./cart-product-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartProductCardComponent implements OnInit {
  @Input() public cartItem!: CartItemResponse;
  @Input() public isLoading!: boolean;
  @Output() public changeQuantity: EventEmitter<UpdateCartItemRequest> = new EventEmitter<UpdateCartItemRequest>();
  @Output() public delete = new EventEmitter<void>();
  @Output() public productUrlClick = new EventEmitter<void>();
  public form = this.formBuilder.group({
    quantity: [0]
  });
  public quantity$ = new BehaviorSubject<number>(0);
  public delete$ = new BehaviorSubject<boolean>(false);
  // To prevent multiple requests when the user changes quantity too fast.
  private readonly UPDATE_DEBOUNCE_TIME = 500;

  constructor(private readonly formBuilder: FormBuilder) {}

  public get productUrl(): string[] {
    return [RouteSegment.Root, RouteSegment.AvailableTests, this.cartItem.handle];
  }

  public get viewItemTag(): Tag {
    return {
      event: TagEvent.ViewItem,
      ecommerce: {
        items: [
          {
            currency: 'EUR',
            item_id: this.cartItem.productId,
            item_name: this.cartItem.sku,
            price: this.cartItem.unitPrice,
            item_brand: this.cartItem.vendor,
            item_category: this.cartItem.productType,
            sku: this.cartItem.sku
          }
        ]
      }
    };
  }

  public ngOnInit(): void {
    this.subscribeToQuantityChange();
    this.form.patchValue({
      quantity: this.cartItem.quantity
    });
    this.quantity$.next(this.cartItem.quantity);
  }

  public subscribeToQuantityChange(): void {
    this.form.controls.quantity.valueChanges
      .pipe(
        untilDestroyed(this),
        skip(1),
        tap((quantity) => this.quantity$.next(quantity)),
        debounceTime(this.UPDATE_DEBOUNCE_TIME)
      )
      .subscribe((quantity) => {
        this.quantity$.next(quantity);
        this.changeCartQuantity(quantity);
      });
  }

  public changeCartQuantity(quantity: number): void {
    if (quantity >= 1) {
      this.changeQuantity.emit({ cartItemId: this.cartItem.id, cartItem: this.cartItem, quantity });
    }
  }

  public removeItemFromCart(): void {
    this.delete$.next(true);
    this.delete.emit();
    this.changeQuantity.emit({ cartItemId: this.cartItem.id, cartItem: this.cartItem, quantity: 0 });
  }
}
