import { Pipe, PipeTransform } from '@angular/core';

/**
 * A pipe that takes two params: a translation key and a counting number. From the counting number,
 * it determines whether the plural, singular or none form of the key should be used.
 *
 * @example
 * {{ 'cart.header' | pluralTranslate: vm.itemCount | translate: { itemCount: vm.itemCount } }}
 *
 */
@Pipe({
  name: 'replaceValue'
})
export class ReplaceValuePipe implements PipeTransform {
  public transform(value: string, replaceValue: { [key: string]: string }): string {
    const replaceValueKey = Object.keys(replaceValue)[0];
    const valueToReplace = `{{ ${replaceValueKey} }}`;
    if (value.includes(valueToReplace)) {
      return value.replace(valueToReplace, replaceValue[replaceValueKey]);
    }
    return value;
  }
}
