import { createAction, props } from '@ngrx/store';
import { ProductItem } from '@shared/interfaces/product.interface';
import { ApiError } from 'src/domain/api-error';
import { ProductItemBatch } from 'src/domain/product/product-batch';
import { TestProcess } from '@core/services/dato/interfaces/test-process.interface';
import { DatoProductItem } from '@core/services/dato/interfaces/product.interface';
import { DatoProductsPage } from '@core/services/dato/interfaces/products-page.interface';
import { DatoApiError } from 'src/domain/dato-api-error';
import { DatoProductFilter } from '@core/services/dato/interfaces/product-filter.interface';
import { ProductFilterResult } from '../pages/available-tests/pages/product/components/product-filter/product-filter.interface';

const feature = '[Products]';

export const getProducts = createAction(
  `${feature} Get products`,
  props<{ limit?: number; imageWidth?: number; filters?: ProductFilterResult }>()
);
export const getProductsSuccess = createAction(`${feature}  Get products success`, props<{ products: ProductItemBatch }>());
export const getProductsError = createAction(`${feature}  Get products failure`, props<{ error: ApiError }>());

export const getTestProcess = createAction(`${feature} Get test process`);
export const getTestProcessSuccess = createAction(`${feature} Get test process success`, props<{ testProcess: TestProcess }>());
export const getTestProcessError = createAction(`${feature} Get test process error`, props<{ error: ApiError }>());

export const getProductsPage = createAction(`${feature} Get products page`);
export const getProductsPageSuccess = createAction(`${feature} Get products page success`, props<{ productsPage: DatoProductsPage }>());
export const getProductsPageError = createAction(`${feature} Get products page error`, props<{ error: DatoApiError }>());

export const getProductFilters = createAction(`${feature} Get product filters`);
export const getProductFiltersSuccess = createAction(
  `${feature} Get product filters success`,
  props<{ productFilters: DatoProductFilter }>()
);
export const getProductFiltersError = createAction(`${feature} Get product filters error`, props<{ error: DatoApiError }>());

export const getProduct = createAction(`${feature} Get product`, props<{ handle: string }>());
export const getProductSuccess = createAction(
  `${feature} Get product success`,
  props<{ product: ProductItem; crossSellingProducts?: DatoProductItem[] }>()
);
export const getProductError = createAction(`${feature} Get product error`, props<{ error: ApiError }>());

export const getCrossSellingProductsSuccess = createAction(
  `${feature} Get cross-selling product success`,
  props<{ products: ProductItem[] }>()
);
export const getCrossSellingProductsError = createAction(`${feature} Get cross-selling product error`, props<{ error: ApiError }>());

export const clearProduct = createAction(`${feature} Clear selected product`);
