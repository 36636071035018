import { AsyncPipe, DOCUMENT, NgClass } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  Renderer2,
  ViewChild
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, distinctUntilChanged, fromEvent, map, Observable, Subscriber, withLatestFrom } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-scroller',
  templateUrl: './scroller.component.html',
  styleUrls: ['./scroller.component.scss'],
  standalone: true,
  imports: [AsyncPipe, NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollerComponent implements AfterViewInit {
  @Input() public contentClass = '';
  @ViewChild('horizontal') public horizontalThumb!: ElementRef;
  @ViewChild('horizontalBar') public horizontalBar!: ElementRef;
  @ViewChild('vertical') public verticalThumb!: ElementRef;
  @ViewChild('verticalBar') public verticalBar!: ElementRef;
  @ViewChild('content') public content!: ElementRef;

  public horizontalThumbActive = false;
  public verticalThumbActive = false;
  public hasHorizontalBar$ = new BehaviorSubject<boolean>(false);
  public hasVerticalBar$ = new BehaviorSubject<boolean>(false);
  private horizontalThumbDragOffset = 0;
  private verticalThumbDragOffset = 0;

  constructor(
    private readonly renderer: Renderer2,
    private readonly cdr: ChangeDetectorRef,
    private readonly zone: NgZone,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  get verticalScroller(): number {
    const { scrollTop, scrollHeight, clientHeight } = this.content.nativeElement;
    return scrollTop / (scrollHeight - clientHeight);
  }

  get verticalSize(): number {
    const { clientHeight, scrollHeight } = this.content.nativeElement;
    return Math.ceil((clientHeight / scrollHeight) * 100);
  }

  get verticalPosition(): number {
    return this.verticalScroller * (100 - this.verticalSize);
  }

  get horizontalScroller(): number {
    const { scrollLeft, scrollWidth, clientWidth } = this.content.nativeElement;
    return scrollLeft / (scrollWidth - clientWidth);
  }

  get horizontalSize(): number {
    const { clientWidth, scrollWidth } = this.content.nativeElement;
    return Math.ceil((clientWidth / scrollWidth) * 100);
  }

  get horizontalPosition(): number {
    return this.horizontalScroller * (100 - this.horizontalSize);
  }

  public ngAfterViewInit(): void {
    this.listenToScroll();
    this.listenToResizeEvents();
  }

  public onDragEnd(): void {
    this.horizontalThumbActive = false;
    this.verticalThumbActive = false;
  }

  public onVerticalStart(mouseEvent: MouseEvent): void {
    mouseEvent.stopPropagation();

    const { target, clientY } = mouseEvent;
    const { top, height } = (target as HTMLElement).getBoundingClientRect();

    this.verticalThumbDragOffset = (clientY - top) / height;
    this.verticalThumbActive = true;

    const verticalMouseMoveListener = fromEvent<MouseEvent>(this.document, 'mousemove', { capture: true, passive: false })
      .pipe(distinctUntilChanged())
      .subscribe((event) => {
        this.onVerticalMove(event, this.verticalThumb.nativeElement);
      });

    const verticalMouseUpListener = fromEvent<MouseEvent>(this.document, 'mouseup', { capture: true })
      .pipe(distinctUntilChanged())
      .subscribe((_) => {
        this.onDragEnd();
        this.cdr.markForCheck();
        verticalMouseMoveListener.unsubscribe();
        verticalMouseUpListener.unsubscribe();
      });
  }

  public onHorizontalStart(mouseEvent: MouseEvent): void {
    mouseEvent.stopPropagation();

    const { target, clientX } = mouseEvent;
    const { left, width } = (target as HTMLElement).getBoundingClientRect();

    this.horizontalThumbDragOffset = (clientX - left) / width;
    this.horizontalThumbActive = true;

    const horizontalMouseMoveListener = fromEvent<MouseEvent>(this.document, 'mousemove', {
      capture: true,
      passive: false
    })
      .pipe(distinctUntilChanged())
      .subscribe((event) => {
        this.onHorizontalMove(event, this.horizontalThumb.nativeElement);
      });

    const horizontalMouseUpListener = fromEvent<MouseEvent>(this.document, 'mouseup', { capture: true })
      .pipe(distinctUntilChanged())
      .subscribe((_) => {
        this.onDragEnd();
        this.cdr.markForCheck();
        horizontalMouseMoveListener.unsubscribe();
        horizontalMouseUpListener.unsubscribe();
      });
  }

  public onVerticalTouchStart(touchEvent: TouchEvent): void {
    touchEvent.stopPropagation();

    const { target } = touchEvent;
    const changedTouches = touchEvent.changedTouches[0];
    const { clientY } = changedTouches;
    const { top, height } = (target as HTMLElement).getBoundingClientRect();

    this.verticalThumbDragOffset = (clientY - top) / height;
    this.verticalThumbActive = true;

    const verticalTouchMoveListener = fromEvent<TouchEvent>(this.document, 'touchmove', { capture: true })
      .pipe(distinctUntilChanged())
      .subscribe((event) => {
        event.stopPropagation();
        const changedTouch = event.changedTouches[0];
        this.onVerticalMove(changedTouch, this.verticalThumb.nativeElement);
      });

    const verticalTouchEndListener = fromEvent<TouchEvent>(this.document, 'touchend', { capture: true })
      .pipe(distinctUntilChanged())
      .subscribe((_) => {
        this.onDragEnd();
        this.cdr.markForCheck();
        verticalTouchMoveListener.unsubscribe();
        verticalTouchEndListener.unsubscribe();
      });
  }

  public onHorizontalTouchStart(touchEvent: TouchEvent): void {
    touchEvent.stopPropagation();

    const { target } = touchEvent;
    const changedTouches = touchEvent.changedTouches[0];
    const { clientX } = changedTouches;
    const { left, width } = (target as HTMLElement).getBoundingClientRect();

    this.horizontalThumbDragOffset = (clientX - left) / width;
    this.horizontalThumbActive = true;

    const horizontalTouchMoveListener = fromEvent<TouchEvent>(this.document, 'touchmove', { capture: true })
      .pipe(distinctUntilChanged())
      .subscribe((event) => {
        const changedTouch = event.changedTouches[0];
        this.onHorizontalMove(changedTouch, this.horizontalThumb.nativeElement);
      });

    const horizontalTouchEndListener = fromEvent<TouchEvent>(this.document, 'touchend', { capture: true })
      .pipe(distinctUntilChanged())
      .subscribe((_) => {
        this.onDragEnd();
        this.cdr.markForCheck();
        horizontalTouchMoveListener.unsubscribe();
        horizontalTouchEndListener.unsubscribe();
      });
  }

  public onVerticalScrollbarClick(mouseEvent: MouseEvent): void {
    mouseEvent.stopPropagation();

    const { target, clientY } = mouseEvent;
    const { top, height } = (target as HTMLElement).getBoundingClientRect();

    this.verticalThumbDragOffset = (clientY - top) / height;
    this.onVerticalMove(mouseEvent, this.verticalThumb.nativeElement);
  }

  public onScrollbarClick(mouseEvent: MouseEvent): void {
    mouseEvent.stopPropagation();

    const { target, clientX } = mouseEvent;
    const { left, width } = (target as HTMLElement).getBoundingClientRect();

    this.horizontalThumbDragOffset = (clientX - left) / width;
    this.onHorizontalMove(mouseEvent, this.horizontalThumb.nativeElement);
  }

  public onVerticalMove({ clientY }: MouseEvent | Touch, { offsetHeight }: HTMLElement): void {
    const { nativeElement } = this.content;
    const scrollBar = this.verticalBar.nativeElement.getBoundingClientRect();
    const { top, height } = scrollBar;
    const maxScrollTop = nativeElement.scrollHeight - height;
    const scrolled = (clientY - top - offsetHeight * this.verticalThumbDragOffset) / (height - offsetHeight);

    nativeElement.scrollTop = maxScrollTop * scrolled;
  }

  public onHorizontalMove({ clientX }: MouseEvent | Touch, { offsetWidth }: HTMLElement): void {
    const { nativeElement } = this.content;
    const scrollBar = this.horizontalBar.nativeElement.getBoundingClientRect();
    const { left, width } = scrollBar;
    const maxScrollLeft = nativeElement.scrollWidth - width;
    const scrolled = (clientX - left - offsetWidth * this.horizontalThumbDragOffset) / (width - offsetWidth);

    nativeElement.scrollLeft = maxScrollLeft * scrolled;
  }

  private listenToScroll(): void {
    this.zone.runOutsideAngular(() => {
      fromEvent(this.content.nativeElement, 'scroll')
        .pipe(untilDestroyed(this), withLatestFrom(this.hasHorizontalBar$, this.hasVerticalBar$))
        .subscribe(([_, hasHorizontalBar, hasVerticalBar]) => {
          this.calculateThumbPositions(hasHorizontalBar, hasVerticalBar);
        });
    });
  }

  private calculateHorizontalThumbPosition(): void {
    this.renderer.setStyle(this.horizontalThumb.nativeElement, 'width', `${this.horizontalSize}%`);
    this.renderer.setStyle(this.horizontalThumb.nativeElement, 'left', `${this.horizontalPosition}%`);
  }

  private calculateVerticalThumbPosition(): void {
    this.renderer.setStyle(this.verticalThumb.nativeElement, 'height', `${this.verticalSize}%`);
    this.renderer.setStyle(this.verticalThumb.nativeElement, 'top', `${this.verticalPosition}%`);
  }

  private fromElementResize$(element: HTMLElement): Observable<void> {
    return new Observable<void>((subscriber: Subscriber<void>) => {
      const observer = new ResizeObserver(() => {
        subscriber.next();
      });
      observer.observe(element);

      return () => {
        observer.disconnect();
      };
    });
  }

  private listenToResizeEvents(): void {
    this.fromElementResize$(this.content.nativeElement)
      .pipe(
        untilDestroyed(this),
        withLatestFrom(this.hasHorizontalBar$, this.hasVerticalBar$),
        map(([_, hasHorizontalBar, hasVerticalBar]) => {
          const shouldHaveHorizontalBar = this.horizontalSize < 100;
          const shouldHaveVerticalBar = this.verticalSize < 100;
          this.zone.run(() => {
            if (shouldHaveHorizontalBar && !hasHorizontalBar) {
              this.hasHorizontalBar$.next(true);
            } else if (this.horizontalSize === 100 && hasHorizontalBar) {
              this.hasHorizontalBar$.next(false);
            }

            if (shouldHaveVerticalBar && !hasVerticalBar) {
              this.hasVerticalBar$.next(true);
            } else if (this.verticalSize === 100 && hasVerticalBar) {
              this.hasVerticalBar$.next(false);
            }
          });
          return {
            hasVerticalBar: shouldHaveVerticalBar,
            hasHorizontalBar: shouldHaveHorizontalBar
          };
        })
      )
      .subscribe(({ hasHorizontalBar, hasVerticalBar }) => {
        this.zone.runOutsideAngular(() => {
          this.calculateThumbPositions(hasHorizontalBar, hasVerticalBar);
        });
      });
  }

  private calculateThumbPositions(hasHorizontalBar: boolean, hasVerticalBar: boolean): void {
    if (hasHorizontalBar) {
      this.calculateHorizontalThumbPosition();
    }
    if (hasVerticalBar) {
      this.calculateVerticalThumbPosition();
    }
  }
}
