import { Injectable } from '@angular/core';
import { LocalStorageService } from '@core/services/local-storage.service';
import { BehaviorSubject, filter } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { CookieConsentPreference } from '@shared/modules/cookie-policy/enums/cookie-consents.enum';
import { TagEvent } from '@shared/enums/tags/tag-event.enum';
import { TagPusherService } from '@core/services/tag-pusher.service';
import { Store } from '@ngrx/store';
import { getCookiesList } from '@shared/modules/cookie-policy/store/cookie-policy.actions';
import { selectCookiesList } from '@shared/modules/cookie-policy/store/cookie-policy.selectors';
import { isNotUndefined } from '@shared/utils/not-undefined';
import DEFAULT_COOKIES_DATA from './data/cookies.json';
import { CookieCategory, CookiePreferences } from './interfaces/cookie.interface';

@Injectable({
  providedIn: 'root'
})
export class CookiePolicyService {
  public consentDone$ = new BehaviorSubject<boolean>(false);
  public vm$ = this.store.select(selectCookiesList).pipe(filter(isNotUndefined));
  public defaultCookieCategories: CookieCategory[] = DEFAULT_COOKIES_DATA as CookieCategory[];
  private readonly CONSENT_KEY = 'dd-cookie-consent';
  private readonly ANALYTICS_COOKIE_KEY = 'dd-cookie-analytics';
  private readonly PREFERENTIAL_COOKIE_KEY = 'dd-cookie-preferential';
  private readonly NECESSARY_COOKIE_KEY = 'dd-cookie-necessary';
  private readonly COOKIE_CONSENT_EXPIRY = 365;

  constructor(
    private readonly localStorage: LocalStorageService,
    private readonly store: Store,
    private readonly cookieService: CookieService,
    private readonly tagPusherService: TagPusherService
  ) {
    if (!this.consented) {
      this.cookieService.set(this.CONSENT_KEY, 'no', this.COOKIE_CONSENT_EXPIRY, '/');
      this.setPreferences(this.createPreferencesObject(CookieConsentPreference.Deny));
    }
    this.consentDone$.next(this.consented);
  }

  public get consented(): boolean {
    const consent = this.cookieService.get(this.CONSENT_KEY);
    return consent === 'yes';
  }

  public getCookiesList(): void {
    this.store.dispatch(getCookiesList());
  }

  public acceptAll(): void {
    const preferences = this.createPreferencesObject(CookieConsentPreference.Allow);
    this.storePreferences(preferences);
  }

  public rejectAll(): void {
    this.cookieService.set(this.CONSENT_KEY, 'yes', this.COOKIE_CONSENT_EXPIRY, '/');
    const preferences = this.createPreferencesObject(CookieConsentPreference.Deny);
    this.storePreferences(preferences);
  }

  public updatePreferences(formValues: CookiePreferences): void {
    const preferences = this.createPreferencesObject();
    this.storePreferences({ ...preferences, ...formValues });
  }

  private setPreferences(preferences: CookiePreferences): void {
    this.cookieService.set(this.PREFERENTIAL_COOKIE_KEY, preferences['preferential'], this.COOKIE_CONSENT_EXPIRY, '/');
    this.cookieService.set(this.ANALYTICS_COOKIE_KEY, preferences['analytics'], this.COOKIE_CONSENT_EXPIRY, '/');
    this.cookieService.set(this.NECESSARY_COOKIE_KEY, CookieConsentPreference.Allow, this.COOKIE_CONSENT_EXPIRY, '/');
  }

  private storePreferences(preferences: CookiePreferences): void {
    this.cookieService.set(this.CONSENT_KEY, 'yes', this.COOKIE_CONSENT_EXPIRY, '/');
    this.setPreferences(preferences);
    this.tagPusherService.pushTag({
      event: TagEvent.ConsentUpdate
    });
    this.consentDone$.next(true);
  }

  private createPreferencesObject(accepted: CookieConsentPreference = CookieConsentPreference.Deny): CookiePreferences {
    const obj: CookiePreferences = {};
    this.defaultCookieCategories.forEach((category) => {
      obj[category.type] = category.required ? CookieConsentPreference.Allow : accepted;
    });
    return obj;
  }
}
