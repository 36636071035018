import { HttpResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';

import testkitManualDataEN from './mock-data/testkit-manual-en.json';
import testkitManualDataNL from './mock-data/testkit-manual-nl.json';

@Injectable({
  providedIn: 'root'
})
export class MockApiService {
  public getTestKitManual(language: string): HttpResponse<unknown> {
    const testkitManualData = language === 'nl' ? testkitManualDataNL : testkitManualDataEN;

    return new HttpResponse({
      status: HttpStatusCode.Ok,
      body: testkitManualData
    });
  }
}
