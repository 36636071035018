/* eslint-disable rxjs/finnish */
import { ESCAPE, hasModifierKey } from '@angular/cdk/keycodes';
import { NoopScrollStrategy, OverlayRef } from '@angular/cdk/overlay';
import { asapScheduler, merge, Observable, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { BottomSheetAndDialogContainerComponent } from './bottom-sheet-and-dialog-container';

/**
 * Reference to a bottom sheet dispatched from the bottom sheet service.
 */
export class BottomSheetAndDialogRef<T = unknown, R = unknown> {
  /** Instance of the component making up the content of the bottom sheet. */
  public instance!: T;

  /**
   * Instance of the component into which the bottom sheet content is projected.
   *
   * @docs-private
   */
  public containerInstance: BottomSheetAndDialogContainerComponent;

  /** Whether the user is allowed to close the bottom sheet. */
  public disableClose: boolean | undefined;

  /** Subject for notifying the user that the bottom sheet has been dismissed. */
  private readonly _afterDismissed$ = new Subject<R | undefined>();

  /** Subject for notifying the user that the bottom sheet has opened and appeared. */
  private readonly _afterOpened$ = new Subject<void>();

  /** Result to be passed down to the `afterDismissed` stream. */
  private _result: R | undefined;

  constructor(
    containerInstance: BottomSheetAndDialogContainerComponent,
    public readonly _overlayRef: OverlayRef
  ) {
    this.containerInstance = containerInstance;
    this.disableClose = containerInstance.bottomSheetConfig.disableClose;

    // Emit when opening animation completes
    containerInstance._animationStateChanged
      .pipe(
        filter((event) => event.phaseName === 'done' && event.toState === 'visible'),
        take(1)
      )
      .subscribe(() => {
        this._afterOpened$.next();
        this._afterOpened$.complete();
      });

    // Dispose overlay when closing animation is complete
    containerInstance._animationStateChanged
      .pipe(
        filter((event) => event.phaseName === 'done' && event.toState === 'hidden'),
        take(1)
      )
      .subscribe(() => {
        _overlayRef.dispose();
      });

    _overlayRef
      .detachments()
      .pipe(take(1))
      .subscribe(() => {
        this._afterDismissed$.next(this._result);
        this._afterDismissed$.complete();
      });

    merge(
      _overlayRef.backdropClick(),
      _overlayRef.outsidePointerEvents(),
      _overlayRef.keydownEvents().pipe(filter((event) => event.keyCode === ESCAPE))
    ).subscribe((event) => {
      if (!this.disableClose && (event.type !== 'keydown' || !hasModifierKey(event as KeyboardEvent))) {
        event.preventDefault();
        this.dismiss();
      }
    });
  }

  /**
   * Dismisses the bottom sheet.
   *
   * @param result Data to be passed back to the bottom sheet opener.
   */
  public dismiss(result?: R): void {
    if (!this._afterDismissed$.closed) {
      // We change the scrollstrategy before
      this._overlayRef.updateScrollStrategy(new NoopScrollStrategy());
      // Transition the backdrop in parallel to the bottom sheet.
      this.containerInstance._animationStateChanged
        .pipe(
          filter((event) => event.phaseName === 'start'),
          take(1)
        )
        .subscribe((event) => {
          // The logic that disposes of the overlay depends on the exit animation completing, however
          // it isn't guaranteed if the parent view is destroyed while it's running. Add a fallback
          // timeout which will clean everything up if the animation hasn't fired within the specified
          // amount of time plus 100ms. We don't need to run this outside the NgZone, because for the
          // vast majority of cases the timeout will have been cleared before it has fired.
          this._overlayRef.detachBackdrop();
          asapScheduler.schedule(() => this._overlayRef.dispose(), event.totalTime + 100);
        });

      this._result = result;
      this.containerInstance.exit();
    }
  }

  /** Gets an observable that is notified when the bottom sheet is finished closing. */
  public afterDismissed(): Observable<R | undefined> {
    return this._afterDismissed$;
  }

  /** Gets an observable that is notified when the bottom sheet has opened and appeared. */
  public afterOpened(): Observable<void> {
    return this._afterOpened$;
  }

  /**
   * Gets an observable that emits when the overlay's backdrop has been clicked.
   */
  public backdropClick(): Observable<MouseEvent> {
    return this._overlayRef.backdropClick();
  }

  /**
   * Gets an observable that emits when keydown events are targeted on the overlay.
   */
  public keydownEvents(): Observable<KeyboardEvent> {
    return this._overlayRef.keydownEvents();
  }
}
