@if (dynamicMenu) {
  <div *ngrxLet="breakpointService.upMd$ as upMd" class="d-flex flex-column flex-md-row p-0 p-md-4 h-100">
    @if (!upMd) {
      <a
        ddButton
        level="tertiary"
        (click)="this.closeMenu.emit()"
        [leftIcon]="selectedMenuItem() ? 'double-arrow-left' : 'arrow-left'"
        size="extra-small"
        class="color-text500 back-button body-large-bold"
      >
        {{ 'navigation.menu-title' | translate }}
      </a>
      @if (!selectedMenuItem()) {
        <hr class="mx-3 color-text200" />
      }
    }
    @if (upMd || (!upMd && !selectedMenuItem())) {
      <div class="d-flex flex-column pe-md-4 gap-1">
        @if (upMd) {
          <p class="mb-2.5 body-large-bold px-3">
            {{ dynamicMenu.title }}
          </p>
        }
        <div class="d-flex flex-column gap-md-1 gap-2 mb-md-0 mb-3 mt-md-0 mt-1">
          @if (dynamicMenu.type === 'DynamicMenuEntry') {
            @for (entry of dynamicMenu.menuItems; track entry.id) {
              @if (entry.type === DynamicMenuItemType.Multiple) {
                <a
                  ddButton
                  level="tertiary"
                  class="menu-item body-small-regular font-weight-regular p-0 order-1"
                  (mouseenter)="selectMenuItem(entry, !upMd)"
                  (click)="selectMenuItem(entry, upMd)"
                  [ngClass]="{
                    'font-weight-bold background-primary100': selectedMenuItem()?.id === entry.id
                  }"
                >
                  {{ entry.title }}
                  @if (!upMd) {
                    <div class="d-flex align-items-center gap-1 flex-grow-1 justify-content-end">
                      <svg-icon key="arrow-right" class="color-text500" size="md"></svg-icon>
                    </div>
                  }
                </a>
              } @else {
                <a
                  ddButton
                  level="tertiary"
                  class="body-small-regular font-weight-regular order-1"
                  (click)="closeMenuClick()"
                  [routerLink]="entry.link"
                  [queryParams]="entry.queryParams"
                >
                  {{ entry.title }}
                </a>
              }
            }
          }

          @if (dynamicMenu.seeMoreCta; as seeMoreCTA) {
            <a
              ddButton
              level="tertiary"
              class="body-small-regular font-weight-regular order-0 order-md-2"
              (click)="closeMenuClick()"
              [routerLink]="seeMoreCTA.route"
              [queryParams]="seeMoreCTA.queryParams"
              queryParamsHandling="preserve"
            >
              <div class="d-flex align-items-center gap-1 flex-grow-1 justify-content-between">
                {{ seeMoreCTA.title }}
                @if (!upMd) {
                  <svg-icon key="arrow-right" class="color-text500" size="md"></svg-icon>
                }
              </div>
            </a>
          }
        </div>
      </div>
    }
    @if (upMd) {
      <div class="divider flex-grow-1"></div>
    }
    <div class="d-flex flex-column ps-0 ps-md-4 h-100">
      @if (selectedMenuItem(); as selectedMenuItem) {
        <div class="d-flex flex-column gap-2 h-100">
          @if (!upMd) {
            <a
              ddButton
              level="tertiary"
              (click)="selectMenuItem()"
              [leftIcon]="'arrow-left'"
              size="small"
              class="color-text500 back-button font-weight-medium"
            >
              {{ dynamicMenu.title }}
            </a>
            <div class="horizontal-divider flex-grow-1"></div>
          }
          <div class="gap-md-1 gap-2 mb-md-0 mb-3 mt-md-0 mt-1 menu-item-container">
            @if (selectedMenuItem.seeMoreCta; as seeMoreCTA) {
              <a
                ddButton
                level="tertiary"
                class="body-small-regular font-weight-regular order-0 order-md-2"
                (click)="closeMenuClick()"
                [routerLink]="seeMoreCTA.route"
                [queryParams]="seeMoreCTA.queryParams"
                queryParamsHandling="merge"
              >
                <div class="d-flex align-items-center gap-1 flex-grow-1 justify-content-between">
                  {{ seeMoreCTA.title }}
                  @if (!upMd) {
                    <svg-icon key="arrow-right" class="color-text500" size="md"></svg-icon>
                  }
                </div>
              </a>
            }
            @if (selectedMenuItem.type === DynamicMenuItemType.Multiple) {
              @for (link of selectedMenuItem.links; track link.id) {
                <a
                  ddButton
                  level="tertiary"
                  class="body-small-regular font-weight-regular dynamic-menu-link"
                  [routerLink]="
                    link.type === LinkRecordType.RegularLinkRecord
                      ? link.route
                      : link.type === LinkRecordType.ProductRecord
                        ? [RouteSegment.AvailableTests, link.slug]
                        : null
                  "
                  [queryParams]="link.type === LinkRecordType.RegularLinkRecord ? link.queryParams : undefined"
                  queryParamsHandling="merge"
                  (click)="closeMenuClick()"
                  [ngClass]="{
                    'color-text500': !link.style
                  }"
                >
                  <div class="d-flex align-items-center gap-1 flex-grow-1 justify-content-between">
                    {{ link.title }}
                    @if ((link.type === LinkRecordType.RegularLinkRecord || link.type === LinkRecordType.ProductRecord) && link.arrow) {
                      <svg-icon key="arrow-right" class="color-text500" size="md"></svg-icon>
                    }
                  </div>
                </a>
              }
            }
          </div>
          @if (!!selectedMenuItem.articles?.length) {
            <div class="article-section-container mb-0">
              <app-scroller contentClass="d-flex gap-2 p-1-5">
                @for (article of selectedMenuItem.articles; track article.id) {
                  <article
                    class="menu-article flex-shrink-0 position-relative overflow-hidden rounded-3 background-bg100 p-3 gap-2 d-flex flex-column justify-content-between"
                    (click)="closeMenuClick()"
                    [routerLink]="[RouteSegment.Root, RouteSegment.KnowledgeBase, article.slug]"
                  >
                    <p class="body-normal-bold mb-0">{{ article.title }}</p>
                    <div class="article-info d-flex body-tiny color-text300">
                      <span>{{ 'navigation.article-read-time' | translate: { value: article.readingTime } }}</span>
                      <span>{{ article.author[0].firstName && article.author[0].lastName }}</span>
                    </div>
                  </article>
                }
              </app-scroller>
            </div>
          }
        </div>
      }
    </div>
  </div>
}
