<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 440 288" width="100%" height="100%">
  <path
    d="M108.6 202.027c-22.557-10.771-22.146-35.432-19.12-46.416 18.038-73.07 130.297-116.244 226.665-49.472 43.193 29.929 51.557 86.513 13.98 123.486-37.577 36.974-75.445-47.203-119.329-49.357-18.74-.92-73.999 35.224-102.196 21.759ZM340 64c0 15.464 12.536 28 28 28h36c8.837 0 16-7.163 16-16s-7.163-16-16-16a15.93 15.93 0 0 0-8.059 2.175C395.001 47.56 382.851 36 368 36c-15.464 0-28 12.536-28 28ZM69 133a9 9 0 0 0 9-9 9 9 0 0 0-11.048-8.766C66.306 106.713 59.187 100 50.5 100c-6.813 0-12.662 4.13-15.18 10.023A12.435 12.435 0 0 0 28.5 108c-6.904 0-12.5 5.596-12.5 12.5S21.596 133 28.5 133H69Z"
    fill="#FFF6ED"
  />
  <path
    d="M286.173 262.698 300 260.004l-21.817-149.438H158.864c-2.523 0-3.919 2.927-2.331 4.888l2.536 3.133-18.729 7.268-19.854 144.831a4 4 0 0 0 3.963 4.543h157.23l4.494-12.531Z"
    fill="#fff"
  />
  <path
    d="m149.08 130.617-19.634 149.868a3.999 3.999 0 0 0 3.966 4.519h155.759M149.08 130.617h118.365m-118.365 0 20.976-7.769-2.85-3.367a1 1 0 0 1 .763-1.646h119.204m-19.728 12.782 21.726 154.387m-21.726-154.387-4.744-5.263 24.472-7.519m1.998 167.169 3.246-14.536 16.584-4.964-21.828-147.669"
    stroke="#42649B"
    stroke-width="2"
  />
  <path d="M221.332 197.059c-3.537-5.479-16.681-7.842-24.332 0" stroke="#42649B" stroke-width="2" />
  <ellipse rx="4.827" ry="4.843" transform="rotate(-12.299 913.165 -773.555) skewX(-.09)" fill="#42649B" />
  <ellipse rx="4.827" ry="4.843" transform="rotate(-12.299 936.03 -985.796) skewX(-.09)" fill="#42649B" />
  <path
    d="M188.285 138.136V89.514c-.25-6.266 3.446-18.797 20.227-18.797s20.809 12.531 20.726 18.797v48.622"
    stroke="#42649B"
    stroke-width="2"
    stroke-linecap="square"
  />
  <path
    d="M197.286 117.834v-37.83m40.941 37.83V78.431c.083-5.078-3.945-15.234-20.72-15.234-6.071 0-10.429 1.33-13.507 3.269"
    stroke="#42649B"
    stroke-width="2"
    stroke-linecap="square"
  />
  <rect x="290.395" y="66.308" width="1.698" height="29.721" rx=".849" transform="rotate(45 290.395 66.308)" fill="#FACB91" />
  <rect x="129" y="91.004" width="2" height="20" rx="1" fill="#98EAAE" />
  <rect x="292.142" y="87.02" width="1.698" height="29.721" rx=".849" transform="rotate(135 292.142 87.02)" fill="#FACB91" />
  <rect x="140" y="100.004" width="2" height="20" rx="1" transform="rotate(90 140 100.004)" fill="#98EAAE" />
  <circle cx="303.5" cy="105.504" r="4.5" fill="#B5CBEE" />
  <circle cx="158" cy="80.004" r="10" stroke="#B5CBEE" stroke-width="2" />
  <circle cx="122" cy="61.004" r="7" stroke="#F67971" stroke-width="2" />
  <path
    d="M234.883 192.206c1.864-3.645.932-6.531.466-8.202 7.922 5.468 8.854 16.86 2.33 15.948-4.66-.651-4.011-5.37-2.796-7.746Z"
    fill="#B5CBEE"
  />
</svg>
