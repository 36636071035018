import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from '@shop/modules/footer/footer.component';
import { DatoFooterDataService } from '@core/services/dato/data-services/footer-data.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FooterEffects } from '@shop/modules/footer/store/footer.effects';
import { featureKeyFooter, reducerFooter } from '@shop/modules/footer/store/footer.reducer';
import { SharedModule } from '@shared/shared.module';
import { IntersectionObserverModule } from '@ng-web-apis/intersection-observer';
import { LazyLoadBackgroundModule } from '@shared/directives/lazy-load-background/lazy-load-background.module';
import { RouterModule } from '@angular/router';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MethodInvokerPipeModule } from '@shared/pipes/method-invoker/invoker.module';
import { FooterGroupComponent } from './components/footer-group/footer-group.component';

@NgModule({
  declarations: [FooterComponent, FooterGroupComponent],
  exports: [FooterComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    IntersectionObserverModule,
    LazyLoadBackgroundModule,
    MethodInvokerPipeModule,
    StoreModule.forFeature(featureKeyFooter, reducerFooter),
    EffectsModule.forFeature([FooterEffects]),
    CdkAccordionModule
  ],
  providers: [DatoFooterDataService]
})
export class FooterModule {}
