import { Directive, ElementRef, Inject, Input, Renderer2 } from '@angular/core';
import { INTERSECTION_ROOT_MARGIN, INTERSECTION_THRESHOLD, IntersectionObserverService } from '@ng-web-apis/intersection-observer';
import { filter, first } from 'rxjs';

@Directive({
  selector: '[appLazyLoadBackground]',
  providers: [
    IntersectionObserverService,
    {
      provide: INTERSECTION_THRESHOLD,
      useValue: 0
    },
    {
      provide: INTERSECTION_ROOT_MARGIN,
      useValue: '200px'
    }
  ]
})
export class LazyLoadBackgroundDirective {
  @Input() public image!: string;

  constructor(
    @Inject(IntersectionObserverService) entries$: IntersectionObserverService,
    private readonly renderer: Renderer2,
    private readonly element: ElementRef<HTMLElement>
  ) {
    entries$
      .pipe(
        filter((intersections) => {
          const intersectingAt = intersections.at(-1);
          return intersectingAt ? intersectingAt?.isIntersecting : false;
        }),
        first()
      )
      .subscribe(() => {
        this.onIntersection(element.nativeElement, this.image);
      });
  }

  public onIntersection(element: HTMLElement, image: string): void {
    if (!element.style.backgroundImage) {
      this.renderer.setStyle(element, 'background-image', `url(${image})`);
    }
  }
}
