import { createAction, props } from '@ngrx/store';
import { CampaignVM } from '@shop/pages/campaign/campaign.vm';
import { DatoApiError } from '../../../../../domain/dato-api-error';

const feature = '[Campaign Page]';

export const getCampaignPage = createAction(`${feature} get campaign page`, props<{ slug: string }>());

export const getCampaignPageSuccess = createAction(
  `${feature} get campaign page success`,
  props<{
    campaignPage: CampaignVM;
  }>()
);

export const getCampaignPageError = createAction(
  `${feature} get campaign page error`,
  props<{
    error: DatoApiError;
  }>()
);
