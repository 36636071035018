import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

const animationTiming = '0.3s ease-out';

export const valueAnimation = trigger('valueAnimation', [
  transition(':increment', [style({ fontSize: '20px' }), animate(animationTiming, style('*'))]),
  transition(':decrement', [style({ fontSize: '20px' }), animate(animationTiming, style('*'))])
]);

export const fabRevealAnimation = trigger('fabRevealAnimation', [
  transition('* => true', [
    animate(
      animationTiming,
      keyframes([style({ transform: 'scale(0)' }), style({ transform: 'scale(1.3)' }), style({ transform: 'scale(1)' })])
    )
  ]),
  transition('true => false', [style({ transform: 'scale(1)' }), animate(animationTiming, style({ transform: 'scale(0)' }))])
]);

export const deleteAnimation = trigger('deleteAnimation', [
  state('true', style({ transform: 'scale(0)' })),
  transition('* => true', [style({ transform: 'scale(1)' }), animate(animationTiming, style({ transform: 'scale(0)' }))])
]);
