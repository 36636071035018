import { ChangeDetectionStrategy, Component, Optional, TrackByFunction } from '@angular/core';
import { BreakpointService } from '@core/services/breakpoint.service';
import { BottomSheetAndDialogRef } from '@shared/components/bottom-sheet/bottom-sheet-and-dialog-ref';
import { DropdownRef } from '@shared/components/dropdown/dropdown-ref';
import { RouteSegment } from '@shared/enums/route-segment.enum';
import { CartItem } from 'src/domain/cart/cart-item';
import { UpdateCartItemRequest } from 'src/domain/cart/update-cart-item-request';
import { deleteAnimation } from '@shop/modules/cart/animations/cart-animations';
import { BehaviorSubject } from 'rxjs';
import { TagPusherService } from '@core/services/tag-pusher.service';
import { TagEvent } from '@shared/enums/tags/tag-event.enum';
import { CartVM } from '@shop/modules/cart/cart.vm';
import { CartService } from '../../cart.service';
import { mapCartItemToEcommerceItem } from '../../../../../../domain/mappers/map-cart';

export interface AnimationState {
  id: string;
  isAnimating: boolean;
}

@Component({
  selector: 'app-cart-dropdown',
  templateUrl: './cart-dropdown.component.html',
  styleUrls: ['./cart-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [deleteAnimation]
})
export class CartDropdownComponent {
  public vm$ = this.cartService.cart$;
  public isLoading$ = this.cartService.isLoading$;
  public animations$ = new BehaviorSubject<AnimationState[]>([]);
  public readonly RouteSegment = RouteSegment;

  constructor(
    private readonly cartService: CartService,
    @Optional() private readonly dropdownRef: DropdownRef,
    @Optional() private readonly bottomSheetRef: BottomSheetAndDialogRef,
    public readonly breakpointService: BreakpointService,
    private readonly tagPusherService: TagPusherService
  ) {}

  public trackById: TrackByFunction<CartItem> = (_, item) => item.id;

  public updateQuantity(updateCartItemRequest: UpdateCartItemRequest): void {
    this.cartService.updateItemQuantity(updateCartItemRequest);
  }

  public onDeleteAnimationDone(productId: string): void {
    this.animations$.next(this.animations$.getValue().filter((animation) => animation.id !== productId));
  }

  public toBeAnimated(productId: string): boolean {
    return this.animations$.getValue().some((animation) => animation.id === productId);
  }

  public onDeleteAnimationStart(productId: string): void {
    this.animations$.next([...this.animations$.getValue(), { id: productId, isAnimating: true }]);
  }

  public pushBeginCheckoutEvent(cart: CartVM): void {
    this.tagPusherService.pushTag({
      event: TagEvent.BeginCheckout,
      ecommerce: {
        currency: 'EUR',
        shipping_tier: 'free',
        value: cart.total,
        items: cart.cartItems.map((cartItem) => mapCartItemToEcommerceItem(cartItem))
      }
    });
  }

  public closeDropdown(): void {
    if (this.dropdownRef) {
      this.dropdownRef?.dismiss();
    } else {
      this.bottomSheetRef?.dismiss();
    }
  }
}
