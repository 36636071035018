/* eslint-disable @angular-eslint/no-host-metadata-property */
import { coerceArray } from '@angular/cdk/coercion';
import { BasePortalOutlet, CdkPortalOutlet, ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { DropdownConfig } from '../dropdown-trigger-config';

@Component({
  selector: 'app-dropdown-container',
  templateUrl: './dropdown-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'app-dropdown-container'
  }
})
export class DropdownContainerComponent extends BasePortalOutlet implements OnInit {
  @ViewChild(CdkPortalOutlet, { static: true }) public portalOutlet!: CdkPortalOutlet;
  public onOpened: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    public readonly dropdownConfig: DropdownConfig
  ) {
    super();
  }

  public ngOnInit(): void {
    this.applyPanelClass();
    this.onOpened.emit();
  }

  public applyPanelClass(): void {
    const element: HTMLElement = this.elementRef.nativeElement;
    element.classList.add(...coerceArray(this.dropdownConfig.panelClass || []));
  }

  public attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
    this.validatePortalAttached();
    return this.portalOutlet.attachTemplatePortal(portal);
  }

  public attachComponentPortal<C>(portal: ComponentPortal<C>): ComponentRef<C> {
    this.validatePortalAttached();
    return this.portalOutlet.attachComponentPortal(portal);
  }

  private validatePortalAttached() {
    if (this.portalOutlet.hasAttached()) {
      throw new Error('Attempting to attach dropdown content after content is already attached');
    }
  }
}
