import { DatoMediaBlock as DatoMediaBlock } from '@core/services/dato/interfaces/media-block.interface';
import { MediaBlock } from '../media/media-block';
import { mapVideoSubtitleToTracks, mapVideoUrlToSource } from './map-video';

export const mapMediaBlock = (mediaBlock: DatoMediaBlock): MediaBlock => {
  const mediaBlockVideo = mediaBlock.video;
  return {
    ...mediaBlock,
    cta: mediaBlock.cta ? mediaBlock.cta[0] : undefined,
    video: mediaBlockVideo?.videoFile
      ? {
          ...mediaBlockVideo,
          videoThumbnail: mediaBlockVideo.videoFile.videoThumbnail,
          videoFile: {
            sources: mapVideoUrlToSource([mediaBlockVideo.videoFile]),
            videoSubtitles: mediaBlockVideo.videoSubtitles ? mapVideoSubtitleToTracks(mediaBlockVideo.videoSubtitles) : []
          }
        }
      : undefined
  };
};
