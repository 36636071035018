import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { ToastMessageService } from './toast-message.service';

@Directive({
  selector: '[appToastMessageAnchor]'
})
export class ToastMessageAnchorDirective implements AfterViewInit, OnDestroy {
  constructor(
    private readonly elementRef: ElementRef,
    private readonly toastMessageService: ToastMessageService
  ) {}

  public ngAfterViewInit(): void {
    this.toastMessageService.registerAnchorElement(this.elementRef);
  }

  public ngOnDestroy(): void {
    this.toastMessageService.close();
  }
}
