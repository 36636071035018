import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

/**
 * A directive that enables reactive forms to have controls that can be dynamically enabled/disabled.
 *
 * @param disabledControl a boolean that sets the formControl either to enabled or disabled
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '([formControlName], [formControl])[disabledControl]'
})
export class DisabledControlDirective {
  constructor(private readonly ngControl: NgControl) {}

  @Input() set disabledControl(state: boolean) {
    const action = state ? 'disable' : 'enable';
    if (this.ngControl.control) {
      this.ngControl.control[action]();
    }
  }
}
