/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, ElementRef, OnInit } from '@angular/core';
import { coerceArray } from '@angular/cdk/coercion';

@Component({
  selector: 'app-skeleton-loader',
  host: {
    class: 'shimmer rounded-2'
  },
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['skeleton-loader.component.scss']
})
export class SkeletonLoaderComponent implements OnInit {
  public width!: string;
  public height!: string;
  public className!: string;

  constructor(private readonly host: ElementRef<HTMLElement>) {}

  public ngOnInit(): void {
    const host = this.host.nativeElement;

    if (this.className) {
      host.classList.add(...coerceArray(this.className || []));
    }

    host.style.setProperty('--skeleton-rect-width', this.width ?? '100%');
    host.style.setProperty('--skeleton-rect-height', this.height ?? '20px');
  }
}
