import { Injectable } from '@angular/core';
import { isNotUndefined } from '@shared/utils/not-undefined';
import { getHeroData, getHomepageData } from '@shop/pages/home/store/home.actions';
import { selectHeroVM, selectHomepageVM } from '@shop/pages/home/store/home.selectors';
import { filter, Observable } from 'rxjs';
import { HomeVM } from '@shop/pages/home/home.vm';
import { HeroVM } from '@shop/pages/home/components/hero/hero.vm';
import { TranslatableContentService } from '@shared/services/translatable-content.service';

@Injectable()
export class HomeService extends TranslatableContentService {
  public vm$: Observable<HomeVM> = this.store.select(selectHomepageVM).pipe(filter(isNotUndefined));
  public heroVM$: Observable<HeroVM> = this.store.select(selectHeroVM).pipe(filter(isNotUndefined));

  public getHeroData(): void {
    this.store.dispatch(getHeroData());
  }

  public getHomepageData(): void {
    this.store.dispatch(getHomepageData());
  }

  public action(): void {
    this.getHeroData();
    this.getHomepageData();
  }
}
