import { UrlMatchResult, UrlSegment } from '@angular/router';
import { SUPPORTED_LANGUAGES } from '../language.service';

/**
 * A matcher that checks if the URL has a language prefix. If it does, we return it as a languageId parameter. If we go to the root,
 * we consume the whole URL. If it is a whole URL without a language prefix, the matcher will pass it forward without consuming any part of
 * the url.
 *
 * @param url the UrlSegment to be consumed by the matcher
 */
export const languagePrefixMatcher = (url: UrlSegment[]): UrlMatchResult => {
  {
    if (!url[0]) {
      return {
        consumed: url
      };
    }

    if (SUPPORTED_LANGUAGES.includes(url[0].path)) {
      return {
        consumed: url.slice(0, 1),
        posParams: {
          languageId: url[0]
        }
      };
    }

    return {
      consumed: url
    };
  }
};
