import { createReducer, on } from '@ngrx/store';
import { LoadableStateReducerHelper } from '@shared/helpers/loadable-state-reducer.helper';
import { Loadable } from '@shared/interfaces/loadable.interface';
import { ApiError } from 'src/domain/api-error';
import { HomeVM } from '@shop/pages/home/home.vm';
import { HeroVM } from '@shop/pages/home/components/hero/hero.vm';
import { DatoApiError } from '../../../../../domain/dato-api-error';
import {
  getHeroData,
  getHeroDataError,
  getHeroDataSuccess,
  getHomepageData,
  getHomepageDataError,
  getHomepageDataSuccess
} from './home.actions';

export const featureKeyHome = 'home';

export interface FeatureStateHome {
  hero: Loadable<HeroVM, DatoApiError>;
  homepage: Loadable<HomeVM, ApiError>;
}

export interface AppState {
  [featureKeyHome]: FeatureStateHome;
}

export const featureStateHome: FeatureStateHome = {
  hero: { isLoading: false },
  homepage: { isLoading: false }
};

export const reducerHome = createReducer(
  featureStateHome,
  on(
    getHomepageData,
    (state): FeatureStateHome => ({
      ...state,
      homepage: LoadableStateReducerHelper.startLoad(state.homepage)
    })
  ),
  on(
    getHomepageDataSuccess,
    (state, { homepage }): FeatureStateHome => ({
      ...state,
      homepage: LoadableStateReducerHelper.loadSuccess(homepage)
    })
  ),
  on(
    getHomepageDataError,
    (state, { error }): FeatureStateHome => ({
      ...state,
      homepage: LoadableStateReducerHelper.loadError(state.homepage, error)
    })
  ),
  on(
    getHeroData,
    (state): FeatureStateHome => ({
      ...state,
      hero: LoadableStateReducerHelper.startLoad(state.hero)
    })
  ),
  on(
    getHeroDataSuccess,
    (state, { hero }): FeatureStateHome => ({
      ...state,
      hero: LoadableStateReducerHelper.loadSuccess(hero)
    })
  ),
  on(
    getHeroDataError,
    (state, { error }): FeatureStateHome => ({
      ...state,
      hero: LoadableStateReducerHelper.loadError(state.hero, error)
    })
  )
);
