import { Inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { DOCUMENT, Location } from '@angular/common';
import { Router } from '@angular/router';
import { BASE_URL } from '@core/tokens/base-url.token';
import { LanguageService } from '@core/modules/language/language.service';
import { MetaResponse } from '../../../domain/meta/meta-response.interface';
import { LinkDefinition } from '../interfaces/link-definition.interface';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(BASE_URL) private readonly baseUrl: string,
    private readonly location: Location,
    private readonly languageService: LanguageService,
    private readonly router: Router,
    private readonly meta: Meta,
    private readonly title: Title
  ) {}

  public setHrefLangTags(): void {
    this.document.querySelectorAll('link[rel="alternate"][hreflang], link[rel="canonical"]').forEach((linkElement) => {
      if (linkElement.parentNode) {
        linkElement.remove();
      }
    });

    const currentLang = this.languageService.currentLanguage$.value;
    const path = this.location.path(true);
    const fragment = path.split('#')[1];
    const locationWithoutQuery = path.split('?')[0];

    this.addTags([
      {
        rel: 'canonical',
        href:
          this.baseUrl +
          this.router.serializeUrl(
            this.router.createUrlTree([this.languageService.replaceLanguageInUrl(locationWithoutQuery, currentLang)], { fragment })
          )
      },
      {
        rel: 'alternate',
        hreflang: 'en',
        href:
          this.baseUrl +
          this.router.serializeUrl(
            this.router.createUrlTree([this.languageService.replaceLanguageInUrl(locationWithoutQuery, 'en')], { fragment })
          )
      },
      {
        rel: 'alternate',
        hreflang: 'nl',
        href:
          this.baseUrl +
          this.router.serializeUrl(
            this.router.createUrlTree([this.languageService.replaceLanguageInUrl(locationWithoutQuery, 'nl')], { fragment })
          )
      },
      {
        rel: 'alternate',
        hreflang: 'x-default',
        href:
          this.baseUrl +
          this.router.serializeUrl(
            this.router.createUrlTree([this.languageService.replaceLanguageInUrl(locationWithoutQuery, 'nl')], { fragment })
          )
      }
    ]);
  }

  public addTags(tags: LinkDefinition[]): HTMLLinkElement[] {
    return tags.map((tag) => {
      const link = this.document.createElement('link');
      link.rel = tag.rel;
      link.href = tag.href;
      if (tag.hreflang) {
        link.hreflang = tag.hreflang;
      }

      this.document.head.append(link);
      return link;
    });
  }

  public setMeta(metaResponse: MetaResponse[]): void {
    if (metaResponse || Array.isArray(metaResponse)) {
      const metaTags: MetaDefinition[] = metaResponse
        .filter((metaTag) => metaTag.attributes)
        .map((metaTag) => metaTag.attributes) as MetaDefinition[];

      const metaTagKeys = [
        'keywords',
        'description',
        'subject',
        'copyrigh',
        'language',
        'name="robots"',
        'revised',
        'abstract',
        'topic',
        'summary',
        'Classification',
        'author',
        'designer',
        'copyright',
        'reply-to',
        'owner',
        'url',
        'identifier-URL',
        'directory',
        'category',
        'coverage',
        'distribution',
        'rating',
        'revisit-after',
        'og:title',
        'og:type',
        'og:url',
        'og:image',
        'og:site_name',
        'og:description',
        'twitter:card',
        'twitter:site',
        'twitter:crator'
      ];

      metaTagKeys.forEach((metaKey) => {
        try {
          this.meta.removeTag(metaKey);
        } catch {
          // @note This type of tags can't be found in the DOM
        }
      });

      metaTags.forEach((metaTag) => {
        if (metaTag.property === 'og:title' && metaTag.content) {
          this.title.setTitle(metaTag.content);
        }
        this.meta.updateTag(metaTag);
      });

      this.setHrefLangTags();
    }
  }
}
