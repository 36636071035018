import { Direction } from '@angular/cdk/bidi';
import { ScrollStrategy } from '@angular/cdk/overlay';
import { InjectionToken, ViewContainerRef } from '@angular/core';

export type AutoFocusTarget = 'dialog' | 'first-tabbable' | 'first-heading';

export const BOTTOM_SHEET_AND_DIALOG_DATA = new InjectionToken<unknown>('bottom-sheet-and-dialog-data');

export class BottomSheetAndDialogConfig<D = unknown> {
  public viewContainerRef?: ViewContainerRef;
  public panelClass?: string | string[];
  public direction?: Direction;
  public data?: D | null = null;
  public data$?: D | null = null;
  public hasBackdrop?: boolean = true;
  public backdropClass?: string;
  public disableClose?: boolean = false;
  public ariaLabel?: string | null = null;

  public closeOnNavigation?: boolean = true;

  public autoFocus?: AutoFocusTarget | string | boolean = 'dialog';

  public restoreFocus?: boolean = true;

  public scrollStrategy?: ScrollStrategy;

  public overlayPanelClass?: string | string[];

  public alwaysBottomSheet?: boolean;
  public alwaysDialog?: boolean;
  public dialogModeWidth?: string;
  public noPaddingWhileDialogMode?: boolean;
  public noPaddingWhileInBottomSheetMode?: boolean;
}
