import { DatoRegularLink } from '@core/services/dato/interfaces/regular-link.interface';
import { DatoArticle } from '@core/services/dato/interfaces/article.interface';
import { DatoFooterProductLink } from '@core/services/dato/interfaces/footer-product-link.interface';

export enum DynamicMenuItemType {
  Single = 'DynamicMenuEntrySingle',
  Multiple = 'DynamicMenuEntryMultiple'
}

export type DynamicMenuItem = DynamicMenuItemSingle | DynamicMenuItemMultiple;

export interface DynamicMenuEntry {
  id: string;
  type: 'DynamicMenuEntry';
  title: string;
  style?: 'primary' | 'secondary';
  seeMoreCta?: DatoRegularLink;
  menuItems: DynamicMenuItem[];
}

export interface DynamicMenuEntryBase {
  id: string;
  title: string;
  type: DynamicMenuItemType;
  seeMoreCta?: DatoRegularLink;
  articles?: DatoArticle[];
  arrow?: boolean;
}

export interface DynamicMenuItemSingle extends DynamicMenuEntryBase {
  type: DynamicMenuItemType.Single;
  queryParams?: { [key: string]: string };
  link: string;
}

export interface DynamicMenuItemMultiple extends DynamicMenuEntryBase {
  type: DynamicMenuItemType.Multiple;
  links: (DatoRegularLink | DynamicMenuEntry | DatoFooterProductLink)[];
}
