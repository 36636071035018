import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbService } from '@shared/modules/breadcrumb/breadcrumb.service';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { appArrowRightIcon } from '@icons/arrow-right';
import { provideSvgIcons } from '@ngneat/svg-icon';
import { BreadcrumbComponent } from './breadcrumb.component';

@NgModule({
  declarations: [BreadcrumbComponent],
  imports: [CommonModule, RouterModule, SharedModule],
  providers: [provideSvgIcons([appArrowRightIcon])],
  exports: [BreadcrumbComponent]
})
export class BreadcrumbModule {
  public static forRoot(): ModuleWithProviders<BreadcrumbModule> {
    return {
      ngModule: BreadcrumbModule,
      providers: [BreadcrumbService]
    };
  }
}
