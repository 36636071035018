import { ReviewProviderParameters } from '@shared/services/review-provider/review-provider.type';

export class ReviewProviderConfiguration {
  public providerUrl: string;
  public providerToken: string;
  public locationId: string;
  public tenantId: string;
  constructor(configurationParameters: ReviewProviderParameters) {
    this.providerUrl = configurationParameters.providerUrl;
    this.providerToken = configurationParameters.providerToken;
    this.locationId = configurationParameters.locationId;
    this.tenantId = configurationParameters.tenantId;
  }
}
