import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export abstract class TranslatableContentService {
  constructor(
    protected readonly translateService: TranslateService,
    public readonly store: Store,
    public readonly location: Location
  ) {
    this.translateService.onLangChange.pipe(untilDestroyed(this)).subscribe(({ lang }) => {
      return this.action(lang);
    });
  }

  protected abstract action(language: string): void;
}
