import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { CookieListDataService } from '@core/services/dato/data-services/cookie-list-data.service';
import { getCookiesList, getCookiesListError, getCookiesListSuccess } from '@shared/modules/cookie-policy/store/cookie-policy.actions';
import { TranslateService } from '@ngx-translate/core';
import { ApiError } from '@datocms/cma-client-node';

@Injectable()
export class CookiePolicyEffects {
  // eslint-disable-next-line unicorn/consistent-function-scoping
  public getCookiesList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCookiesList),
      switchMap(() => {
        return this.datoCookieListDataService.getCookiesList$(this.translateService.currentLang).pipe(
          map((cookiesList) => {
            return getCookiesListSuccess({ cookiesList });
          })
        );
      }),
      catchError((error: ApiError) => {
        return of(getCookiesListError({ error }));
      })
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly datoCookieListDataService: CookieListDataService,
    private readonly translateService: TranslateService
  ) {}
}
