import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneObfuscate'
})
export class PhoneObfuscatePipe implements PipeTransform {
  public transform(phoneNumber: string): string {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    return `${phoneNumber.slice(0, 6)}${phoneNumber.slice(6).replaceAll(/.(?=.)/g, '*')}`;
  }
}
