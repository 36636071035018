import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { filter, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { isNotUndefined } from '@shared/utils/not-undefined';
import { map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { selectCampaignPage } from '@shop/pages/campaign/store/campaign.selectors';
import { getCampaignPage } from '@shop/pages/campaign/store/campaign.actions';

@Injectable()
@UntilDestroy()
export class BreadcrumbGenericPageNameResolver implements Resolve<string> {
  constructor(
    private readonly store: Store,
    private readonly translateService: TranslateService
  ) {
    this.translateService.onLangChange.pipe(untilDestroyed(this)).subscribe(() => {
      return this.selectGenericPage$();
    });
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<string> {
    const slug = route.paramMap.get('slug');
    this.store.dispatch(getCampaignPage({ slug: slug ?? '' }));

    return this.selectGenericPage$();
  }

  private selectGenericPage$(): Observable<string> {
    return this.store.select(selectCampaignPage).pipe(
      filter(isNotUndefined),
      // eslint-disable-next-line @ngrx/avoid-mapping-selectors
      map((campaign) => {
        return campaign.title;
      })
    );
  }
}
