import { createAction, props } from '@ngrx/store';
import { ApiError } from '@datocms/cma-client-node';
import { DatoCookiesList } from '@core/services/dato/interfaces/cookies-list.interface';

const feature = '[CookiePolicy]';

export const getCookiesList = createAction(`${feature} get cookies`);

export const getCookiesListSuccess = createAction(`${feature} get cookies success`, props<{ cookiesList: DatoCookiesList }>());

export const getCookiesListError = createAction(`${feature} get cookies error`, props<{ error: ApiError }>());
