import { ArticleSection, DatoArticle } from '@core/services/dato/interfaces/article.interface';
import { KnowledgeBaseSection } from '../knowledge-base/section';
import { KnowledgeBaseArticle } from '../knowledge-base/article';

export const mapArticleSectionToKnowledgeBaseArticleSection = (articleSections: ArticleSection[]): KnowledgeBaseSection[] => {
  return articleSections.map((articleSection) => {
    return {
      id: articleSection.id,
      articles: mapArticlesToKnowledgeBaseArticles(articleSection.articles),
      title: articleSection.title,
      articleSectionId: articleSection.articleSectionId,
      writtenBy: articleSection.subtitle
    };
  });
};
export const mapArticleToKnowledgeBaseArticle = (article: DatoArticle): KnowledgeBaseArticle => {
  const { author, id, title, labels, createdAt, articleContent, articleImage, slug, articleThumbnailImage, readingTime, excerpt } = article;
  return {
    id,
    title,
    labels,
    date: new Date(createdAt).toISOString(),
    coverImage: articleImage,
    thumbnailImage: articleThumbnailImage,
    body: articleContent,
    slug,
    excerpt,
    readingTime,
    author: {
      name: `${author[0].firstName} ${author[0].lastName}`,
      avatar: author[0].authorImage.url
    }
  };
};
export const mapArticlesToKnowledgeBaseArticles = (articles: DatoArticle[]): KnowledgeBaseArticle[] => {
  return articles.map((article) => {
    return mapArticleToKnowledgeBaseArticle(article);
  });
};
