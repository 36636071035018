import { createFeatureSelector, createSelector } from '@ngrx/store';
import { KnowledgeBaseArticlePageVM } from '../article.vm';
import { mapArticleToKnowledgeBaseArticle } from '../../../../../../../domain/mappers/map-article';
import { featureKeyKnowledgeBaseArticle, FeatureStateKnowledgeBaseArticle } from './article.reducer';

const selectKnowledgeBaseArticleFeature = createFeatureSelector<FeatureStateKnowledgeBaseArticle>(featureKeyKnowledgeBaseArticle);

export const selectKnowledgeBaseArticleIsLoading = createSelector(
  selectKnowledgeBaseArticleFeature,
  (state: FeatureStateKnowledgeBaseArticle) => state.article.isLoading || state.relatedProduct.isLoading
);

export const selectKnowledgeBaseArticle = createSelector(
  selectKnowledgeBaseArticleFeature,
  (state: FeatureStateKnowledgeBaseArticle) => state.article.data
);

export const selectKnowledgeBaseArticleRelatedProduct = createSelector(
  selectKnowledgeBaseArticleFeature,
  (state: FeatureStateKnowledgeBaseArticle) => state.relatedProduct.data
);

export const selectKnowledgeBaseArticlePage = createSelector(
  selectKnowledgeBaseArticle,
  selectKnowledgeBaseArticleRelatedProduct,
  selectKnowledgeBaseArticleIsLoading,
  (knowledgeBaseArticle, articleRelatedProduct, isLoading): KnowledgeBaseArticlePageVM | undefined => {
    if (knowledgeBaseArticle) {
      return {
        article: mapArticleToKnowledgeBaseArticle(knowledgeBaseArticle),
        relatedProduct: articleRelatedProduct
          ? {
              product: articleRelatedProduct,
              relatedProductIntroduction: knowledgeBaseArticle.relatedProductIntroduction,
              relatedProductSubtitle: knowledgeBaseArticle.relatedProductSubtitle
            }
          : undefined,
        isLoading
      };
    }
    return undefined;
  }
);
