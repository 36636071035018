import { gql } from '@apollo/client';

export const FooterTextContentFragment = gql`
  fragment FooterTextContent on FooterTextContentRecord {
    id
    type: __typename
    title
    content
    imageSide
    images {
      url
      alt
    }
  }
`;
