import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { DropdownContainerComponent } from './dropdown-container/dropdown-container.component';
import { DropdownService } from './dropdown.service';
import { DropdownTriggerDirective } from './dropdown-trigger.directive';

@NgModule({
  declarations: [DropdownTriggerDirective, DropdownContainerComponent],
  imports: [CommonModule, OverlayModule, PortalModule],
  exports: [DropdownTriggerDirective]
})
export class DropdownModule {
  public static forRoot(): ModuleWithProviders<DropdownModule> {
    return {
      ngModule: DropdownModule,
      providers: [DropdownService]
    };
  }
}
