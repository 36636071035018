<div [ngClass]="['form-check', 'form-check-' + size]" [class.form-switch]="switch" [class.error]="hasError">
  <input
    #input
    class="form-check-input"
    type="checkbox"
    [name]="name"
    [id]="name"
    (click)="onCheckboxChange($event)"
    [disabled]="control.disabled$ | async"
    [value]="control.value || false"
    [attr.checked]="control.value || null"
    [attr.role]="switch ? 'switch' : null"
    (focus)="focused = true"
    (blur)="focused = false; onBlur()"
  />
  <label class="form-check-label" [for]="name" (click)="onLabelClick($event)">
    <div *ngIf="switch" class="form-switch-button">
      <div class="form-switch-knob"></div>
    </div>
    <ng-content></ng-content>
  </label>
  <small class="form-hint body-tiny" *ngIf="hint || (hasError && error)">{{ hasError ? error : hint }}</small>
</div>
