import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from '@core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LanguageService } from '@core/modules/language/language.service';
import { appPlaceholderIcon } from '@icons/placeholder';
import { Configuration as ShopConfiguration } from '@dd/shop-client-sdk';
import { DropdownModule } from '@shared/components/dropdown/dropdown.module';
import { ToastMessageModule } from '@shared/modules/toast-message/toast-message.module';
import { Configuration as ResultConfiguration } from '@dd/results-client-sdk';
import { NO_ANALYTICS_TOKEN } from '@core/tokens/no-analytics.token';
import { DatoConfiguration } from '@core/services/dato/dato-configuration';
import { ProfileStoreModule } from '@shared/store/profile/profile-store.module';
import { BASE_URL } from '@core/tokens/base-url.token';
import { ReviewProviderConfiguration } from '@shared/services/review-provider/review-provider-configuration';
import { DatoCampaignRoutesService } from '@core/services/dato/data-services/campaign-routes.service';
import { firstValueFrom } from 'rxjs';
import { provideSvgIconsConfig } from '@ngneat/svg-icon';
import { CONFIG_TOKEN } from '../../config-token';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Config } from './config';

declare global {
  interface Window {
    isAnalyticsEnabled: () => boolean;
  }
}

const loadCampaignRoutes = (datoCampaignRoutesService: DatoCampaignRoutesService) => {
  return () => {
    return firstValueFrom(datoCampaignRoutesService.fetchRoutes$());
  };
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    ProfileStoreModule,
    ToastMessageModule.forRoot(),
    DropdownModule.forRoot()
  ],
  bootstrap: [AppComponent],
  providers: [
    provideSvgIconsConfig({
      missingIconFallback: appPlaceholderIcon
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: loadCampaignRoutes,
      deps: [DatoCampaignRoutesService],
      multi: true
    },
    {
      provide: ShopConfiguration,
      useFactory: (config: Config) =>
        new ShopConfiguration({
          basePath: config.backendRootUrl,
          withCredentials: true
        }),
      deps: [CONFIG_TOKEN]
    },
    {
      provide: ResultConfiguration,
      useFactory: (config: Config) =>
        new ResultConfiguration({
          basePath: config.backendRootUrl,
          withCredentials: true
        }),
      deps: [CONFIG_TOKEN]
    },
    {
      provide: DatoConfiguration,
      useFactory: (config: Config) => {
        new DatoConfiguration({
          datoUrl: config.datoUrl,
          datoReadToken: config.datoReadToken
        });
      },
      deps: [CONFIG_TOKEN]
    },
    {
      provide: ReviewProviderConfiguration,
      useFactory: (config: Config) => {
        return new ReviewProviderConfiguration({
          providerUrl: config.reviewProviderBaseUrl,
          providerToken: config.reviewProviderToken,
          locationId: config.kiyohLocationId,
          tenantId: config.kiyohTenantId
        });
      },
      deps: [CONFIG_TOKEN]
    },
    {
      provide: NO_ANALYTICS_TOKEN,
      useFactory: () => typeof window.isAnalyticsEnabled === 'function' && !window.isAnalyticsEnabled()
    },
    { provide: 'googleTagManagerId', useFactory: (config: Config) => config.googleTagManagerId, deps: [CONFIG_TOKEN] },
    { provide: 'googleMeasurementId', useFactory: (config: Config) => config.googleMeasurementId, deps: [CONFIG_TOKEN] },
    { provide: BASE_URL, useFactory: (config: Config) => config.baseUrl, deps: [CONFIG_TOKEN] }
  ]
})
export class AppModule {
  public constructor(_languageService: LanguageService) {
    // To initialize languageService
  }
}
