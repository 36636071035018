import { RouterLink } from '@angular/router';
import { Directive } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'a[routerLink]'
})
export class QueryParamsHandlingDirective {
  constructor(routerLink: RouterLink) {
    routerLink.queryParamsHandling = 'merge';
  }
}
