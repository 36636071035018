import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { appRoadSignIcon } from '@icons/road-sign';
import { provideSvgIcons, SvgIconComponent } from '@ngneat/svg-icon';
import { NoMoreInStockComponent } from './no-more-in-stock.component';

@NgModule({
  declarations: [NoMoreInStockComponent],
  imports: [CommonModule, TranslateModule, SvgIconComponent],
  providers: [provideSvgIcons([appRoadSignIcon])],
  exports: [NoMoreInStockComponent]
})
export class NoMoreInStockModule {}
