import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, inject, Input, ViewEncapsulation } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[ddButton], a[ddButton]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [SvgIconComponent],
  standalone: true
})
export class ButtonComponent {
  @Input() public htmlType: 'submit' | 'button' | 'reset' = 'button';
  @Input() public size: 'extra-small' | 'small' | 'normal' | 'large' = 'normal';
  @Input() public level: 'primary' | 'secondary' | 'tertiary' | 'nav' = 'primary';
  @Input() public type: 'text' | 'icon' | 'fab' = 'text';
  @Input() public iconSize: 'xl' | 'lg' | 'md' | 'sm' | 'xs' = 'lg';
  @Input() public leftIcon?: string;
  @Input() public rightIcon?: string;
  @Input() public inverse = false;

  public elementRef = inject(ElementRef);

  public disabled = false;

  @HostBinding('class') public get classes(): string {
    return [
      'btn',
      `btn-${this.size}`,
      `btn-${this.level}`,
      `btn-${this.type}`,
      this.disabled ? 'btn-disabled' : '',
      this.inverse ? 'btn-inverse' : ''
    ]
      .filter(Boolean)
      .join(' ');
  }

  @HostBinding('type') public get buttonHtmlType(): string {
    return this.htmlType;
  }
}
