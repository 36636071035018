import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BreakpointService } from '@core/services/breakpoint.service';

@Component({
  selector: 'app-payment-icons',
  templateUrl: './payment-icons.component.html',
  styleUrls: ['./payment-icons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentIconsComponent {
  @Input() public bigIcons = false;
  constructor(public readonly breakpointService: BreakpointService) {}
}
