import { Language as ApiLanguage } from '@dd/shop-client-sdk';
import { Language } from '../checkout/language';

export const mapLanguageToApiLanguage = (language: Language): ApiLanguage => {
  switch (language) {
    case Language.Gb: {
      return ApiLanguage.En;
    }
    case Language.Nl: {
      return ApiLanguage.Nl;
    }
    default: {
      return ApiLanguage.Nl;
    }
  }
};

export const mapApiLanguageToLanguage = (language: ApiLanguage): Language => {
  switch (language) {
    case ApiLanguage.En: {
      return Language.Gb;
    }
    case ApiLanguage.Nl: {
      return Language.Nl;
    }
    default: {
      return Language.Nl;
    }
  }
};

export const mapStringToApiLanguage = (language: string): ApiLanguage => {
  switch (language) {
    case 'en': {
      return ApiLanguage.En;
    }
    case 'nl': {
      return ApiLanguage.Nl;
    }
    default: {
      return ApiLanguage.Nl;
    }
  }
};

export const mapStringToLocale = (language: string): string => {
  switch (language) {
    case 'en': {
      return 'English';
    }
    case 'nl': {
      return 'Dutch';
    }
    default: {
      return language;
    }
  }
};
