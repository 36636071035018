import { NgModule } from '@angular/core';
import { appArrowDownIcon } from '@icons/arrow-down';
import { appArrowUpIcon } from '@icons/arrow-up';
import { SharedModule } from '@shared/shared.module';
import { provideSvgIcons } from '@ngneat/svg-icon';
import { ExpandComponent } from './expand.component';

@NgModule({
  declarations: [ExpandComponent],
  imports: [SharedModule],
  providers: [provideSvgIcons([appArrowDownIcon, appArrowUpIcon])],
  exports: [ExpandComponent]
})
export class ExpandModule {}
