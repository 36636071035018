import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  Optional,
  Self,
  TrackByFunction,
  ViewChild
} from '@angular/core';
import { NgControl, Validators } from '@angular/forms';
import { ControlValueAccessor, FormControl } from '@ngneat/reactive-forms';
import { SelectOption } from './select.vm';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectComponent extends ControlValueAccessor<string> {
  private static readonly EMPTY_VALUE = '';
  @Input() public label?: string;
  @Input() public hint?: string;
  @Input() public prefix = false;
  @Input() public suffix = false;
  @Input() public clearable = false;
  @Input() public error?: string | null;
  @Input() public showOptionalLabel = false;
  @Input() public autocomplete?: string;
  @Input() public placeholder = 'Please select...';
  @Input() public options: SelectOption[] = [];
  @Input() public size: 'small' | 'medium' | 'large' = 'medium';
  @ViewChild('element') public element?: ElementRef<HTMLSelectElement>;
  public onChange?: (value: string | null) => void;

  public constructor(
    @Optional() @Self() private readonly ngControl: NgControl,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public get control(): FormControl<string> {
    return this.ngControl.control as FormControl<string>;
  }

  public get hasError(): boolean {
    return (this.control?.invalid || Object.values(this.control?.errors || []).length > 0) && !!this.control?.touched;
  }

  public get isRequired(): boolean {
    return this.control.hasValidator(Validators.required);
  }

  public get isEmpty(): boolean {
    return this.control.value === SelectComponent.EMPTY_VALUE;
  }

  public focusInput(): void {
    this.element?.nativeElement.focus();
  }

  public writeValue(value: string): void {
    if (value && this.control.value !== value) {
      this.control.setValue(value);
      this.cdr.markForCheck();
    }
  }

  public onClearIconClick(): void {
    this.control.setValue(SelectComponent.EMPTY_VALUE);
  }

  public trackByValue: TrackByFunction<SelectOption> = (_, item) => item.value;
}
