import { PersonalDetails as ApiPersonalDetails, PersonalDetailsSexAtBirthEnum, Profile as ApiProfile } from '@dd/shop-client-sdk';
import { PersonalDetailsFormGroup } from '@shared/modules/personal-details-form/personal-details-form-group.interface';
import { formatISO } from 'date-fns';
import { Profile, ProfilePersonalDetails } from '../profile/profile';
import { mapApiLanguageToLanguage, mapLanguageToApiLanguage } from './map-language';

export const mapApiProfileResponse = (profile: ApiProfile): Profile => {
  const extractIdRegExp = new RegExp('[^/]+$');

  return {
    shopifyId: profile.shopifyId.match(extractIdRegExp)![0],
    personalDetails: mapApiPersonalDetailsToPersonalDetails(profile.personalDetails),
    shippingAddress: profile.shippingAddress
  };
};

export const mapPersonalDetailsToApoPersonalDetails = (personalDetails: ProfilePersonalDetails): ApiPersonalDetails => {
  return {
    ...personalDetails,
    email: personalDetails.email,
    sexAtBirth: personalDetails.sexAtBirth as PersonalDetailsSexAtBirthEnum,
    preferredLanguage: mapLanguageToApiLanguage(personalDetails.preferredLanguage)
  };
};

export const mapApiPersonalDetailsToPersonalDetails = (personalDetails: ApiPersonalDetails): ProfilePersonalDetails => {
  return {
    ...personalDetails,
    email: personalDetails.email,
    sexAtBirth: personalDetails.sexAtBirth as PersonalDetailsSexAtBirthEnum,
    preferredLanguage: mapApiLanguageToLanguage(personalDetails.preferredLanguage)
  };
};

export const mapPersonalDetailsFormToPersonalDetails = (personalDetailsForm: PersonalDetailsFormGroup): ApiPersonalDetails => {
  return {
    ...personalDetailsForm,
    birthDate:
      personalDetailsForm.birthDate instanceof Date
        ? formatISO(personalDetailsForm.birthDate, {
            representation: 'date'
          })
        : personalDetailsForm.birthDate,
    email: personalDetailsForm.email!,
    sexAtBirth: personalDetailsForm.sexAtBirth as PersonalDetailsSexAtBirthEnum,
    preferredLanguage: mapLanguageToApiLanguage(personalDetailsForm.preferredLanguage)
  };
};
