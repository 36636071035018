<button ddButton class="position-absolute top-0 end-0 mt-3 me-3" size="small" type="icon" level="tertiary" (click)="closeDialog()">
  <svg-icon key="close" fontSize="24px"></svg-icon>
</button>
<h1 class="body-large-bold mb-4">{{ 'cookie-policy.dialog.title' | translate }}</h1>
<form *ngrxLet="cookieCategories$ as cookieCategories" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="scroll pe-4">
    <div
      class="body-small"
      *ngFor="let category of cookieCategories; index as i; trackBy: trackByFn"
      [class.mb-4]="i !== cookieCategories.length - 1"
    >
      <div
        class="d-flex flex-sm-row justify-content-between mb-1"
        [class.flex-row]="!category.required"
        [class.flex-column]="category.required"
      >
        <h2 class="body-small-bold mb-1">{{ 'cookie-policy.' + category.type + '.title' | translate }}</h2>
        <strong class="color-primary500" *ngIf="category.required">
          {{ 'cookie-policy.dialog.always-active' | translate }}
        </strong>
        <app-checkbox [formControl]="form.controls[category.type]" *ngIf="!category.required" [name]="category.type" [switch]="true">
        </app-checkbox>
      </div>
      <div class="body-small mb-1 inner-html-paragraph" [innerHTML]="category.description | safeHtml"></div>
      <app-expand
        *ngIf="category.cookies.length"
        class="mb-1"
        [expandLabel]="'cookie-policy.dialog.show-cookie-list' | translate"
        [collapseLabel]="'cookie-policy.dialog.hide-cookie-list' | translate"
        buttonPadding="0"
      >
        <ul class="mb-0 ps-2-5">
          <li *ngFor="let cookie of category.cookies; trackBy: trackByFn" class="mb-0-5">
            <code>{{ cookie.cookieName }}</code>
            <div *ngIf="cookie.cookieDescription" class="mb-0 inner-html-paragraph" [innerHTML]="cookie.cookieDescription | safeHtml"></div>
          </li>
        </ul>
      </app-expand>
      <a [routerLink]="[RouteSegment.Root, RouteSegment.PrivacyPolicy] | localizeUrl" (click)="closeDialog()">
        {{ 'cookie-policy.dialog.details' | translate }}
      </a>
    </div>
  </div>
  <div class="d-flex flex-md-row flex-column gap-2 mt-4">
    <button ddButton htmlType="submit">{{ 'cookie-policy.dialog.submit' | translate }}</button>
    <button ddButton level="secondary" [inverse]="false" (click)="onRejectClick()">
      {{ 'cookie-policy.decline' | translate }}
    </button>
  </div>
</form>
