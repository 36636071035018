import { EcommerceItem } from '@shared/interfaces/tags/ecommerce-item.interface';
import { CartItemResponse } from '@shop/modules/cart/cart.interface';
import { DatoProductItem } from '@core/services/dato/interfaces/product.interface';
import { CartItem } from '../cart/cart-item';

export const mapCartItem = (cartItem: CartItem): CartItemResponse => {
  const extractProductIdRegExp = new RegExp('[^/]+$');
  const discountUnitPrice = cartItem.product.priceDifferencePercentage ? cartItem.product.price : 0;
  const finalPriceWithoutDiscount = cartItem.product.originalPrice ? cartItem.product.originalPrice * cartItem.quantity : 0;
  const price = (discountUnitPrice ?? cartItem.product.price) * cartItem.quantity;
  const productId = cartItem.product.variantId.match(extractProductIdRegExp)![0];
  return {
    id: cartItem.id,
    discountUnitPrice,
    name: cartItem.product.name ?? cartItem.product.handle,
    handle: cartItem.product.handle,
    quantity: cartItem.quantity,
    image: cartItem.product.images[0],
    variantId: cartItem.product.variantId,
    price,
    unitPrice: cartItem.product.price,
    sku: cartItem.product.sku,
    quantityAvailable: cartItem.product.quantityAvailable,
    unitPriceWithoutDiscount: cartItem.product.originalPrice ?? cartItem.product.price,
    finalPrice: cartItem.product.price * cartItem.quantity,
    finalPriceWithoutDiscount,
    productType: cartItem.product.productType,
    vendor: cartItem.product.vendor,
    productId
  };
};

export const mapCartItemHandleToName = (cartItems: CartItem[], productItems: DatoProductItem[]): CartItem[] => {
  return cartItems.map((cartItem) => {
    const product = productItems.find((item) => cartItem.product.handle === item?.sku);

    return {
      ...cartItem,
      product: {
        ...cartItem.product,
        name: product?.title,
        images: [product?.images[0].responsiveImage?.src ?? cartItem.product.images[0]]
      }
    };
  });
};

export const mapCartItemToEcommerceItem = (cartItem: CartItemResponse): EcommerceItem => {
  return {
    currency: 'EUR',
    item_brand: cartItem.vendor,
    item_category: cartItem.productType,
    item_id: cartItem.productId,
    sku: cartItem.sku,
    item_name: cartItem.sku,
    price: cartItem.unitPrice,
    quantity: cartItem.quantity
  };
};
