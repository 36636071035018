/* eslint-disable rxjs/finnish */
import { OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { DropdownContainerComponent } from './dropdown-container/dropdown-container.component';

/**
 * Reference to a dropdown dispatched from the dropdown service.
 */
export class DropdownRef<T = unknown, R = unknown> {
  /** Instance of the component making up the content of the dropdown. */
  public instance!: T;

  /**
   * Instance of the component into which the dropdown content is projected.
   *
   * @docs-private
   */
  public containerInstance: DropdownContainerComponent;

  /** Whether the user is allowed to close the dropdown. */
  public disableClose: boolean | undefined;

  /** Subject for notifying the user that the dropdown has been dismissed. */
  private readonly _afterDismissed$ = new Subject<R | undefined>();

  /** Subject for notifying the user that the dropdown has opened and appeared. */
  private readonly _afterOpened$ = new Subject<void>();

  /** Result to be passed down to the `afterDismissed` stream. */
  private readonly _result: R | undefined;

  constructor(
    containerInstance: DropdownContainerComponent,
    public readonly _overlayRef: OverlayRef
  ) {
    this.containerInstance = containerInstance;
    const config = containerInstance.dropdownConfig;

    containerInstance.onOpened.pipe(take(1)).subscribe(() => {
      this._afterOpened$.next();
      this._afterOpened$.complete();
    });

    const outsideClickListener = _overlayRef
      .outsidePointerEvents()
      .pipe(
        filter(() => !!config.disposeOnOutsideClick),
        filter((event) => {
          if (!config.triggerElement || !event.target) {
            return true;
          }
          return !(event.target === config.triggerElement || config.triggerElement.contains(event.target as HTMLElement));
        }),
        take(1)
      )
      .subscribe(() => {
        this.dismiss();
      });

    _overlayRef.detachments().subscribe(() => {
      this._afterDismissed$.next(this._result);
      this._afterDismissed$.complete();
      outsideClickListener.unsubscribe();
    });
  }

  /**
   * Dismisses the dropdown.
   */
  public dismiss(): void {
    this._overlayRef.detachBackdrop();
    this._overlayRef.detach();
    this._overlayRef.dispose();
  }

  /** Gets an observable that is notified when the dropdown is finished closing. */
  public afterDismissed(): Observable<R | undefined> {
    return this._afterDismissed$;
  }

  /** Gets an observable that is notified when the dropdown has opened and appeared. */
  public afterOpened(): Observable<void> {
    return this._afterOpened$;
  }

  /**
   * Gets an observable that emits when the overlay's backdrop has been clicked.
   */
  public backdropClick(): Observable<MouseEvent> {
    return this._overlayRef.backdropClick();
  }

  /**
   * Gets an observable that emits when keydown events are targeted on the overlay.
   */
  public keydownEvents(): Observable<KeyboardEvent> {
    return this._overlayRef.keydownEvents();
  }
}
