import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { filter, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { isNotUndefined } from '@shared/utils/not-undefined';
import { map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { selectKnowledgeBaseArticle } from '@shop/pages/knowledge-base/pages/article/store/article.selectors';
import { getArticle } from '@shop/pages/knowledge-base/pages/article/store/article.actions';

@Injectable()
@UntilDestroy()
export class BreadcrumbArticleNameResolver implements Resolve<string> {
  constructor(
    private readonly store: Store,
    private readonly translateService: TranslateService
  ) {
    this.translateService.onLangChange.pipe(untilDestroyed(this)).subscribe(() => {
      return this.selectArticle$();
    });
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<string> {
    const slug = route.paramMap.get('slug');
    this.store.dispatch(getArticle({ slug: slug ?? '' }));

    return this.selectArticle$();
  }

  private selectArticle$(): Observable<string> {
    return this.store.select(selectKnowledgeBaseArticle).pipe(
      filter(isNotUndefined),
      // eslint-disable-next-line @ngrx/avoid-mapping-selectors
      map((article) => {
        return article.breadcrumb ?? article.title;
      })
    );
  }
}
