import {
  animate,
  state,
  style,
  transition,
  trigger,
  AnimationTriggerMetadata,
  group,
  query,
  animateChild,
  sequence
} from '@angular/animations';
import { AnimationCurves, AnimationDurations } from '@angular/material/core';

export const bottomSheetAndDialogAnimations: {
  readonly bottomSheetState: AnimationTriggerMetadata;
} = {
  bottomSheetState: trigger('state', [
    state('void, hidden', style({ transform: 'translateY(100%)' })),
    state('visible', style({ transform: 'translateY(0%)' })),
    state('visibleDialog', style({ transform: 'translateY(0%)', opacity: 1 })),
    transition(
      'visible => void, visible => hidden',
      group([
        sequence([
          style({
            transform: `translateY(0%)`
          }),
          animate(`${AnimationDurations.COMPLEX} ${AnimationCurves.ACCELERATION_CURVE}`, style({ transform: 'translateY(100%)' }))
        ]),
        query('@*', animateChild(), { optional: true })
      ])
    ),
    transition(
      'void => visible',
      group([
        animate(`${AnimationDurations.EXITING} ${AnimationCurves.DECELERATION_CURVE}`),
        query('@*', animateChild(), { optional: true })
      ])
    ),
    transition(
      'void => visibleDialog, hidden => visibleDialog',
      group([
        sequence([
          style({
            transform: `translateY(50px)`,
            opacity: 0
          }),
          animate('250ms cubic-bezier(0, 0, 0.2, 1)')
        ]),
        query('@*', animateChild(), { optional: true })
      ])
    ),
    transition(
      'visibleDialog => void, visibleDialog => hidden',
      group([
        sequence([
          style({
            transform: `translateY(0)`,
            opacity: 1
          }),
          animate(
            '75ms cubic-bezier(0.4, 0.0, 0.2, 1)',
            style({
              opacity: 0
            })
          )
        ]),
        query('@*', animateChild(), { optional: true })
      ])
    )
  ])
};
