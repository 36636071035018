import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getStoreReviews } from './store/reviews.actions';

@Injectable({
  providedIn: 'root'
})
export class ReviewsService {
  constructor(private readonly store: Store) {}

  public getStoreReviews(): void {
    this.store.dispatch(getStoreReviews());
  }
}
