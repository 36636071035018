import { Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, TemplateRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { DropdownService } from './dropdown.service';
import { DropdownConfig } from './dropdown-trigger-config';
import { DropdownRef } from './dropdown-ref';

@UntilDestroy()
@Directive({
  selector: '[appDropdownTrigger]',
  providers: [
    {
      provide: DropdownService,
      useClass: DropdownService
    }
  ]
})
export class DropdownTriggerDirective implements OnDestroy {
  @Input() public config!: DropdownConfig;
  @Input() public templateRef?: TemplateRef<unknown>;
  @Output() public afterDismissed: EventEmitter<void> = new EventEmitter<void>();
  @Output() public afterOpened: EventEmitter<void> = new EventEmitter<void>();
  public isDropdownOpen$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    public readonly dropdown: DropdownService
  ) {}

  @HostListener('click', ['$event'])
  public onClick(): void {
    if (!this.isDropdownOpen$.getValue()) {
      this.afterOpened.emit();
      this.isDropdownOpen$.next(true);
    }

    const dropdownRef = this.toggleDropdown();
    dropdownRef
      .afterDismissed()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isDropdownOpen$.next(false);
        this.afterDismissed.emit();
      });
  }

  public ngOnDestroy(): void {
    this.afterDismissed.emit();
  }

  public toggleDropdown(): DropdownRef {
    if (this.templateRef) {
      this.config.componentOrTemplateRef = this.templateRef;
    }
    return this.dropdown.toggleDropdown(this.elementRef, { ...this.config, triggerElement: this.elementRef.nativeElement });
  }
}
