import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { featureKeyNewsletter, reducerNewsletter } from '@shared/store/newsletter/newsletter.reducer';
import { NewsletterEffects } from '@shared/store/newsletter/newsletter.effects';
import { NewsletterService } from '@shared/store/newsletter/newsletter.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature(featureKeyNewsletter, reducerNewsletter), EffectsModule.forFeature([NewsletterEffects])]
})
export class NewsletterStoreModule {
  public static forRoot(): ModuleWithProviders<NewsletterStoreModule> {
    return {
      ngModule: NewsletterStoreModule,
      providers: [NewsletterService]
    };
  }
}
