<button
  *ngIf="(breakpointService.downMd$ | async) === false"
  ddButton
  type="icon"
  level="nav"
  size="normal"
  appDropdownTrigger
  [class.opened]="isOpen$ | async"
  [config]="dropdownConfig"
  [templateRef]="languagePicker"
  (afterOpened)="languagePickerOpened.emit()"
  (afterDismissed)="languagePickerClosed.emit()"
  [appPushTagOnClick]="{
    event: TagEvent.HeaderMenuClick,
    menu_name: 'Language Picker'
  }"
>
  <svg-icon [key]="(currentLanguageFlag$ | async)!" fontSize="24px"></svg-icon>
</button>

<ng-container *ngIf="breakpointService.downMd$ | async">
  <ng-container *ngTemplateOutlet="languagePicker"></ng-container>
</ng-container>

<ng-template #languagePicker>
  <div class="d-flex flex-column">
    <button
      ddButton
      class="flag-button body-small-regular"
      type="text"
      level="tertiary"
      (click)="switchLanguage('en')"
      leftIcon="uk-flag"
      [appPushTagOnClick]="{
        event: TagEvent.HeaderMenuClick,
        menu_name: 'Language English'
      }"
    >
      <span [class.font-weight-bold]="(currentLanguageFlag$ | async) === 'uk-flag'">{{ 'language-select.GB' | translate }}</span>
    </button>
    <button
      ddButton
      class="flag-button body-small-regular"
      type="text"
      level="tertiary"
      (click)="switchLanguage('nl')"
      leftIcon="nl-flag"
      [appPushTagOnClick]="{
        event: TagEvent.HeaderMenuClick,
        menu_name: 'Language Dutch'
      }"
    >
      <span [class.font-weight-bold]="(currentLanguageFlag$ | async) === 'nl-flag'">{{ 'language-select.NL' | translate }}</span>
    </button>
  </div>
</ng-template>
