import { DOCUMENT, Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { changeLanguage } from '@core/modules/language/store/language.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, distinctUntilChanged, filter, map, skip } from 'rxjs';
import englishTranslation from '../../../../assets/i18n/en.json';
import dutchTranslation from '../../../../assets/i18n/nl.json';

export const SUPPORTED_LANGUAGES = ['en', 'nl'];

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public readonly DEFAULT_LANGUAGE = 'nl';
  public currentLanguage$: BehaviorSubject<string> = new BehaviorSubject<string>(this.DEFAULT_LANGUAGE);
  private readonly STORAGE_KEY = 'ddSelectedLanguage';

  constructor(
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly location: Location,
    private readonly store: Store,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    const browserLang = this.translateService.getBrowserLang() ?? this.DEFAULT_LANGUAGE;
    const currentLanguage = this.getBestAvailableLanguage(localStorage.getItem(this.STORAGE_KEY) ?? browserLang);
    this.translateService.setTranslation('en', englishTranslation);
    this.translateService.setTranslation('nl', dutchTranslation);
    this.translateService.setDefaultLang(this.DEFAULT_LANGUAGE);
    this.translateService.addLangs(SUPPORTED_LANGUAGES);
    this.translateService.use(currentLanguage);
    this.currentLanguage$.next(currentLanguage);
    this.router.events
      .pipe(
        skip(1),
        filter((routerEvent) => routerEvent instanceof NavigationEnd),
        map(() => this.activatedRoute.snapshot.firstChild?.paramMap.get('languageId')),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe((languageId) => {
        this.switchLanguage(languageId ?? this.DEFAULT_LANGUAGE, false);
      });
  }

  public replaceLanguageInUrl(url: string, language: string): string {
    // eslint-disable-next-line unicorn/prefer-string-replace-all
    return url.replace(new RegExp(`^\/${this.translateService.currentLang}`, 'g'), `/${language}`);
  }

  public switchLanguage(language: string, updateUrl: boolean = true): void {
    this.document.documentElement.setAttribute('lang', language);

    if (updateUrl) {
      const newPath = this.replaceLanguageInUrl(this.location.path(), language);
      const queryParams = location.search;
      const hash = location.hash;
      this.location.replaceState(`${newPath}${queryParams}${hash}`);
    }

    localStorage.setItem(this.STORAGE_KEY, language);
    this.translateService.use(language);
    this.currentLanguage$.next(language);
    this.store.dispatch(changeLanguage());
  }

  private getBestAvailableLanguage(currentLanguage: string): string {
    return SUPPORTED_LANGUAGES.includes(currentLanguage) ? currentLanguage : this.DEFAULT_LANGUAGE;
  }
}
