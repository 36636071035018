<div class="content overflow-hidden" [@expandAnimation]="expanded ? ExpandState.Expanded : ExpandState.Collapsed">
  <ng-content></ng-content>
</div>
<div class="d-flex" [ngClass]="['justify-content-' + buttonPosition]">
  <button
    ddButton
    level="tertiary"
    class="toggle btn-unstyled body-normal-bold"
    [class.expanded]="expanded"
    (click)="onToggleClick($event)"
    rightIcon="arrow-down"
    [style.padding]="buttonPadding || null"
  >
    {{ expanded ? collapseLabel : expandLabel }}
  </button>
</div>
