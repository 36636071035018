import { AfterViewInit, ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TagPusherService } from '@core/services/tag-pusher.service';
import { Store } from '@ngrx/store';
import { TagEvent } from '@shared/enums/tags/tag-event.enum';
import { selectProfile } from '@shared/store/profile/profile.selectors';
import { CONFIG_TOKEN } from 'config-token';
import { combineLatestWith, distinctUntilKeyChanged, filter, map } from 'rxjs';
import { getProfileIfNeeded } from '@shared/store/profile/profile.actions';
import { Config } from './config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements AfterViewInit {
  constructor(
    @Inject(CONFIG_TOKEN) public config: Config,
    private readonly tagPusherService: TagPusherService,
    private readonly store: Store,
    private readonly router: Router
  ) {
    this.store.dispatch(getProfileIfNeeded());
  }

  public ngAfterViewInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event) => event as NavigationEnd),
        distinctUntilKeyChanged('url'),
        combineLatestWith(
          this.store.select(selectProfile).pipe(
            filter((profile) => {
              return !profile.isLoading;
            })
          )
        )
      )
      // eslint-disable-next-line rxjs-angular/prefer-async-pipe
      .subscribe(([event, profile]) => {
        this.tagPusherService.pushTag({
          event: TagEvent.PageView,
          pageName: event.url,
          user_id: profile.data?.shopifyId
        });
      });
  }
}
