<div
  class="position-relative"
  [ngClass]="{
    disabled: control.disabled$ | async,
    error: hasError,
    'input-has-prefix': prefix,
    'input-has-suffix': suffix,
    'input-sm': size === 'small',
    'input-md': size === 'medium',
    'input-lg': size === 'large'
  }"
>
  <label *ngIf="label" title="{{ label }}" (click)="focusInput()" class="form-label body-tiny-medium">
    {{ label }}
    <span class="color-text300" *ngIf="showOptionalLabel && !isRequired">({{ 'common.optional' | translate }})</span>
  </label>
  <div *ngIf="prefix" class="input-prefix">
    <ng-content select="[prefix]"></ng-content>
  </div>
  @if (dateValueTypes.includes(type)) {
    <input
      #dateInput
      class="form-input"
      matInput
      [matDatepicker]="picker"
      [attr.disabled]="(control.disabled$ | async) || null"
      [attr.autocomplete]="autocomplete || null"
      [attr.inputmode]="inputmode || null"
      [attr.pattern]="pattern || null"
      [disabled]="!!(control.disabled$ | async)"
      [(ngModel)]="nativeInputValue"
      (ngModelChange)="onValueChange($event)"
      [type]="'text'"
      [placeholder]="placeholder ?? ''"
      (cdkAutofill)="autocompleted.emit($event)"
      (keydown)="userInput.emit($event)"
      (blur)="onTouched!()"
    />
  } @else {
    <input
      #input
      class="form-input"
      [attr.min]="canMinMax && min !== undefined ? min : null"
      [attr.max]="canMinMax && max !== undefined ? max : null"
      [attr.step]="type === 'number' && step !== undefined ? step : null"
      [attr.minlength]="type === 'text' && minLength !== undefined ? minLength : null"
      [attr.maxlength]="type === 'text' && maxLength !== undefined ? maxLength : null"
      [attr.disabled]="(control.disabled$ | async) || null"
      [attr.autocomplete]="autocomplete || null"
      [attr.inputmode]="inputmode || null"
      [attr.pattern]="pattern || null"
      [disabled]="!!(control.disabled$ | async)"
      [(ngModel)]="nativeInputValue"
      (ngModelChange)="onValueChange($event)"
      [type]="currentType ? currentType : 'text'"
      [placeholder]="placeholder ?? ''"
      (cdkAutofill)="autocompleted.emit($event)"
      (keydown)="userInput.emit($event)"
      (blur)="onTouched!()"
    />
  }

  <div *ngIf="suffix" class="input-suffix">
    <!-- Password show/hide button -->
    <button
      type="button"
      *ngIf="type === 'password'"
      class="input-suffix-button color-primary500"
      (click)="onSuffixButtonClick($event)"
      title="{{ currentType === 'password' ? ('password.show-password' | translate) : ('password.hide-password' | translate) }}"
      [attr.tabindex]="control.disabled ? '-1' : null"
    >
      <svg-icon *ngIf="currentType === 'password'" key="eye-no" size="lg"></svg-icon>
      <svg-icon *ngIf="currentType === 'text'" key="eye-yes" size="lg"></svg-icon>
    </button>
    <!-- Clear button -->
    <button
      type="button"
      *ngIf="type !== 'password' && clearable && nativeInputValue"
      class="input-suffix-button"
      (click)="onClearIconClick($event)"
      [attr.tabindex]="control.disabled ? '-1' : null"
    >
      <svg-icon key="close" size="lg"></svg-icon>
    </button>
    <!-- Calendar button -->
    @if (['date', 'datetime-local', 'month', 'week'].includes(type)) {
      <button
        type="button"
        class="input-suffix-button"
        (click)="onSuffixButtonClick($event, picker)"
        [attr.tabindex]="control.disabled ? '-1' : null"
      >
        <svg-icon key="calendar" size="lg"></svg-icon>
      </button>
    }

    <!-- Time button -->
    <button type="button" *ngIf="type === 'time'" class="input-suffix-button" (click)="onSuffixButtonClick($event)">
      <svg-icon key="time" size="lg"></svg-icon>
    </button>
    <!-- Search icon -->
    <svg-icon *ngIf="type === 'search'" key="search" size="lg"></svg-icon>
    <!-- Any other suffix -->
    <ng-container *ngIf="!clearable && !['search', 'password', 'date', 'time', 'datetime-local', 'month', 'week'].includes(type)">
      <ng-content select="[suffix]"></ng-content>
    </ng-container>
  </div>

  <small *ngIf="hint || (hasError && error)" class="form-hint body-tiny">
    {{ hasError ? error : hint }}
  </small>
</div>
<mat-datepicker #picker type="button" class="input-suffix-button" [attr.tabindex]="control.disabled ? '-1' : null"> </mat-datepicker>
