import { gql } from '@apollo/client';

export const ExtraDetailsBlockFragment = gql`
  fragment ExtraDetailsBlock on ExtraDetailsBlockRecord {
    extraDetailsOneTitle
    extraDetailsOneIcon {
      url
    }
    extraDetailsOne
    extraDetailsTwoTitle
    extraDetailsTwoIcon {
      url
    }
    extraDetailsTwo
  }
`;
