<!-- eslint-disable @angular-eslint/template/use-track-by-function -->
<div class="d-flex flex-column menu-column gap-2 menu-item" *ngIf="group.type === FooterGroupRecordType.FooterGroupRecord">
  <h3 [class.mt-3]="isDeepGroup" class="body-small-bold mb-0">{{ group.groupTitle }}</h3>
  <div class="d-flex flex-column gap-2" *ngFor="let subGroup of group.links">
    <ng-container *ngIf="subGroup.type === FooterGroupRecordType.FooterSubGroupRecord">
      <div class="d-flex flex-column">
        <span *ngIf="subGroup.title" class="body-tiny-bold py-0-5 mb-0">{{ subGroup.title }}</span>
        <ng-container *ngFor="let link of subGroup.links">
          <a
            *ngIf="link.type === FooterLinkRecordType.RegularLinkRecord"
            [routerLink]="link.route | localizeUrl"
            [fragment]="link.fragment ? link.fragment : undefined"
          >
            {{ link.title }}
          </a>
          <a
            *ngIf="link.type === FooterLinkRecordType.ProductRecord"
            [routerLink]="[RouteSegment.Root, RouteSegment.AvailableTests, link.slug] | localizeUrl"
          >
            {{ link.title }}
          </a>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="subGroup.type === FooterGroupRecordType.FooterTextContentRecord">
      <span *ngIf="subGroup.title" class="body-small-bold mt-3 mb-0">{{ subGroup.title }}</span>
      <div class="text-content-container" [innerHTML]="subGroup.content | safeHtml"></div>
    </ng-container>
    <ng-container *ngIf="subGroup.type === FooterGroupRecordType.FooterGroupRecord">
      <app-footer-group [group]="subGroup" [isDeepGroup]="true"></app-footer-group>
    </ng-container>
  </div>
</div>
<ng-container *ngIf="group.type === FooterGroupRecordType.FooterTextContentRecord">
  <h3 *ngIf="group.title" class="body-small-bold mb-0">{{ group.title }}</h3>
  <div class="text-content-container" [innerHTML]="group.content | safeHtml"></div>
</ng-container>
