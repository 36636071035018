import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouteSegment } from '@shared/enums/route-segment.enum';
import { TagEvent } from '@shared/enums/tags/tag-event.enum';
import { FooterGroupRecordType } from '@core/services/dato/interfaces/footer-group-record-type.enum';
import { FooterService } from '@shop/modules/footer/footer.service';
import { Observable } from 'rxjs';
import { LinkRecordType } from '@core/services/dato/interfaces/link-record-type.enum';
import { FooterVM } from '@shop/modules/footer/footer.vm';
import { BreakpointService } from '@core/services/breakpoint.service';
import { FooterTextContent } from '../../../../domain/footer/footer-text-content';
import { FooterMenuLink } from './interfaces/footer-menu-link.interface';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FooterService]
})
export class FooterComponent {
  public readonly RouteSegment = RouteSegment;
  public readonly vm$: Observable<FooterVM> = this.footerService.vm$;
  public menuLinks: FooterMenuLink[] = [];
  public readonly TagEvent = TagEvent;
  public readonly FooterGroupRecordType = FooterGroupRecordType;
  public readonly FooterLinkRecordType = LinkRecordType;

  constructor(
    private readonly footerService: FooterService,
    public readonly breakpointService: BreakpointService
  ) {
    this.footerService.getFooter();
  }

  public doesTextContentHaveMutlipleIcons(textContents: FooterTextContent[]): boolean {
    return textContents.some((textContent) => (textContent.images ? textContent.images.length > 1 : false));
  }
}
