<div
  *ngrxLet="breakpointService.upMd$ as upMd"
  class="container d-flex flex-column flex-md-row justify-content-between align-items-md-center gap-1-5"
>
  <div *ngIf="upMd" class="d-flex gap-2 align-items-center">
    <div class="d-none d-md-flex flex-shrink-0">
      <img class="product-icon" [src]="product.secondaryTestkitIcon.url" [alt]="product.secondaryTestkitIcon.alt" />
    </div>
    <div *ngIf="upMd" class="title body-small header-h6-md mb-0">{{ product.title }}</div>
  </div>
  <hr *ngIf="upMd" class="color-text200 d-md-none w-100 m-0" />
  <div class="d-flex align-items-center justify-content-between gap-1-5 flex-wrap flex-md-nowrap">
    <div *ngIf="product.sale" class="discount-container background-primary500 px-1-5 rounded-1 body-xs me-md-4">
      <span class="color-text100">{{ 'product.off-sale' | translate: { percent: product.sale } }}</span>
    </div>
    <div class="me-md-8">
      <div
        *ngIf="!!product.sale && product.originalPrice && product.price < product.originalPrice"
        class="discount-price body-tiny text-decoration-line-through"
      >
        {{ product.originalPrice | currency: 'EUR' : 'symbol' }}
      </div>
      <strong
        [class.discount]="!!product.sale && product.originalPrice && product.price < product.originalPrice"
        class="font-weight-bold body-normal body-large-md original-price"
      >
        {{ product.price | currency: 'EUR' : 'symbol' }}
      </strong>
    </div>
    <ng-container *ngIf="product.published; else notAvailable">
      <app-add-to-cart class="add-to-cart" [product]="product" [inverseButton]="upMd"></app-add-to-cart>
    </ng-container>
    <ng-template #notAvailable>
      <button ddButton class="button d-flex position-relative" leftIcon="shopping-cart" size="normal" disabled>
        {{ 'product.out-of-stock' | translate }}
      </button>
    </ng-template>
    <app-no-more-in-stock *ngIf="noMoreItems$ | async" class="order-md-first w-100 d-md-none d-lg-block"></app-no-more-in-stock>
  </div>
</div>
