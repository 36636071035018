import { Injectable } from '@angular/core';
import { filter } from 'rxjs';
import { isNotUndefined } from '@shared/utils/not-undefined';
import { TranslatableContentService } from '@shared/services/translatable-content.service';
import { getFooter } from '@shop/modules/footer/store/footer.actions';
import { selectFooterData } from '@shop/modules/footer/store/footer.selectors';

@Injectable()
export class FooterService extends TranslatableContentService {
  public vm$ = this.store.select(selectFooterData).pipe(filter(isNotUndefined));

  public action(): void {
    this.getFooter();
  }

  public getFooter(): void {
    this.store.dispatch(getFooter());
  }
}
