import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddToCartModule } from '@shop/modules/add-to-cart/add-to-cart.module';
import { LetDirective } from '@ngrx/component';
import { NoMoreInStockModule } from '@shop/modules/no-more-in-stock/no-more-in-stock.module';
import { SharedModule } from '@shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ProductItemStickyComponent } from './product-item-sticky.component';

@NgModule({
  declarations: [ProductItemStickyComponent],
  imports: [CommonModule, AddToCartModule, LetDirective, NoMoreInStockModule, SharedModule, TranslateModule],
  exports: [ProductItemStickyComponent]
})
export class ProductItemStickyModule {}
