import { gql } from '@apollo/client';

export const FaqFragment = gql`
  fragment Faq on FaqRecord {
    content
    id
    title
    accordionId: faqId
  }
`;
