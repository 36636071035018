import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LazyLoadBackgroundDirective } from '@shared/directives/lazy-load-background/lazy-load-background.directive';
import { IntersectionObserverModule } from '@ng-web-apis/intersection-observer';

@NgModule({
  declarations: [LazyLoadBackgroundDirective],
  imports: [CommonModule, IntersectionObserverModule],
  exports: [LazyLoadBackgroundDirective]
})
export class LazyLoadBackgroundModule {}
