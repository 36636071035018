import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { MetaReducer, StoreModule, USER_PROVIDED_META_REDUCERS } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { BottomSheetAndDialogModule } from '@shared/components/bottom-sheet/bottom-sheet-and-dialog-module';
import { ScrollManagerModule } from '@shared/directives/scroll-manager/scroll-manager.module';
import { CookieService } from 'ngx-cookie-service';
import { TitleStrategy } from '@angular/router';
import { PageTitleStrategy } from '@core/services/page-title-strategy.service';
import { NewsletterStoreModule } from '@shared/store/newsletter/newsletter-store.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { enUS, nl } from 'date-fns/locale';
import { provideDateFnsAdapter } from '@angular/material-date-fns-adapter';
import * as RootStore from '../stores/root.store';
import { localStorageSyncReducer } from '../stores/meta-reducers/storage-sync.meta-reducer';
import { LanguageModule } from './modules/language/language.module';
import { AuthModule } from './auth/auth.module';
import { MockApiInterceptor } from './interceptors/mock-api.interceptor';
import { RequestIdInterceptor } from './interceptors/request-id.interceptor';
import { LanguageService } from './modules/language/language.service';

export const metaReducerFactory = (): MetaReducer<unknown>[] => {
  return [localStorageSyncReducer()];
};

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forRoot(RootStore.reducer, {
      runtimeChecks: {
        strictStateSerializability: true,
        strictActionSerializability: true,
        strictActionWithinNgZone: true
      }
    }),
    StoreRouterConnectingModule.forRoot(),
    ScrollManagerModule.forRoot(),
    NewsletterStoreModule.forRoot(),
    environment.production ? [] : StoreDevtoolsModule.instrument(),
    EffectsModule.forRoot(),
    BottomSheetAndDialogModule,
    LanguageModule,
    AuthModule
  ],
  providers: [
    {
      provide: CookieService
    },
    {
      provide: USER_PROVIDED_META_REDUCERS,
      useFactory: metaReducerFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestIdInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MockApiInterceptor,
      multi: true
    },
    {
      provide: MAT_DATE_LOCALE,
      useFactory: (languageService: LanguageService) => {
        return languageService.currentLanguage$.getValue() === 'nl' ? nl : enUS;
      },
      deps: [LanguageService]
    },
    provideDateFnsAdapter({
      parse: { dateInput: 'dd/MM/yyyy' },
      display: {
        dateInput: 'dd/MM/yyyy',
        monthYearLabel: 'yyyy',
        dateA11yLabel: 'dd/MM/yyyy',
        monthYearA11yLabel: 'yyyy',
        monthLabel: 'MMMM'
      }
    }),
    { provide: TitleStrategy, useClass: PageTitleStrategy }
  ]
})
export class CoreModule {}
