import { Pipe, PipeTransform } from '@angular/core';

/**
 * A pipe that checks if an item exists within an array. The array may be undefined or null at any given time
 * during the running of an application, so we accept undefined values and only observe if it is safe to do so.
 */
@Pipe({
  name: 'includes'
})
export class IncludesPipe implements PipeTransform {
  public transform(array: unknown[] | null | undefined, value: string): boolean | void {
    if (Array.isArray(array)) {
      return array.includes(value);
    }
    return false;
  }
}
