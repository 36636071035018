export enum RouteSegment {
  Root = '',
  Faq = 'veel-gestelde-vragen',
  AvailableTests = 'onze-testen',
  KnowledgeBase = 'health-reads',
  ContactUs = 'contact',
  ContactUsB2b = 'contact-b2b',
  Checkout = 'checkout',
  BarcodeActivation = 'activeer',
  BarcodeActivationNew = 'nieuw',
  BarcodeActivationExisting = 'klant',
  BarcodeActivationSuccess = 'activeer-succes',
  TheMethod = 'hoe-werkt-het',
  AboutUs = 'over-ons',
  Disclaimer = 'disclaimer',
  Complaints = 'klachten',
  PaymentMethods = 'betaalmethoden',
  PrivacyPolicy = 'privacybeleid',
  ReturnsPolicy = 'retouren',
  TermsAndConditions = 'algemene-voorwaarden',
  Dashboard = 'mijn-account',
  HealthFeed = 'gezondheidsoverzicht',
  Results = 'resultaten',
  ForgotPassword = 'wachtwoord-vergeten',
  ResetPassword = 'wachtwoord-resetten',
  Profile = 'account',
  PersonalDetails = 'persoonlijke-gegevens',
  Password = 'wachtwoord',
  Address = 'adres',
  Consent = 'toestemming',
  OrderHistory = 'orderhistorie',
  Invoices = 'facturen',
  TestkitManual = 'testkit-handleiding',
  Test = 'keuzehulp',
  MeetTheTeam = 'het-team',
  ForCompanies = 'voor-werkgevers',
  AnyOther = '**',
  NotFound = '404'
}

export enum RouteParam {
  Language = 'lang',
  Slug = 'slug',
  ProductSku = 'productSku',
  ResultId = 'resultId',
  Limit = 'limit',
  Offset = 'offset',
  Id = 'id',
  Category = 'category',
  OrderNumber = 'orderNumber'
}
