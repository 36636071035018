<form [formGroup]="form" class="d-flex align-items-center p-2 shadow-normal rounded-3 background-bg100">
  <a [routerLink]="productUrl | localizeUrl" (click)="productUrlClick.emit()" [appPushTagOnClick]="viewItemTag">
    <div class="card-image me-2 me-md-3 flex-shrink-0" [style.background-image]="'url(' + cartItem.image + ')'"></div>
  </a>
  <div class="d-flex justify-content-between align-self-stretch w-100 py-1">
    <div class="d-flex justify-content-between w-100">
      <div class="d-flex flex-column justify-content-between">
        <div>
          <a
            [routerLink]="productUrl | localizeUrl"
            (click)="productUrlClick.emit()"
            class="color-text500 body-large-bold-md body-small-bold"
            [appPushTagOnClick]="viewItemTag"
          >
            {{ cartItem.name }}
          </a>
          <div class="d-flex align-items-center">
            <span class="me-1 body-tiny body-small-sm color-text400">
              {{ cartItem.unitPrice | currency: 'EUR' : 'symbol' }}
            </span>
            <span *ngIf="cartItem.discountUnitPrice" class="color-text300 body-xs body-tiny-sm text-decoration-line-through">
              {{ cartItem.unitPriceWithoutDiscount | currency: 'EUR' : 'symbol' }}
            </span>
          </div>
        </div>
        <app-quantity-input
          class="mt-2"
          [formControl]="form.controls.quantity"
          [min]="1"
          [max]="cartItem.quantityAvailable"
        ></app-quantity-input>
      </div>
    </div>
    <div class="d-flex flex-column justify-content-between align-items-end">
      <div class="d-flex flex-column justify-content-between align-items-end">
        <span class="font-weight-bold body-small body-normal-sm" [class.color-text300]="(quantity$ | async) !== cartItem.quantity">
          {{ cartItem.finalPrice | currency: 'EUR' : 'symbol' }}
        </span>
        <span *ngIf="cartItem.finalPriceWithoutDiscount" class="color-text300 body-xs body-tiny-sm text-decoration-line-through">
          {{ cartItem.finalPriceWithoutDiscount | currency: 'EUR' : 'symbol' }}
        </span>
      </div>
      <button
        ddButton
        level="tertiary"
        size="extra-small"
        class="delete-button font-weight-regular"
        leftIcon="trash"
        [disabled]="isLoading && !!(delete$ | async)"
        (click)="removeItemFromCart()"
      ></button>
    </div>
  </div>
</form>
