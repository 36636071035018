import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { expandAnimation } from './animations/expand.animations';
import { ExpandState } from './enums/expand-state.enum';

@UntilDestroy()
@Component({
  selector: 'app-expand',
  templateUrl: './expand.component.html',
  styleUrls: ['./expand.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [expandAnimation]
})
export class ExpandComponent {
  @Input() public expanded = false;
  @Input() public expandLabel = 'Show more';
  @Input() public collapseLabel = 'Show less';
  @Input() public buttonPosition: 'start' | 'center' | 'end' = 'start';
  @Input() public buttonPadding?: string;
  @Output() public toggleClicked = new EventEmitter<boolean>();
  public readonly ExpandState = ExpandState;

  public onToggleClick(event: Event): void {
    event.preventDefault();
    this.expanded = !this.expanded;
    this.toggleClicked.emit(this.expanded);
  }
}
