import { Injectable } from '@angular/core';
import { DatoService } from '@core/services/dato/dato.service';
import { gql } from '@apollo/client';
import { map, Observable } from 'rxjs';
import { ArticleFragment } from '../fragments/article.fragment';
import { DatoMenu } from '../interfaces/navigation.interface';

@Injectable({
  providedIn: 'root'
})
export class NavigationDataService {
  constructor(private readonly datoService: DatoService) {}

  public getMenu$(locale: string = 'en', disableCache = false): Observable<DatoMenu> {
    return this.datoService
      .get$<{ menu: DatoMenu }>(
        gql`
          ${ArticleFragment}
          query getNavigation {
            menu(locale: ${locale})  {
              id
              locale @client
              menuItems {
                type: __typename
                ...on NavigationItemRecord {
                  type: __typename
                  id
                  title
                  route: navigationLink
                  queryParams
                }
                ... on DynamicMenuItemRecord {
                  id
                  title
                  seeMoreCta {
                    id
                    type: __typename
                    title: linkText
                    route
                    style
                    fragment
                    url
                    queryParams
                  }
                  type: __typename
                  links {
                    ... on RegularLinkRecord {
                      id
                      type: __typename
                      title: linkText
                      route
                      style
                      fragment
                      url
                      queryParams
                    }
                    ... on MenuEntryRecord {
                      id
                      title
                      style
                      articles {
                        ...Article
                      }
                      seeMoreCta {
                        id
                        type: __typename
                        title: linkText
                        route
                        style
                        fragment
                        url
                        queryParams
                      }
                      type: __typename
                      menuItems {
                          ... on RegularLinkRecord {
                            id
                            type: __typename
                            title: linkText
                            route
                            style
                            fragment
                            url
                            queryParams
                          }
                          ... on ProductRecord {
                            id
                            type: __typename
                            title
                            sku
                            slug
                          }
                        }
                    }
                  }
                }
              }
              meta: _seoMetaTags {
                content
                attributes
                tag
              }
            }
          }
        `,
        disableCache,
        { locale }
      )
      .pipe(
        map((res) => {
          return res.menu;
        })
      );
  }
}
