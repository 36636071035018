import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { BottomSheetAndDialog } from '@shared/components/bottom-sheet/bottom-sheet-and-dialog';
import { BottomSheetAndDialogRef } from '@shared/components/bottom-sheet/bottom-sheet-and-dialog-ref';
import { Observable } from 'rxjs';
import { CookieCategory } from '@shared/modules/cookie-policy/interfaces/cookie.interface';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CookiePolicyService } from './cookie-policy.service';
import { CookiePolicyDialogComponent } from './components/cookie-policy-dialog/cookie-policy-dialog.component';

@UntilDestroy()
@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookiePolicyComponent implements OnDestroy {
  public vm$: Observable<CookieCategory[]> = this.cookiePolicyService.vm$;
  private readonly bottomSheetRef?: BottomSheetAndDialogRef;

  constructor(
    private readonly cookiePolicyService: CookiePolicyService,
    private readonly dialog: BottomSheetAndDialog,
    private readonly translateService: TranslateService
  ) {
    if (!this.cookiePolicyService.consented) {
      this.translateService.onLangChange.pipe(untilDestroyed(this)).subscribe(() => {
        this.getCookiesList();
      });
      this.getCookiesList();
    }
  }

  public onAcceptClick(): void {
    this.cookiePolicyService.acceptAll();
  }

  public onPreferencesClick(): void {
    this.dialog.open<CookiePolicyDialogComponent, Observable<CookieCategory[]>>(CookiePolicyDialogComponent, {
      backdropClass: 'dd-blur-backdrop',
      data$: this.vm$
    });
  }

  public ngOnDestroy(): void {
    this.bottomSheetRef?.dismiss();
  }

  private getCookiesList(): void {
    this.cookiePolicyService.getCookiesList();
  }
}
