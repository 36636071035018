import { Platform } from '@angular/cdk/platform';
import { ChangeDetectionStrategy, Component, createNgModule, Injector, OnInit, ViewContainerRef } from '@angular/core';
import { HomeContentModule } from '@shop/pages/home/home-content/home-content.module';
import { HomeService } from '@shop/pages/home/home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit {
  public readonly isSafari = this.platform.SAFARI;
  public heroVM$ = this.homeService.heroVM$;

  constructor(
    private readonly platform: Platform,
    private readonly vcr: ViewContainerRef,
    private readonly injector: Injector,
    private readonly homeService: HomeService
  ) {}

  public ngOnInit(): void {
    this.homeService.getHeroData();
    this.loadContent();
  }

  public loadContent(): void {
    import('./home-content/home-content.module').then((m) => {
      this.homeService.getHomepageData();
      const lazyLoadedModule = m.HomeContentModule;
      const moduleRef = createNgModule<HomeContentModule>(lazyLoadedModule, this.injector);
      this.vcr.createComponent(moduleRef.instance.getHomeContent());
    });
  }
}
