import { createReducer, on } from '@ngrx/store';
import { LoadableStateReducerHelper } from '@shared/helpers/loadable-state-reducer.helper';
import { Loadable } from '@shared/interfaces/loadable.interface';
import { ApiError } from 'src/domain/api-error';
import { ReviewStarsConfig } from '../../review-stars/review-stars-config.interface';
import { getStoreReviews, getStoreReviewsError, getStoreReviewsSuccess } from './reviews.actions';

export const featureKeyReviews = 'reviews';

export interface FeatureStateReviews {
  storeReviews: Loadable<ReviewStarsConfig, ApiError>;
}

export const initialState: FeatureStateReviews = {
  storeReviews: { isLoading: false }
};

export const reducerReviews = createReducer(
  initialState,
  on(
    getStoreReviews,
    (state): FeatureStateReviews => ({
      ...state,
      storeReviews: LoadableStateReducerHelper.startLoad(state.storeReviews)
    })
  ),
  on(
    getStoreReviewsSuccess,
    (state, { storeReviews }): FeatureStateReviews => ({
      ...state,
      storeReviews: LoadableStateReducerHelper.loadSuccess(storeReviews)
    })
  ),
  on(
    getStoreReviewsError,
    (state, { error }): FeatureStateReviews => ({
      ...state,
      storeReviews: LoadableStateReducerHelper.loadError(state.storeReviews, error)
    })
  )
);
