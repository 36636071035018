import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { BottomSheetAndDialogCloserDirective } from './bottom-sheet-and-dialog-closer.directive';
import { BottomSheetAndDialogContainerComponent } from './bottom-sheet-and-dialog-container';

@NgModule({
  imports: [OverlayModule, PortalModule],
  exports: [BottomSheetAndDialogContainerComponent, BottomSheetAndDialogCloserDirective],
  declarations: [BottomSheetAndDialogContainerComponent, BottomSheetAndDialogCloserDirective]
})
export class BottomSheetAndDialogModule {}
