import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { RouteSegment } from '@shared/enums/route-segment.enum';

import { CartService } from '@shop/modules/cart/cart.service';
import { catchError, filter, Observable, of, switchMap, withLatestFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckoutGuard {
  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly cartService: CartService
  ) {
    this.cartService.getCart();
  }

  public canLoad(): Observable<boolean | UrlTree> {
    return this.getGuardResult$();
  }

  public canActivate(): Observable<boolean | UrlTree> {
    return this.getGuardResult$();
  }

  private getGuardResult$(): Observable<boolean | UrlTree> {
    const urlTree = this.router.createUrlTree([RouteSegment.Root]);

    return this.getLoadedCart$().pipe(
      switchMap((result) => of(result ? true : urlTree)),
      catchError(() => of(urlTree))
    );
  }

  private getLoadedCart$(): Observable<boolean> {
    const cart$ = this.cartService.cart$;
    const isLoading$ = this.cartService.isLoading$;

    return cart$.pipe(
      withLatestFrom(isLoading$),
      filter(([_, isLoading]) => isLoading === false),
      switchMap(([cart]) => {
        if (cart && cart.cartItems.length > 0) {
          return of(true);
        }
        return of(false);
      })
    );
  }
}
