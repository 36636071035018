import { Injectable } from '@angular/core';
import { DatoService } from '@core/services/dato/dato.service';
import { gql } from '@apollo/client';
import { map, Observable } from 'rxjs';
import { PageService } from '@shared/services/page.service';
import { TestProcess } from '@core/services/dato/interfaces/test-process.interface';

@Injectable({
  providedIn: 'root'
})
export class DatoTestProcessDataService {
  constructor(
    private readonly datoService: DatoService,
    private readonly pageService: PageService
  ) {}

  public getTestProcessData$(locale: string = 'en', disableCache = false): Observable<TestProcess> {
    return this.datoService
      .get$<{ testProcess: TestProcess }>(
        gql`
          query getTestProcess {
            testProcess: homepage(locale: ${locale}) {
              testProcessTitle
              testProcess {
                title
                content
                icon
              }
              testProcessCta {
                ctaTitle
                route
              }
            }
          }
        `,
        disableCache,
        { locale }
      )
      .pipe(
        map((res) => {
          return res.testProcess;
        })
      );
  }
}
