import { CookieCategory } from '@shared/modules/cookie-policy/interfaces/cookie.interface';
import { CookieType } from '@shared/modules/cookie-policy/enums/cookie-type.enum';
import { CookiesList } from '../cookies/cookies-list';
import DEFAULT_COOKIES_DATA from '../../app/shared/modules/cookie-policy/data/cookies.json';

export const mapCookiesToCookieCategories = (cookies: CookiesList): CookieCategory[] => {
  const defaultCookies: CookieCategory[] = DEFAULT_COOKIES_DATA as CookieCategory[];

  return defaultCookies.map((cookieCategory): CookieCategory => {
    return mapCookieTypeToCookieList(cookieCategory.type, cookieCategory, cookies);
  });
};

const mapCookieTypeToCookieList = (cookieType: CookieType, cookieCategory: CookieCategory, cookiesList: CookiesList): CookieCategory => {
  switch (cookieType) {
    case CookieType.Analytics: {
      return {
        ...cookieCategory,
        cookies: cookiesList.statisticalCookiesList,
        description: cookiesList.statisticalCookiesDescription
      };
    }
    case CookieType.Essential: {
      return {
        ...cookieCategory,
        cookies: cookiesList.strictlyNecessaryCookiesList,
        description: cookiesList.strictlyNecessaryCookiesDescription
      };
    }
    case CookieType.Preferential: {
      return {
        ...cookieCategory,
        cookies: cookiesList.advertisingCookiesList,
        description: cookiesList.advertisingCookiesDescription
      };
    }
  }
};
