import { gql } from '@apollo/client';

export const CookiesListItemFragment = gql`
  fragment CookiesListItem on CookiesListItemRecord {
    id
    locale @client
    cookieName
    cookieDescription
  }
`;
