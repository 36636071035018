import { CdkScrollable, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';
import { InjectionToken, TemplateRef, ViewContainerRef } from '@angular/core';

export const DROPDOWN_DATA = new InjectionToken<unknown>('DropdownData');

const DEFAULT_Y_OFFSET = 8;

export class DropdownConfig<D = unknown> {
  public viewContainerRef?: ViewContainerRef;
  public componentOrTemplateRef?: ComponentType<unknown> | TemplateRef<unknown>;
  public data?: D | null = null;
  public data$?: D | null = null;
  public scrollStrategy?: 'block' | 'reposition' | 'noop' | 'close' = 'reposition';
  public hasBackdrop?: boolean = true;
  public backdropClass?: string | string[] = 'dd-transparent-backdrop';
  public disableClose?: boolean = false;
  public ariaLabel?: string | null = null;
  public originX?: 'end' | 'start' | 'center' = 'end';
  public originY?: 'bottom' | 'top' | 'center' = 'bottom';
  public overlayX?: 'end' | 'start' | 'center' = 'end';
  public overlayY?: 'bottom' | 'top' | 'center' = 'top';
  public panelClass?: string | string[];
  public overlayPanelClass?: string | string[];
  public offsetX? = 0;
  public offsetY? = DEFAULT_Y_OFFSET;
  public marginTop?: number = 0;
  /**
   * Trigger element to prevent dialog from reopening on close
   */
  public triggerElement?: HTMLElement;
  public minWith?: number;
  public maxWidth?: number;
  public maxHeight?: number;
  public width?: number;
  public endPositionStrategy?: boolean = false;
  public positionStrategy?: PositionStrategy;
  public disposeOnNavigation?: boolean;
  public disposeOnOutsideClick?: boolean = false;
  public lockPosition?: boolean = false;

  public scrollableContainers?: CdkScrollable[];
}
