import { ComponentStore } from '@ngrx/component-store';
import { Signal } from '@angular/core';

export abstract class DdComponentStore<TState extends object, TViewModel> extends ComponentStore<TState> {
  // eslint-disable-next-line unicorn/consistent-function-scoping
  public readonly viewModel: Signal<TViewModel> = this.selectSignal((state) => this.mapStateToViewModel(state));

  protected constructor(initialState: TState) {
    super(initialState);
  }

  // Initialization logic should be invoked by the Component's onInit method
  public abstract init(): void;
  protected abstract mapStateToViewModel(state: TState): TViewModel;
}
