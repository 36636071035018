import { gql } from '@apollo/client';
import { ProductFragment } from '@core/services/dato/fragments/product.fragment';

export const ArticleFragment = gql`
  ${ProductFragment}
  fragment Article on ArticleRecord {
    id
    locale @client
    articleContent
    slug
    title
    breadcrumb
    createdAt
    readingTime
    relatedProductIntroduction
    relatedProductSubtitle
    articleImage {
      id
      title
      format
      url
      responsiveImage(imgixParams: { fit: crop, h: 416, w: 1200, crop: focalpoint }) {
        src
        srcSet
        sizes
        webpSrcSet
      }
    }
    articleThumbnailImage {
      id
      title
      format
      url
      responsiveImage(imgixParams: { fit: crop, w: 270, h: 270, crop: focalpoint }, sizes: "(max-width: 992px) 270px, 374px") {
        src
        srcSet
        sizes
        webpSrcSet
      }
    }
    author {
      id
      firstName
      lastName
      authorImage {
        id
        title
        format
        url
      }
    }
    labels {
      id
      title
      locale @client
      color {
        hex
      }
    }
    relatedProduct {
      ...Product
      images: productImages {
        url
        alt
        responsiveImage(imgixParams: { fit: crop, w: 300, crop: focalpoint, fm: webp }) {
          src
          srcSet
          sizes
          webpSrcSet
        }
      }
    }
  }
`;
