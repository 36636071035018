<svg width="322" height="258" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M164.716 9.462c-41.142-2.955-27.744 52.003-63.163 58.525-58.729 10.815-99.678 35.13-90.678 84.153 13.096 71.337 137.956 39.209 178.167 2.721 52.119-47.293 16.816-142.443-24.326-145.399Z"
    fill="#FFE5CC"
  />
  <ellipse cx="255.285" cy="79.563" rx="55.734" ry="55.888" fill="#98EAAE" />
  <path
    d="M279.359 28.484c-9.825-8.695-22.731-13.972-36.865-13.972-30.781 0-55.734 25.022-55.734 55.888s24.953 55.888 55.734 55.888c30.781 0 55.733-25.022 55.733-55.888 0-8.31-1.809-16.198-5.053-23.287"
    stroke="#42649B"
    stroke-width="3"
    stroke-linecap="square"
  />
  <path d="M212.148 56.675 239.11 82.69a1.2 1.2 0 0 0 1.676-.01l63.694-62.83" stroke="#42649B" stroke-width="3" stroke-linecap="square" />
  <path
    d="M74.515 181.865C72.157 114.08 145.254 89.913 168.834 51.6c13.558 45.387 102.571 76.037 64.254 153.253-38.317 77.216-156.215 44.797-158.573-22.988Z"
    fill="#fff"
  />
  <path
    d="M67.676 181.865C65.318 114.08 138.415 89.913 161.994 51.6c13.559 45.387 102.571 76.037 64.254 153.253-38.317 77.216-156.214 44.797-158.572-22.988Z"
    stroke="#42649B"
    stroke-width="3"
  />
  <ellipse cx="4.154" cy="4.16" rx="4.154" ry="4.16" transform="rotate(-8.268 1033.943 -1139.894) skewX(-.028)" fill="#42649B" />
  <ellipse cx="4.154" cy="4.16" rx="4.154" ry="4.16" transform="rotate(-8.268 976.847 -728.94) skewX(-.028)" fill="#42649B" />
  <path d="M138.46 158.025c1.894 3.339 12.944 6.764 19.521 0" stroke="#42649B" stroke-width="3" />
</svg>
