/* eslint-disable @cspell/spellchecker */
export enum TagEvent {
  ConsentUpdate = 'consent_update',
  UserIdSet = 'user_id_set',
  PageView = 'page_view',
  ViewCart = 'view_cart',
  AddPaymentInfo = 'add_payment_info',
  BeginCheckout = 'begin_checkout',
  FooterMenuClick = 'footer_menu_click',
  HeaderMenuClick = 'header_menu_click',
  SocialClick = 'social_click',
  ArrowDownClick = 'arrow_down_click',
  CustomerStoriesScrollClick = 'customer_stories_scroll_click',
  FaqOpen = 'faq_open',
  FaqClose = 'faq_close',
  NewsletterFormSent = 'newsletter_form_sent',
  NewsletterFormError = 'newsletter_form_error',
  ViewItem = 'view_item',
  SelectItem = 'select_item',
  ProductImageSlide = 'product_image_slide',
  ContactSent = 'contact_sent',
  ContactError = 'contact_error',
  Login = 'login',
  LoginCodeResent = 'login_code_resent',
  LoginError = 'login_error',
  EmailOrPhoneTakenError = 'email_or_phone_taken_error',
  TestKitDataError = 'test_kit_data_error',
  UnknownRegistrationError = 'unknown_registration_error',
  AddToCart = 'add_to_cart',
  RemoveFromCart = 'remove_from_cart',
  PassResetSent = 'pass_reset_sent',
  PassReset = 'pass_reset_success',
  PassResetError = 'pass_reset_error',
  TabMenuClick = 'tab_menu_click',
  LatestTestResultsClick = 'latest_results_click',
  PreviousTestResultsClick = 'previous_results_click',
  PreviousTestResultsOpen = 'previous_results_open',
  PreviousTestResultsClose = 'previous_results_close',
  PreviousTestResultsPagination = 'previous_results_pagination',
  BiomarkerCodeClick = 'biomarker_code_click',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  PreviousResultsPagination = 'previous_results_pagination',
  OrderHistoryPagination = 'order_history_pagination',
  Registration = 'registration',
  RegisterByKit = 'register_by_kit',
  TestQuestionClick = 'test_question_click',
  Filters = 'filters'
}
