import { createReducer, on } from '@ngrx/store';
import { Loadable } from '@shared/interfaces/loadable.interface';
import { LoadableStateReducerHelper } from '@shared/helpers/loadable-state-reducer.helper';
import { CampaignVM } from '@shop/pages/campaign/campaign.vm';
import { DatoApiError } from '../../../../../domain/dato-api-error';
import * as Actions from './campaign.actions';

export const featureKeyCampaign = 'campaign';

export interface FeatureStateCampaign {
  campaignPage: Loadable<CampaignVM, DatoApiError>;
}

export interface AppState {
  [featureKeyCampaign]: FeatureStateCampaign;
}

export const initialStateAboutUs: FeatureStateCampaign = {
  campaignPage: { isLoading: false }
};

export const reducerCampaign = createReducer(
  initialStateAboutUs,
  on(Actions.getCampaignPage, (state) => ({
    ...state,
    campaignPage: LoadableStateReducerHelper.startLoad(state.campaignPage)
  })),
  on(Actions.getCampaignPageSuccess, (state, { campaignPage }) => ({
    ...state,
    campaignPage: LoadableStateReducerHelper.loadSuccess(campaignPage)
  })),
  on(Actions.getCampaignPageError, (state, { error }) => ({
    ...state,
    campaignPage: LoadableStateReducerHelper.loadError(state.campaignPage, error)
  }))
);
