import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MethodInvokerPipe } from './invoker.pipe';

@NgModule({
  declarations: [MethodInvokerPipe],
  imports: [CommonModule],
  exports: [MethodInvokerPipe]
})
export class MethodInvokerPipeModule {
  public static forRoot(): ModuleWithProviders<MethodInvokerPipeModule> {
    return {
      ngModule: MethodInvokerPipeModule,
      providers: [MethodInvokerPipe]
    };
  }
}
