import { ReviewStarsConfig } from '@shared/modules/review-stars/review-stars-config.interface';
import { KiyohPageReviewResponse } from '@shared/services/review-provider/kiyoh.interface';

export const mapToReviewStarsConfig = (storeReviews: KiyohPageReviewResponse): ReviewStarsConfig => {
  return {
    reviewCount: storeReviews.numberReviews,
    rating: storeReviews.averageRating,
    route: storeReviews.viewReviewUrl
  };
};
