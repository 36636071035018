import { VideoSource, VideoTrack } from '@shared/modules/video/video-player.interface';
import { DatoVideo, VideoSubtitle } from '@core/services/dato/interfaces/media-block.interface';
import { mapStringToLocale } from './map-language';

export const mapVideoSubtitleToTracks = (subtitles: VideoSubtitle[]): VideoTrack[] => {
  return subtitles.map((subtitle) => {
    return {
      url: subtitle.videoSubtitleFile.url,
      label: mapStringToLocale(subtitle.subtitleLocale),
      srclang: subtitle.subtitleLocale
    };
  });
};

export const mapVideoUrlToSource = (videos: DatoVideo[]): VideoSource[] => {
  return videos.map((video) => {
    return {
      url: video.url,
      type: video.format
    };
  });
};
