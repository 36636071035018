import { InjectionToken } from '@angular/core';
import { DEFAULT_ROUTER_FEATURENAME, routerReducer, RouterReducerState } from '@ngrx/router-store';
import { Action, ActionReducerMap, createFeatureSelector } from '@ngrx/store';

export interface State {
  [DEFAULT_ROUTER_FEATURENAME]: RouterReducerState;
}

export const reducer = new InjectionToken<ActionReducerMap<State, Action>>('Root reducers token', {
  factory: () => ({
    [DEFAULT_ROUTER_FEATURENAME]: routerReducer
  })
});

export const selectRouter = createFeatureSelector<RouterReducerState>(DEFAULT_ROUTER_FEATURENAME);
