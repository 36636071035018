<ng-container *ngrxLet="vm$ as vm">
  <div
    [@fabRevealAnimation]="isFab"
    class="cart-button-wrapper position-relative"
    [ngClass]="{
      'cart-fab': isFab,
      'sticky-opened': stickyOpened
    }"
  >
    <button
      *ngrxLet="breakpointService.downSm$; let downSm"
      #trigger
      ddButton
      class="cart-button"
      [type]="isFab ? 'fab' : 'icon'"
      [size]="isFab || downSm ? 'small' : 'normal'"
      [level]="isFab ? 'tertiary' : 'nav'"
      [class.opened]="isOpen$ | async"
      (click)="openCart(vm)"
    >
      <svg-icon key="shopping-cart" [fontSize]="isFab ? '32px' : '24px'"></svg-icon>
      <app-cta-badge @revealAnimation *ngIf="vm.itemCount" class="badge background-primary700 color-text100">
        <span [@valueAnimation]="vm.itemCount">{{ vm.itemCount }}</span>
      </app-cta-badge>
    </button>
  </div>
</ng-container>
