import { gql } from '@apollo/client';

export const CtaFragment = gql`
  fragment Cta on CtaRecord {
    id
    ctaTitle
    level
    route
    anchor
    queryParams
  }
`;
