import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  ElementRef,
  HostBinding,
  inject,
  OnDestroy,
  OnInit,
  signal,
  TemplateRef,
  ViewChild,
  WritableSignal
} from '@angular/core';
import { BreakpointService } from '@core/services/breakpoint.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DropdownConfig } from '@shared/components/dropdown/dropdown-trigger-config';
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter } from 'rxjs';
import { NavigationComponent } from '@shared/components/navigation/navigation.component';
import { RouteSegment } from '@shared/enums/route-segment.enum';
import { CartService } from '@shop/modules/cart/cart.service';
import { DropdownRef } from '@shared/components/dropdown/dropdown-ref';
import { DropdownService } from '@shared/components/dropdown/dropdown.service';
import { NavigationConfig } from '@shared/components/navigation/interfaces/navigation-config.interface';
import { TagEvent } from '@shared/enums/tags/tag-event.enum';
import { DynamicMenuEntry } from '@shared/components/dynamic-menu-item/dynamic-menu-item.interface';
import { DynamicMenuItemComponent } from '@shared/components/dynamic-menu-item/dynamic-menu-item.component';
import { LanguageService } from '@core/modules/language/language.service';
import { HeaderService } from './header.service';

const MOBILE_OFFSET = -1;
const DESKTOP_OFFSET = 33;

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnDestroy, OnInit {
  private readonly headerService = inject(HeaderService);
  private readonly languageService = inject(LanguageService);

  @ViewChild('trigger', { read: ElementRef }) public trigger!: ElementRef<HTMLElement>;
  @ViewChild('dropdownLanguagePicker', { read: TemplateRef }) public dropdownLanguagePicker!: TemplateRef<HTMLElement>;

  public readonly RouteSegment = RouteSegment;
  public navigationExpanded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public cartOpened$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public dropdownExpanded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public mockArticle = {
    id: '1',
    articleContent: 'This is a mock article content.',
    title: 'Mock Article',
    breadcrumb: 'Breadcrumb',
    excerpt: 'This is a mock excerpt.',
    articleImage: {
      id: '1',
      url: 'https://example.com/image.jpg',
      alt: 'Mock Image',
      title: 'Mock Image'
    },
    articleThumbnailImage: {
      id: '1',
      url: 'https://example.com/thumbnail.jpg',
      alt: 'Mock Thumbnail',
      title: 'Mock Thumbnail'
    },
    author: [
      {
        id: '1',
        firstName: 'John',
        lastName: 'Doe',
        authorImage: {
          id: '1',
          url: 'https://example.com/author.jpg',
          alt: 'Author Image',
          title: 'Author Image'
        }
      }
    ],
    labels: [
      {
        id: '1',
        title: 'Mock Label',
        color: {
          hex: '#FFFFFF'
        },
        content: 'Mock label content'
      }
    ],
    relatedProduct: [],
    relatedProductIntroduction: 'Related product introduction',
    relatedProductSubtitle: 'Related product subtitle',
    readingTime: 5,
    createdAt: new Date().toISOString(),
    slug: 'mock-article',
    meta: []
  };

  public readonly navigationItems = computed(() => this.headerService.viewModel().navigationItems);
  public readonly selectedItemId: WritableSignal<string | null> = signal(null);
  public readonly TagEvent = TagEvent;
  public dropdownRef?: DropdownRef;

  constructor(
    public readonly breakpointService: BreakpointService,
    private readonly cartService: CartService,
    private readonly dropdown: DropdownService,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.headerService.init();
  }

  @HostBinding('class.dropdown-open') get isDropdownOpen(): boolean {
    return this.navigationExpanded$.getValue();
  }

  @HostBinding('class.cart-open') get isCartOpen(): boolean {
    return this.cartOpened$.getValue();
  }

  @HostBinding('class.language-picker-open') get isLanguagePickerExpanded(): boolean {
    return this.dropdownExpanded$.getValue();
  }

  public get dropdownTrigger(): ElementRef<HTMLElement> {
    return this.trigger;
  }

  public get dropdownConfig(): DropdownConfig<NavigationConfig> {
    return {
      componentOrTemplateRef: NavigationComponent,
      data: {
        items: this.navigationItems(),
        showLanguagePicker: true,
        extraItems: [this.dropdownLanguagePicker],
        dropdownRef: this.dropdownRef
      },
      scrollStrategy: 'block',
      panelClass: ['shadow-normal', 'rounded-3', 'background-bg100', 'vw-100', 'w-100', 'overflow-hidden', 'dd-fullscreen-dropdown'],
      backdropClass: ['dd-blur-backdrop'],
      disposeOnOutsideClick: true,
      offsetY: this.breakpointService.upMd ? DESKTOP_OFFSET : MOBILE_OFFSET,
      endPositionStrategy: true,
      offsetX: 0
    };
  }

  public ngOnInit(): void {
    this.repositionDropdownOnBreakpointChange();
    this.subscribeToCartOpenState();
    this.subscribeToLanguageChange();
  }

  public ngOnDestroy(): void {
    this.navigationExpanded$.next(false);
    this.cartOpened$.next(false);
    this.dropdownExpanded$.next(false);
  }

  public toggleCartState(isOpen: boolean): void {
    this.cartOpened$.next(isOpen);
  }

  public setDropdownExpandedState(expanded: boolean): void {
    this.dropdownExpanded$.next(expanded);
  }

  public openDynamicMenu(result: { elementRef: ElementRef; menuItem: DynamicMenuEntry; preventClose?: boolean }): void {
    if (this.dropdownRef && this.navigationExpanded$.getValue() && !result.preventClose) {
      this.dropdownRef.dismiss();
    } else {
      this.dropdownRef?.dismiss();
      this.dropdownRef = this.dropdown.openDropdown(
        {
          componentOrTemplateRef: DynamicMenuItemComponent,
          scrollStrategy: 'block',
          data: result.menuItem,
          panelClass: ['shadow-normal', 'rounded-3', 'background-bg100'],
          disposeOnOutsideClick: true,
          offsetY: this.breakpointService.upMd ? DESKTOP_OFFSET : MOBILE_OFFSET,
          originX: 'start',
          overlayX: 'start',
          triggerElement: result.elementRef.nativeElement,
          disposeOnNavigation: true
        },
        result.elementRef
      );
      this.navigationExpanded$.next(true);
      this.selectedItemId.set(result.menuItem.id);
      this.dropdownRef.afterDismissed().subscribe(() => {
        this.navigationExpanded$.next(false);
        this.selectedItemId.set(null);
      });
    }
  }

  public openDropdown(): void {
    if (this.dropdownRef && this.navigationExpanded$.getValue()) {
      this.dropdownRef.dismiss();
    } else {
      this.dropdownRef = this.dropdown.openDropdown({ ...this.dropdownConfig, triggerElement: this.trigger.nativeElement }, this.trigger);
      this.navigationExpanded$.next(true);
      this.dropdownRef.afterDismissed().subscribe(() => {
        this.navigationExpanded$.next(false);
        this.cartService.toggleCartState(false);
      });
    }
  }

  private subscribeToCartOpenState(): void {
    this.cartService.isCartOpen$.pipe(untilDestroyed(this)).subscribe((isOpen) => {
      this.toggleCartState(isOpen);
      // We call markForCheck as change detection is not triggered when we toggle cart state if it happens outside the
      // component
      this.cdr.markForCheck();
    });
  }

  private subscribeToLanguageChange(): void {
    this.languageService.currentLanguage$.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe(() => {
      this.headerService.getNavigationItems();
    });
  }

  private repositionDropdownOnBreakpointChange(): void {
    combineLatest([this.breakpointService.downMd$, this.breakpointService.upMd$])
      .pipe(
        untilDestroyed(this),
        filter(() => !!this.trigger),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.dropdown.destroyDropdown();
        this.cdr.markForCheck();
      });
  }
}
