import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  Optional,
  Renderer2,
  Self,
  ViewChild
} from '@angular/core';
import { NgControl, Validators } from '@angular/forms';
import { ControlValueAccessor, FormControl } from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextareaComponent extends ControlValueAccessor<string> implements AfterContentInit, AfterViewInit {
  @Input() public label?: string;
  @Input() public hint?: string;
  @Input() public placeholder = '';
  @Input() public error?: string | null;
  @Input() public minLength?: number;
  @Input() public maxLength?: number;
  @Input() public resize: 'none' | 'vertical' | 'horizontal' | 'both' = 'none';
  @Input() public showOptionalLabel = false;
  @Input() public rows?: number;

  @ViewChild('textarea') public textareaElement!: ElementRef;

  public nativeInputValue = '';
  public disabled: boolean | null = null;
  public focused = false;
  public suffixWidth$ = new BehaviorSubject<string>('100%');

  public constructor(
    @Optional() @Self() private readonly ngControl: NgControl,
    private readonly cdr: ChangeDetectorRef,
    private readonly renderer: Renderer2,
    private readonly zone: NgZone
  ) {
    super();
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public get control(): FormControl<string | null> {
    return this.ngControl.control as FormControl<string | null>;
  }

  public get hasError(): boolean {
    return (this.control?.invalid || Object.values(this.control?.errors || []).length > 0) && !!this.control?.touched;
  }

  public get required(): boolean {
    return this.control.hasValidator(Validators.required);
  }

  @HostListener(':input')
  public onInput(): void {
    this.resizeElement();
  }

  public ngAfterContentInit(): void {
    this.control.touch$.subscribe((_) => this.cdr.markForCheck());
  }

  public ngAfterViewInit(): void {
    if (this.textareaElement) {
      this.resizeElement();
      this.listenToResizeEvents();
    }
    this.cdr.markForCheck();
  }

  public onValueChange(value: string): void {
    if (this.onChange) {
      this.onChange(value);
    }
  }

  public focusInput(): void {
    this.textareaElement?.nativeElement.focus();
  }

  public onBlur(): void {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  public writeValue(value: string | null): void {
    if (value === null) {
      this.nativeInputValue = '';
    } else if (this.nativeInputValue !== value) {
      this.nativeInputValue = value;
    }
    this.cdr.markForCheck();
  }

  private resizeElement(): void {
    const scrollHeight = this.textareaElement.nativeElement.scrollHeight;
    const borderWidth = Number.parseInt(getComputedStyle(this.textareaElement.nativeElement).getPropertyValue('border-width'), 10);
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    this.renderer.setStyle(this.textareaElement.nativeElement, 'height', `${scrollHeight + 2 * borderWidth}px`);
  }

  private recalculateSize(): void {
    const textareaWidth = this.textareaElement.nativeElement.offsetWidth;
    this.suffixWidth$.next(`${textareaWidth}px`);
  }

  private fromElementResize$(element: HTMLElement): Observable<void> {
    return new Observable<void>((subscriber: Subscriber<void>) => {
      const observer = new ResizeObserver(() => {
        subscriber.next();
      });
      observer.observe(element);

      return () => {
        observer.disconnect();
      };
    });
  }

  private listenToResizeEvents(): void {
    this.fromElementResize$(this.textareaElement.nativeElement)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.zone.run(() => {
          this.recalculateSize();
        });
      });
  }
}
