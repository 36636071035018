import { ApiSubscribeToInventoryResponse } from '@core/services/mock-models/subscribe-to-inventory.model';
import { createReducer, on } from '@ngrx/store';
import { LoadableStateReducerHelper } from '@shared/helpers/loadable-state-reducer.helper';
import { Loadable } from '@shared/interfaces/loadable.interface';
import { ApiError } from 'src/domain/api-error';
import { Cart } from '../../../../../domain/cart/cart';
import {
  addToCart,
  addToCartError,
  cartOperationSuccess,
  checkout,
  checkoutError,
  createCart,
  createCartError,
  createCartSuccess,
  getCart,
  getCartSuccess,
  resetCart,
  updateItemQuantityError
} from './cart.actions';

export const featureKeyCart = 'cart';

export interface FeatureStateCart {
  cart: Loadable<Cart>;
  cartId?: string;
  subscribedToStockNotification: Loadable<ApiSubscribeToInventoryResponse>;
  checkoutSuccess: Loadable<boolean, ApiError>;
}

export interface AppState {
  [featureKeyCart]: FeatureStateCart;
}

export const featureStateCart: FeatureStateCart = {
  cart: { isLoading: false },
  subscribedToStockNotification: { isLoading: false },
  checkoutSuccess: { data: false, isLoading: false }
};

export const reducerCart = createReducer(
  featureStateCart,
  on(
    checkout,
    (state): FeatureStateCart => ({
      ...state,
      checkoutSuccess: LoadableStateReducerHelper.startLoad(state.checkoutSuccess)
    })
  ),
  on(
    checkoutError,
    (state): FeatureStateCart => ({
      ...state,
      checkoutSuccess: LoadableStateReducerHelper.loadError(state.checkoutSuccess)
    })
  ),
  on(
    addToCart,
    (state): FeatureStateCart => ({
      ...state,
      cart: LoadableStateReducerHelper.startLoad(state.cart)
    })
  ),
  on(
    addToCartError,
    (state, { error }): FeatureStateCart => ({
      ...state,
      cart: LoadableStateReducerHelper.loadError(state.cart, error.message),
      cartId: state.cartId
    })
  ),
  on(
    updateItemQuantityError,
    // eslint-disable-next-line sonarjs/no-identical-functions
    (state, { error }): FeatureStateCart => ({
      ...state,
      cart: LoadableStateReducerHelper.loadError(state.cart, error.message),
      cartId: state.cartId
    })
  ),
  on(
    getCart,
    (state): FeatureStateCart => ({
      ...state,
      cart: LoadableStateReducerHelper.startLoad(state.cart)
    })
  ),
  on(
    getCartSuccess,
    (state, { cart }): FeatureStateCart => ({
      ...state,
      cart: LoadableStateReducerHelper.loadSuccess(cart),
      cartId: cart.id
    })
  ),
  on(
    createCart,
    (state): FeatureStateCart => ({
      ...state,
      cart: LoadableStateReducerHelper.startLoad(state.cart)
    })
  ),
  on(
    createCartSuccess,
    // eslint-disable-next-line sonarjs/no-identical-functions
    (state, { cart }): FeatureStateCart => ({
      ...state,
      cart: LoadableStateReducerHelper.loadSuccess(cart),
      cartId: cart.id
    })
  ),
  on(
    createCartError,
    // eslint-disable-next-line sonarjs/no-identical-functions
    (state, { error }): FeatureStateCart => ({
      ...state,
      cart: LoadableStateReducerHelper.loadError(state.cart, error.message),
      cartId: state.cartId
    })
  ),
  on(
    cartOperationSuccess,
    // eslint-disable-next-line sonarjs/no-identical-functions
    (state, { cart }): FeatureStateCart => {
      return {
        ...state,
        cart: LoadableStateReducerHelper.loadSuccess(cart),
        cartId: cart.id
      };
    }
  ),
  on(resetCart, (state): FeatureStateCart => {
    return {
      ...state,
      cartId: '-1'
    };
  })
);
