import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { CartVM } from '@shop/modules/cart/cart.vm';
import { CartItemResponse } from '@shop/modules/cart/cart.interface';
import { mapCartItem } from 'src/domain/mappers/map-cart';
import { featureKeyCart, FeatureStateCart } from './cart.reducer';

const selectCartFeature = createFeatureSelector<FeatureStateCart>(featureKeyCart);

export const selectCartId = createSelector(selectCartFeature, (state) => state.cartId ?? '-1');

export const selectCartResult = createSelector(selectCartFeature, (state) => {
  return state.cart.data!;
});

export const selectIsCartLoading = createSelector(selectCartFeature, (state) => !!state.cart?.isLoading);

export const selectCheckoutIsLoading = createSelector(selectCartFeature, (state) => !!state.checkoutSuccess?.isLoading);

export const selectCart = createSelector(selectCartResult, (cart): CartVM => {
  if (cart) {
    const cartItems: CartItemResponse[] = cart.items.map((cartItem): CartItemResponse => mapCartItem(cartItem));
    return {
      id: cart.id,
      cartItems,
      itemCount: cart.totalQuantity,
      total: cart.totalPrice
    };
  }
  return {
    id: '-1',
    cartItems: [],
    itemCount: 0,
    total: 0
  };
});

export const selectCartItem = (variantId: string): MemoizedSelector<object, CartItemResponse | undefined> => {
  return createSelector(selectCart, (cart) => cart.cartItems.find((item) => item.variantId === variantId));
};
