import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectProfile } from '@shared/store/profile/profile.selectors';
import { LoginFormVM } from '../components/login-form/models/login-form.vm';
import { featureKeyAuth, FeatureStateAuth } from './auth.reducer';

const selectAuthFeature = createFeatureSelector<FeatureStateAuth>(featureKeyAuth);

export const selectAuth = createSelector(selectAuthFeature, (state): LoginFormVM => {
  return {
    isLoading: !!state.isAuthenticated?.isLoading,
    error: state.isAuthenticated?.error,
    isAuthenticated: !!state.isAuthenticated?.data
  };
});

export const selectSecondFactorAuth = createSelector(selectAuthFeature, (state) => {
  return {
    isLoading: !!state.isSecondFactorAuthenticated?.isLoading,
    error: state.isSecondFactorAuthenticated?.error,
    secondFactorCodeError: state.secondFactorCodeRequested?.error,
    isAuthenticated: !!state.isSecondFactorAuthenticated?.data
  };
});

export const selectSecondFactorCodeRequest = createSelector(selectAuthFeature, (state) => {
  return {
    isLoading: !!state.secondFactorCodeRequested?.isLoading,
    error: state.secondFactorCodeRequested?.error,
    isCodeRequested: state.secondFactorCodeRequested?.data
  };
});

export const selectMagicTokenLoading = createSelector(selectAuthFeature, (state) => {
  return state.loggingInWithMagicToken.isLoading;
});

export const selectIsAuthenticated = createSelector(selectProfile, (profile) => {
  return !!profile.data;
});

export const selectInitialUrlBeforeLogin = createSelector(selectAuthFeature, (state: FeatureStateAuth) => state.initialUrlBeforeLogin);
