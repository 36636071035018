import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { KiyohPageReviewResponse } from './kiyoh.interface';
import { ReviewProviderConfiguration } from './review-provider-configuration';

@Injectable({
  providedIn: 'root'
})
export class KiyohReviewProviderService {
  constructor(
    @Inject(ReviewProviderConfiguration) private readonly config: ReviewProviderConfiguration,
    private readonly httpClient: HttpClient
  ) {}

  public getPageReviews$(): Observable<KiyohPageReviewResponse> {
    return this.httpClient.get<KiyohPageReviewResponse>(`${this.config.providerUrl}/external`, {
      params: {
        locationId: this.config.locationId,
        tenantId: this.config.tenantId
      },
      headers: {
        'X-Publication-Api-Token': this.config.providerToken,
        'Cache-Control': 'max-age=604800'
      }
    });
  }
}
