import { createReducer, on } from '@ngrx/store';
import { LoadableStateReducerHelper } from '@shared/helpers/loadable-state-reducer.helper';
import { Loadable } from '@shared/interfaces/loadable.interface';
import { ApiError } from 'src/domain/api-error';
import { NewsletterStatus } from 'src/domain/newsletter/newsletter-status';
import { DatoNewsletter } from '@core/services/dato/interfaces/newsletter.interface';
import { DatoApiError } from '../../../../domain/dato-api-error';
import {
  getNewsletterContent,
  getNewsletterContentError,
  getNewsletterContentSuccess,
  getSubscriptionStatus,
  getSubscriptionStatusError,
  getSubscriptionStatusSuccess,
  subscribeToNewsletter,
  subscribeToNewsletterError,
  subscribeToNewsletterReset,
  subscribeToNewsletterSuccess
} from './newsletter.actions';

export const featureKeyNewsletter = 'newsletter';

export interface FeatureStateNewsletter {
  subscribed: Loadable<NewsletterStatus, ApiError>;
  newsletterContent: Loadable<DatoNewsletter, DatoApiError>;
}

export interface AppState {
  [featureKeyNewsletter]: FeatureStateNewsletter;
}

export const featureStateNewsletter: FeatureStateNewsletter = {
  subscribed: { isLoading: false },
  newsletterContent: { isLoading: false }
};

export const reducerNewsletter = createReducer(
  featureStateNewsletter,
  on(subscribeToNewsletter, (state) => ({
    ...state,
    subscribed: LoadableStateReducerHelper.startLoad(state.subscribed)
  })),
  on(subscribeToNewsletterSuccess, (state) => ({
    ...state,
    subscribed: LoadableStateReducerHelper.loadSuccess({ isSubscribed: true })
  })),
  on(subscribeToNewsletterError, (state, { error }) => ({
    ...state,
    subscribed: LoadableStateReducerHelper.loadError(state.subscribed, error)
  })),
  on(getSubscriptionStatus, (state) => ({
    ...state,
    subscribed: LoadableStateReducerHelper.startLoad(state.subscribed)
  })),
  on(getSubscriptionStatusSuccess, (state, { status }) => ({
    ...state,
    subscribed: LoadableStateReducerHelper.loadSuccess({ ...status })
  })),
  on(getSubscriptionStatusError, (state, { error }) => ({
    ...state,
    subscribed: LoadableStateReducerHelper.loadError(state.subscribed, error)
  })),
  on(subscribeToNewsletterReset, (state) => ({
    ...state,
    subscribed: LoadableStateReducerHelper.loadSuccess({ isSubscribed: false })
  })),
  on(getNewsletterContent, (state) => ({
    ...state,
    newsletterContent: LoadableStateReducerHelper.startLoad(state.newsletterContent)
  })),
  on(getNewsletterContentSuccess, (state, { content }) => ({
    ...state,
    newsletterContent: LoadableStateReducerHelper.loadSuccess(content)
  })),
  on(getNewsletterContentError, (state, { error }) => ({
    ...state,
    newsletterContent: LoadableStateReducerHelper.loadError(state.newsletterContent, error)
  }))
);
