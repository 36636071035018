import { inject, Injectable } from '@angular/core';
import { DdComponentStore } from '@shared/utils/dd-component-store';
import { Store } from '@ngrx/store';
import { NavigationDataService } from '@core/services/dato/data-services/navigation-data.service';
import { DatoMenu, DatoMenuEntry, DatoMenuItem, DatoNavigationItem } from '@core/services/dato/interfaces/navigation.interface';
import { switchMap } from 'rxjs';
import { tapResponse } from '@ngrx/operators';
import { NavigationItem } from '@shared/components/navigation/interfaces/navigation-items.interface';
import {
  DynamicMenuEntry,
  DynamicMenuItemMultiple,
  DynamicMenuItemSingle,
  DynamicMenuItemType
} from '@shared/components/dynamic-menu-item/dynamic-menu-item.interface';
import { RouteSegment } from '@shared/enums/route-segment.enum';
import { DatoRegularLink } from '@core/services/dato/interfaces/regular-link.interface';
import { concatLatestFrom } from '@ngrx/effects';
import { LanguageService } from '@core/modules/language/language.service';
import { DatoFooterProductLink } from '@core/services/dato/interfaces/footer-product-link.interface';

export interface HeaderState {
  navigationItems: DatoMenu | null;
}

export interface HeaderViewModel {
  navigationItems: (NavigationItem | DynamicMenuEntry)[];
}

const initialState = {
  navigationItems: null
};

@Injectable({
  providedIn: 'root'
})
export class HeaderService extends DdComponentStore<HeaderState, HeaderViewModel> {
  private readonly store: Store = inject(Store);
  private readonly languageService = inject(LanguageService);
  private readonly navigationDataService: NavigationDataService = inject(NavigationDataService);

  public override init(): void {
    this.getNavigationItems();
  }

  constructor() {
    super(initialState);
  }

  protected override mapStateToViewModel(state: HeaderState): HeaderViewModel {
    return {
      navigationItems: state.navigationItems
        ? state.navigationItems?.menuItems.map((item) => {
            if (item.type === 'NavigationItemRecord') {
              const navigationItem = item as DatoNavigationItem;
              return {
                id: navigationItem.id,
                nameTranslationKey: navigationItem.title,
                type: 'NavigationItem',
                routerLink: [RouteSegment.Root, navigationItem.route],
                exactMatch: true
              } as NavigationItem;
            }
            const dynamicMenuEntry = item as DatoMenuItem;

            return {
              id: dynamicMenuEntry.id,
              type: 'DynamicMenuEntry',
              title: dynamicMenuEntry.title,
              seeMoreCta: dynamicMenuEntry.seeMoreCta,
              menuItems: dynamicMenuEntry.links?.map((link) => {
                if (link.type === 'RegularLinkRecord') {
                  const datoRegularLink = link as DatoRegularLink;
                  return {
                    id: datoRegularLink.id,
                    type: DynamicMenuItemType.Single,
                    title: datoRegularLink.title,
                    link: datoRegularLink.route,
                    queryParams: datoRegularLink.queryParams
                  } as DynamicMenuItemSingle;
                }

                const datoMenuEntry = link as DatoMenuEntry;
                return {
                  id: datoMenuEntry.id,
                  type: DynamicMenuItemType.Multiple,
                  title: datoMenuEntry.title,
                  seeMoreCta: datoMenuEntry.seeMoreCta,
                  articles: datoMenuEntry.articles,
                  links: datoMenuEntry.menuItems?.map((menuEntry) => {
                    if (menuEntry.type === 'RegularLinkRecord') {
                      return menuEntry as DatoRegularLink;
                    }

                    return { ...menuEntry, arrow: true } as DatoFooterProductLink;
                  })
                } as DynamicMenuItemMultiple;
              })
            } as DynamicMenuEntry;
          })
        : []
    };
  }

  public readonly getNavigationItems = this.effect(($) => {
    return $.pipe(
      concatLatestFrom(() => [this.languageService.currentLanguage$]),
      switchMap(([_, lang]) =>
        this.navigationDataService.getMenu$(lang).pipe(
          tapResponse(
            (response) => {
              this.patchState({ navigationItems: response });
            },
            () => console.error()
          )
        )
      )
    );
  });
}
