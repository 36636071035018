import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { getFooter, getFooterError, getFooterSuccess } from '@shop/modules/footer/store/footer.actions';
import { TranslateService } from '@ngx-translate/core';
import { DatoFooterDataService } from '@core/services/dato/data-services/footer-data.service';
import { ApolloError } from '@apollo/client';
import { mapDatoApiErrorResponse } from '../../../../../domain/mappers/dato-error';

@Injectable()
export class FooterEffects {
  // eslint-disable-next-line unicorn/consistent-function-scoping
  public sampleEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getFooter),
      switchMap(() => {
        return this.datoFooterDataService.getFooterData$(this.translateService.currentLang).pipe(
          map((footer) => {
            return getFooterSuccess({ footer });
          }),
          catchError((error: ApolloError) => {
            return of(getFooterError({ error: mapDatoApiErrorResponse(error) }));
          })
        );
      })
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly translateService: TranslateService,
    private readonly datoFooterDataService: DatoFooterDataService
  ) {}
}
