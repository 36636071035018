import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { mediaQueries } from './media-queries';

@Injectable({
  providedIn: 'root'
})
export class BreakpointService {
  public xs$ = this.observe$(mediaQueries.xs);
  public sm$ = this.observe$(mediaQueries.sm);
  public md$ = this.observe$(mediaQueries.md);
  public lg$ = this.observe$(mediaQueries.lg);
  public xl$ = this.observe$(mediaQueries.xl);
  public xxl$ = this.observe$(mediaQueries.xxl);
  public upXs$ = this.observe$(mediaQueries.upXs);
  public upSm$ = this.observe$(mediaQueries.upSm);
  public upMd$ = this.observe$(mediaQueries.upMd);
  public upLg$ = this.observe$(mediaQueries.upLg);
  public upXl$ = this.observe$(mediaQueries.upXl);
  public upXxl$ = this.observe$(mediaQueries.upXxl);
  public downXs$ = this.observe$(mediaQueries.downXs);
  public downSm$ = this.observe$(mediaQueries.downSm);
  public downMd$ = this.observe$(mediaQueries.downMd);
  public downLg$ = this.observe$(mediaQueries.downLg);
  public downXl$ = this.observe$(mediaQueries.downXl);
  public downXxl$ = this.observe$(mediaQueries.downXxl);

  constructor(private readonly breakpointObserver: BreakpointObserver) {}

  public get xs(): boolean {
    return this.doesMediaQueryMatch(mediaQueries.xs);
  }
  public get sm(): boolean {
    return this.doesMediaQueryMatch(mediaQueries.sm);
  }
  public get md(): boolean {
    return this.doesMediaQueryMatch(mediaQueries.md);
  }
  public get lg(): boolean {
    return this.doesMediaQueryMatch(mediaQueries.lg);
  }
  public get xl(): boolean {
    return this.doesMediaQueryMatch(mediaQueries.xl);
  }
  public get xxl(): boolean {
    return this.doesMediaQueryMatch(mediaQueries.xxl);
  }
  public get upXs(): boolean {
    return this.doesMediaQueryMatch(mediaQueries.upXs);
  }
  public get upSm(): boolean {
    return this.doesMediaQueryMatch(mediaQueries.upSm);
  }
  public get upMd(): boolean {
    return this.doesMediaQueryMatch(mediaQueries.upMd);
  }
  public get upLg(): boolean {
    return this.doesMediaQueryMatch(mediaQueries.upLg);
  }
  public get upXl(): boolean {
    return this.doesMediaQueryMatch(mediaQueries.upXl);
  }
  public get upXxl(): boolean {
    return this.doesMediaQueryMatch(mediaQueries.upXxl);
  }
  public get downXs(): boolean {
    return this.doesMediaQueryMatch(mediaQueries.downXs);
  }
  public get downSm(): boolean {
    return this.doesMediaQueryMatch(mediaQueries.downSm);
  }
  public get downMd(): boolean {
    return this.doesMediaQueryMatch(mediaQueries.downMd);
  }
  public get downLg(): boolean {
    return this.doesMediaQueryMatch(mediaQueries.downLg);
  }
  public get downXl(): boolean {
    return this.doesMediaQueryMatch(mediaQueries.downXl);
  }
  public get downXxl(): boolean {
    return this.doesMediaQueryMatch(mediaQueries.downXxl);
  }

  private observe$(mediaQuery: string | string[]): Observable<boolean> {
    return this.breakpointObserver.observe(mediaQuery).pipe(
      map((res) => {
        return !Object.keys(res.breakpoints).some((breakpointQuery) => {
          return !res.breakpoints[breakpointQuery];
        });
      }),
      distinctUntilChanged()
    );
  }

  private doesMediaQueryMatch(mediaQuery: string | string[]): boolean {
    if (Array.isArray(mediaQuery)) {
      return mediaQuery.every((query) => this.breakpointObserver.isMatched(query));
    }

    return this.breakpointObserver.isMatched(mediaQuery);
  }
}
