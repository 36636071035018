import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CookieListDataService } from '@core/services/dato/data-services/cookie-list-data.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { featureKeyCookiePolicy, reducerCookiePolicy } from '@shared/modules/cookie-policy/store/cookie-policy.reducer';
import { CookiePolicyEffects } from '@shared/modules/cookie-policy/store/cookie-policy.effects';
import { ExpandModule } from '../expand/expand.module';
import { CookiePolicyDialogComponent } from './components/cookie-policy-dialog/cookie-policy-dialog.component';
import { CookiePolicyImageComponent } from './components/cookie-policy-image/cookie-policy-image.component';
import { CookiePolicyComponent } from './cookie-policy.component';

@NgModule({
  declarations: [CookiePolicyComponent, CookiePolicyImageComponent, CookiePolicyDialogComponent],
  imports: [
    SharedModule,
    ReactiveFormsModule,

    RouterModule,
    ExpandModule,
    StoreModule.forFeature(featureKeyCookiePolicy, reducerCookiePolicy),
    EffectsModule.forFeature([CookiePolicyEffects])
  ],
  exports: [CookiePolicyComponent],
  providers: [CookieListDataService]
})
export class CookiePolicyModule {}
