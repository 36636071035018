import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  TrackByFunction,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { BreakpointService } from '@core/services/breakpoint.service';
import { TagPusherService } from '@core/services/tag-pusher.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BottomSheetAndDialog } from '@shared/components/bottom-sheet/bottom-sheet-and-dialog';
import { BottomSheetAndDialogRef } from '@shared/components/bottom-sheet/bottom-sheet-and-dialog-ref';
import { DropdownRef } from '@shared/components/dropdown/dropdown-ref';
import { DropdownConfig } from '@shared/components/dropdown/dropdown-trigger-config';
import { DropdownService } from '@shared/components/dropdown/dropdown.service';
import { RouteSegment } from '@shared/enums/route-segment.enum';
import { TagEvent } from '@shared/enums/tags/tag-event.enum';
import { badgeRevealAnimation } from '@shared/modules/cta-badge/animations/cta-badge.animations';
import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs';
import { CartItem } from 'src/domain/cart/cart-item';
import { UpdateCartItemRequest } from 'src/domain/cart/update-cart-item-request';
import { mapCartItemToEcommerceItem } from 'src/domain/mappers/map-cart';
import { WINDOW } from '@ng-web-apis/common';
import { fabRevealAnimation, valueAnimation } from './animations/cart-animations';
import { CartService } from './cart.service';
import { CartVM } from './cart.vm';
import { CartDropdownComponent } from './components/cart-dropdown/cart-dropdown.component';

@UntilDestroy()
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fabRevealAnimation, valueAnimation, badgeRevealAnimation],
  providers: [DropdownService]
})
export class CartComponent implements OnInit, OnDestroy {
  @Input() public isFab = false;
  @Input() public stickyOpened = false;
  @ViewChild('trigger', { read: ElementRef }) private readonly trigger!: ElementRef<HTMLElement>;
  public isOpen$ = new BehaviorSubject<boolean>(false);
  public vm$ = this.cartService.cart$;
  public TagEvent = TagEvent;
  public readonly RouteSegment = RouteSegment;
  private bottomSheetRef?: BottomSheetAndDialogRef;
  private dropdownRef?: DropdownRef;

  constructor(
    public readonly breakpointService: BreakpointService,
    private readonly vcr: ViewContainerRef,
    private readonly cartService: CartService,
    private readonly bottomSheet: BottomSheetAndDialog,
    private readonly cdr: ChangeDetectorRef,
    private readonly dropdown: DropdownService,
    private readonly tagPusherService: TagPusherService,
    @Inject(WINDOW) private readonly window: Window
  ) {}

  public get dropdownTrigger(): ElementRef<HTMLElement> {
    return this.trigger;
  }

  public get dropdownConfig(): DropdownConfig<Observable<CartVM>> {
    const downMd = this.breakpointService.downMd;
    return {
      componentOrTemplateRef: CartDropdownComponent,
      viewContainerRef: this.vcr,
      backdropClass: ['dd-blur-backdrop'],
      scrollStrategy: 'block',
      disposeOnOutsideClick: true,
      overlayX: 'center',
      overlayY: 'center',
      panelClass: ['d-flex', 'shadow-normal', 'rounded-3', 'background-bg100', 'dd-fullscreen-dropdown'],
      overlayPanelClass: ['dd-fullscreen-overlay-lg'],
      offsetY: 0,
      endPositionStrategy: downMd
    };
  }

  public trackById: TrackByFunction<CartItem> = (_, item) => item.id;

  public ngOnInit(): void {
    this.subscribeToScreenSizeChanges();
    this.subscribeToOpenCartRequests();
  }

  public ngOnDestroy(): void {
    this.closeCart();
  }

  public updateQuantity(updateCartItemRequest: UpdateCartItemRequest): void {
    this.cartService.updateItemQuantity(updateCartItemRequest);
  }

  public closeCart(): void {
    if (this.isOpen$.getValue()) {
      this.dropdownRef?.dismiss();
    } else {
      this.bottomSheetRef?.dismiss();
    }
  }

  public openCart(cart?: CartVM): void {
    this.cartService.toggleCartState(true);
    if (!this.isOpen$.getValue() && !!cart) {
      this.tagPusherService.pushTag({
        event: TagEvent.PageView,
        page_location: `${window.location.origin}/cart`
      });
      this.tagPusherService.pushTag({
        event: TagEvent.ViewCart,
        ecommerce: {
          currency: 'EUR',
          value: cart.total,
          items: cart.cartItems.map((cartItem) => mapCartItemToEcommerceItem(cartItem))
        }
      });
    }
    if (this.isFab) {
      this.openBottomSheet();
    } else {
      this.openDropdown();
    }
  }

  public openBottomSheet(): void {
    this.bottomSheetRef = this.bottomSheet.open(CartDropdownComponent, {
      backdropClass: 'dd-blur-backdrop',
      noPaddingWhileInBottomSheetMode: true,
      alwaysBottomSheet: true,
      restoreFocus: false,
      panelClass: ['d-flex', 'flex-column', 'mt-header'],
      overlayPanelClass: 'dd-fullscreen-overlay-lg'
    });
    this.bottomSheetRef.afterDismissed().subscribe(() => {
      this.isOpen$.next(false);
      this.cartService.toggleCartState(false);
    });
  }

  public openDropdown(): void {
    if (this.dropdownRef && this.isOpen$.getValue()) {
      this.closeCart();
    } else {
      this.dropdownRef = this.dropdown.openDropdown(
        { ...this.dropdownConfig, triggerElement: this.dropdownTrigger.nativeElement },
        this.dropdownTrigger
      );
      this.isOpen$.next(true);
      this.dropdownRef.afterDismissed().subscribe(() => {
        this.isOpen$.next(false);
        this.cartService.toggleCartState(false);
      });
    }
  }

  public subscribeToScreenSizeChanges(): void {
    this.breakpointService.downMd$.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe(() => {
      this.dropdown.updateDropdownPosition(this.dropdownConfig);
      this.cdr.markForCheck();
    });
    this.breakpointService.upMd$.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe((upMd) => {
      if (upMd && this.isOpen$.value) {
        this.bottomSheetRef?.dismiss();
      }
    });
  }

  public subscribeToOpenCartRequests(): void {
    this.cartService.openCart$.pipe(untilDestroyed(this)).subscribe(() => {
      this.openCart();
    });
  }
}
