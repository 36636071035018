import { ChangeDetectionStrategy, Component, Input, TrackByFunction } from '@angular/core';
import { RouteSegment } from '@shared/enums/route-segment.enum';
import { HeroVM } from '@shop/pages/home/components/hero/hero.vm';
import { BreakpointService } from '@core/services/breakpoint.service';
import { CTA } from '../../../../../../domain/cta';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeroComponent {
  @Input() public vm?: HeroVM;
  @Input() public isLoading!: boolean;
  public readonly RouteSegment = RouteSegment;

  constructor(public breakpointService: BreakpointService) {}

  public trackByFn: TrackByFunction<CTA> = (_: number, cta) => cta.id;
}
