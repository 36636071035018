import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BreakpointService } from '@core/services/breakpoint.service';
import { ProductItem } from '@shared/interfaces/product.interface';
import { CartService } from '@shop/modules/cart/cart.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-product-item-sticky',
  templateUrl: './product-item-sticky.component.html',
  styleUrls: ['./product-item-sticky.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductItemStickyComponent {
  @Input() public product!: ProductItem;

  constructor(
    public readonly breakpointService: BreakpointService,
    private readonly cartService: CartService
  ) {}

  public get noMoreItems$(): Observable<boolean> {
    return this.cartService.allItemsPutInCart$(this.product);
  }
}
