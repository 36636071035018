import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { filter, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { isNotUndefined } from '@shared/utils/not-undefined';
import { map } from 'rxjs/operators';
import { selectProduct } from '@shop/store/products.selectors';
import { getProduct } from '@shop/store/products.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
@UntilDestroy()
export class BreadcrumbProductNameResolver {
  constructor(
    private readonly store: Store,
    private readonly translateService: TranslateService
  ) {
    this.translateService.onLangChange.pipe(untilDestroyed(this)).subscribe(() => {
      return this.selectProduct$();
    });
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
    const handle = route.paramMap.get('productSku');
    this.store.dispatch(getProduct({ handle: handle ?? '' }));

    return this.selectProduct$();
  }

  private selectProduct$(): Observable<string> {
    return this.store.select(selectProduct).pipe(
      filter(isNotUndefined),
      // eslint-disable-next-line @ngrx/avoid-mapping-selectors
      map((productItem) => {
        return productItem.title;
      })
    );
  }
}
