<div class="layout-bg layout-top-bg-small"></div>
<div class="d-flex flex-column gap-2-5 align-items-center text-center mt-2 mb-10" *ngrxLet="profileVM$ as profile">
  <app-happy-illustration class="align-self-center mt-8"></app-happy-illustration>
  <h1 class="header-h6 header-h5-sm mb-0">{{ 'register-sample.success.title' | translate }}</h1>
  <div class="success-text-container">
    <p>{{ 'register-sample.success.text' | translate }}</p>
  </div>
  <div class="d-flex flex-column gap-2">
    <a
      ddButton
      size="large"
      class="font-weight-medium"
      rightIcon="arrow-right"
      [routerLink]="[RouteSegment.Root, RouteSegment.TestkitManual] | localizeUrl"
    >
      {{ 'register-sample.success.test-kit-manual-cta' | translate }}
    </a>
    <a
      ddButton
      size="large"
      class="font-weight-medium"
      [level]="'nav'"
      [routerLink]="
        [
          RouteSegment.Root,
          RouteSegment.BarcodeActivation,
          profile.personalDetails ? RouteSegment.BarcodeActivationExisting : RouteSegment.BarcodeActivationNew
        ] | localizeUrl
      "
    >
      {{
        (profile.personalDetails ? 'register-sample.success.add-another-cta' : 'register-sample.success.register-another-cta') | translate
      }}
    </a>
  </div>
</div>
