import { createReducer, on } from '@ngrx/store';
import { LoadableStateReducerHelper } from '@shared/helpers/loadable-state-reducer.helper';
import { Loadable } from '@shared/interfaces/loadable.interface';
import { getProfileError } from '@shared/store/profile/profile.actions';
import { registerAndCheckoutSuccess } from '@shop/pages/checkout/store/checkout.actions';
import { ApiError } from 'src/domain/api-error';
import { SecondFactorCodeRequestedState } from '../models/second-factor-code';
import {
  clearInitialUrl,
  login,
  loginError,
  loginSuccess,
  loginWithMagicToken,
  requestSecondFactorCode,
  requestSecondFactorCodeError,
  requestSecondFactorCodeSuccess,
  resetState,
  secondFactorAuthenticate,
  secondFactorAuthenticateError,
  secondFactorAuthenticateSuccess,
  storeInitialUrlBeforeLogin
} from './auth.actions';

export const featureKeyAuth = 'auth';

export interface FeatureStateAuth {
  isAuthenticated: Loadable<boolean, ApiError>;
  loggingInWithMagicToken: Loadable<boolean, ApiError>;
  isSecondFactorAuthenticated: Loadable<boolean, ApiError>;
  secondFactorCodeRequested: Loadable<SecondFactorCodeRequestedState, ApiError>;
  initialUrlBeforeLogin?: string;
}

export interface AppState {
  [featureKeyAuth]: FeatureStateAuth;
}

export const featureStateAuth: FeatureStateAuth = {
  isAuthenticated: { isLoading: false },
  loggingInWithMagicToken: { isLoading: false },
  isSecondFactorAuthenticated: { isLoading: false },
  secondFactorCodeRequested: { isLoading: false }
};

export const reducerAuth = createReducer(
  featureStateAuth,
  on(login, (state) => ({
    ...state,
    isAuthenticated: LoadableStateReducerHelper.startLoad(state.isAuthenticated)
  })),
  on(loginSuccess, (state) => ({
    ...state,
    isAuthenticated: LoadableStateReducerHelper.loadSuccess(true),
    loggingInWithMagicToken: LoadableStateReducerHelper.loadSuccess(true)
  })),
  on(loginError, (state, { error }) => ({
    ...state,
    isAuthenticated: LoadableStateReducerHelper.loadError(state.isAuthenticated, error),
    loggingInWithMagicToken: LoadableStateReducerHelper.loadError(state.loggingInWithMagicToken, error)
  })),
  on(secondFactorAuthenticate, (state) => ({
    ...state,
    isSecondFactorAuthenticated: LoadableStateReducerHelper.startLoad(state.isSecondFactorAuthenticated)
  })),
  on(secondFactorAuthenticateSuccess, (state) => ({
    ...state,
    isSecondFactorAuthenticated: LoadableStateReducerHelper.loadSuccess(true)
  })),
  on(secondFactorAuthenticateError, (state, { error }) => ({
    ...state,
    isSecondFactorAuthenticated: LoadableStateReducerHelper.loadError(state.isSecondFactorAuthenticated, error)
  })),
  on(requestSecondFactorCode, (state) => ({
    ...state,
    secondFactorCodeRequested: LoadableStateReducerHelper.startLoad(state.secondFactorCodeRequested)
  })),
  on(requestSecondFactorCodeSuccess, (state, { isResent }) => ({
    ...state,
    secondFactorCodeRequested: LoadableStateReducerHelper.loadSuccess({ codeRequested: true, isResent })
  })),
  on(requestSecondFactorCodeError, (state, { error }) => ({
    ...state,
    secondFactorCodeRequested: LoadableStateReducerHelper.loadError(state.secondFactorCodeRequested, error)
  })),
  on(registerAndCheckoutSuccess, (state) => ({
    ...state,
    isSecondFactorAuthenticated: LoadableStateReducerHelper.loadSuccess(false)
  })),
  on(getProfileError, (state) => ({
    ...state,
    isSecondFactorAuthenticated: LoadableStateReducerHelper.loadSuccess(false)
  })),
  on(loginWithMagicToken, (state) => ({
    ...state,
    loggingInWithMagicToken: LoadableStateReducerHelper.startLoad(state.loggingInWithMagicToken)
  })),
  on(
    storeInitialUrlBeforeLogin,
    (state, { initialUrl }): FeatureStateAuth => ({
      ...state,
      initialUrlBeforeLogin: initialUrl
    })
  ),
  on(
    clearInitialUrl,
    (state): FeatureStateAuth => ({
      ...state,
      initialUrlBeforeLogin: undefined
    })
  ),
  /*
   * We reset the state, however, we reset initialUrl only by calling its action in order to make sure it is kept
   * until the user logs in or closes the login dialog
   */
  on(
    resetState,
    (state): FeatureStateAuth => ({
      ...featureStateAuth,
      initialUrlBeforeLogin: state.initialUrlBeforeLogin
    })
  )
);
