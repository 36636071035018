/* eslint-disable rxjs/finnish */

import { Directionality } from '@angular/cdk/bidi';
import { Overlay, OverlayConfig, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, TemplatePortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  Injectable,
  Injector,
  Optional,
  SkipSelf,
  TemplateRef,
  InjectionToken,
  Inject,
  OnDestroy,
  StaticProvider,
  InjectFlags
} from '@angular/core';
import { BreakpointService } from '@core/services/breakpoint.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { of as observableOf } from 'rxjs';
import { BOTTOM_SHEET_AND_DIALOG_DATA, BottomSheetAndDialogConfig } from './bottom-sheet-and-dialog-config';
import { BottomSheetAndDialogContainerComponent } from './bottom-sheet-and-dialog-container';
import { BottomSheetAndDialogModule } from './bottom-sheet-and-dialog-module';
import { BottomSheetAndDialogRef } from './bottom-sheet-and-dialog-ref';

/** Injection token that can be used to specify default bottom sheet options. */
export const MAT_BOTTOM_SHEET_DEFAULT_OPTIONS = new InjectionToken<BottomSheetAndDialogConfig>('mat-bottom-sheet-default-options');

/**
 * Applies default options to the bottom sheet config.
 *
 * @param defaults Object containing the default values to which to fall back.
 * @param config The configuration to which the defaults will be applied.
 * @returns The new configuration object with defaults applied.
 */
const _applyConfigDefaults = (defaults: BottomSheetAndDialogConfig, config?: BottomSheetAndDialogConfig): BottomSheetAndDialogConfig => {
  return { ...defaults, ...config };
};

@UntilDestroy()
@Injectable({ providedIn: BottomSheetAndDialogModule })
export class BottomSheetAndDialog implements OnDestroy {
  private _bottomSheetRefAtThisLevel: BottomSheetAndDialogRef<unknown> | null = null;
  private _bottomSheetConfigAtThisLevel: BottomSheetAndDialogConfig<unknown> | null = null;
  private readonly mobileWidth = '100%';
  private readonly desktopWidth = 'auto';

  constructor(
    private readonly _overlay: Overlay,
    private readonly _injector: Injector,
    private readonly breakpointService: BreakpointService,
    @Optional() @SkipSelf() private readonly _parentBottomSheet: BottomSheetAndDialog,
    @Optional()
    @Inject(MAT_BOTTOM_SHEET_DEFAULT_OPTIONS)
    private readonly _defaultOptions?: BottomSheetAndDialogConfig
  ) {
    this.changePositioningStrategyOnBreakpointChange();
  }

  /** Reference to the currently opened bottom sheet. */
  public get _openedBottomSheetRef(): BottomSheetAndDialogRef<unknown> | null {
    const parent = this._parentBottomSheet;
    return parent ? parent._openedBottomSheetRef : this._bottomSheetRefAtThisLevel;
  }

  public set _openedBottomSheetRef(value: BottomSheetAndDialogRef<unknown> | null) {
    if (this._parentBottomSheet) {
      this._parentBottomSheet._openedBottomSheetRef = value;
    } else {
      this._bottomSheetRefAtThisLevel = value;
    }
  }

  /**
   * Opens a bottom sheet containing the given component.
   *
   * @param component Type of the component to load into the bottom sheet.
   * @param config Extra configuration options.
   * @returns Reference to the newly-opened bottom sheet.
   */
  public open<T, D = unknown, R = unknown>(
    component: ComponentType<T>,
    config?: BottomSheetAndDialogConfig<D>
  ): BottomSheetAndDialogRef<T, R>;

  /**
   * Opens a bottom sheet containing the given template.
   *
   * @param template TemplateRef to instantiate as the bottom sheet content.
   * @param config Extra configuration options.
   * @returns Reference to the newly-opened bottom sheet.
   */
  // eslint-disable-next-line @typescript-eslint/unified-signatures
  public open<T, D = unknown, R = unknown>(template: TemplateRef<T>, config?: BottomSheetAndDialogConfig<D>): BottomSheetAndDialogRef<T, R>;

  public open<T, D = unknown, R = unknown>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config?: BottomSheetAndDialogConfig<D>
  ): BottomSheetAndDialogRef<T, R> {
    const _config = _applyConfigDefaults(this._defaultOptions || new BottomSheetAndDialogConfig(), config) as BottomSheetAndDialogConfig<D>;
    const overlayRef = this._createOverlay(_config);
    const container = this._attachContainer(overlayRef, _config);
    container.config = _config;
    const ref = new BottomSheetAndDialogRef<T, R>(container, overlayRef);

    if (componentOrTemplateRef instanceof TemplateRef) {
      container.attachTemplatePortal(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        new TemplatePortal<T>(componentOrTemplateRef, null!, {
          $implicit: _config.data$ || _config.data,
          bottomSheetRef: ref
        } as unknown as T)
      );
    } else {
      const portal = new ComponentPortal(
        componentOrTemplateRef,
        undefined,
        this._createInjector(_config, ref as BottomSheetAndDialogRef<unknown, unknown>)
      );
      const contentRef = container.attachComponentPortal(portal);
      ref.instance = contentRef.instance;
    }

    // When the bottom sheet is dismissed, clear the reference to it.
    ref.afterDismissed().subscribe(() => {
      // Clear the bottom sheet ref if it hasn't already been replaced by a newer one.
      if (this._openedBottomSheetRef === ref) {
        this._openedBottomSheetRef = null;
        this._bottomSheetConfigAtThisLevel = null;
      }
    });

    if (this._openedBottomSheetRef) {
      // If a bottom sheet is already in view, dismiss it and enter the
      // new bottom sheet after exit animation is complete.
      this._openedBottomSheetRef.afterDismissed().subscribe(() => ref.containerInstance.enter());
      this._openedBottomSheetRef.dismiss();
    } else {
      // If no bottom sheet is in view, enter the new bottom sheet.
      ref.containerInstance.enter();
    }

    this._openedBottomSheetRef = ref as BottomSheetAndDialogRef<unknown, unknown>;
    this._bottomSheetConfigAtThisLevel = config ?? null;

    return ref;
  }

  /**
   * Dismisses the currently-visible bottom sheet.
   *
   * @param result Data to pass to the bottom sheet instance.
   */
  public dismiss<R = unknown>(result?: R): void {
    if (this._openedBottomSheetRef) {
      this._openedBottomSheetRef.dismiss(result);
    }
  }

  public ngOnDestroy(): void {
    if (this._bottomSheetRefAtThisLevel) {
      this._bottomSheetRefAtThisLevel.dismiss();
    }
  }

  /**
   * Attaches the bottom sheet container component to the overlay.
   */
  private _attachContainer(overlayRef: OverlayRef, config: BottomSheetAndDialogConfig): BottomSheetAndDialogContainerComponent {
    const userInjector = config && config.viewContainerRef && config.viewContainerRef.injector;
    const injector = Injector.create({
      parent: userInjector || this._injector,
      providers: [{ provide: BottomSheetAndDialogConfig, useValue: config }]
    });

    const containerPortal = new ComponentPortal(BottomSheetAndDialogContainerComponent, config.viewContainerRef, injector);
    const containerRef: ComponentRef<BottomSheetAndDialogContainerComponent> = overlayRef.attach(containerPortal);
    return containerRef.instance;
  }

  /**
   * Creates a new overlay and places it in the correct location.
   *
   * @param config The user-specified bottom sheet config.
   */
  private _createOverlay(config: BottomSheetAndDialogConfig): OverlayRef {
    const overlayConfig = new OverlayConfig({
      direction: config.direction,
      hasBackdrop: config.hasBackdrop,
      backdropClass: 'dd-bottom-sheet-backdrop',
      panelClass: config.overlayPanelClass,
      disposeOnNavigation: config.closeOnNavigation,
      maxWidth: '100%',
      width: this.getOverlayWidth(config),
      scrollStrategy: config.scrollStrategy || this._overlay.scrollStrategies.block(),
      positionStrategy: this.getOverlayPositionStrategy(config)
    });

    if (config.backdropClass) {
      overlayConfig.backdropClass = config.backdropClass;
    }

    return this._overlay.create(overlayConfig);
  }

  /**
   * Creates an injector to be used inside of a bottom sheet component.
   *
   * @param config Config that was used to create the bottom sheet.
   * @param bottomSheetRef Reference to the bottom sheet.
   */
  private _createInjector<T>(config: BottomSheetAndDialogConfig, bottomSheetRef: BottomSheetAndDialogRef<T>): Injector {
    const userInjector = config && config.viewContainerRef && config.viewContainerRef.injector;
    const providers: StaticProvider[] = [
      { provide: BottomSheetAndDialogRef, useValue: bottomSheetRef },
      { provide: BOTTOM_SHEET_AND_DIALOG_DATA, useValue: config.data$ || config.data }
    ];

    if (config.direction && (!userInjector || !userInjector.get<Directionality | null>(Directionality, null, InjectFlags.Optional))) {
      providers.push({
        provide: Directionality,
        useValue: { value: config.direction, change: observableOf() }
      });
    }

    return Injector.create({ parent: userInjector || this._injector, providers });
  }

  private changePositioningStrategyOnBreakpointChange(): void {
    this.breakpointService.upMd$.pipe(untilDestroyed(this)).subscribe(() => {
      this._bottomSheetRefAtThisLevel?._overlayRef.updateSize({
        width: this.getOverlayWidth(this._bottomSheetConfigAtThisLevel)
      });
      this._bottomSheetRefAtThisLevel?._overlayRef.updatePositionStrategy(
        this.getOverlayPositionStrategy(this._bottomSheetConfigAtThisLevel)
      );
    });
  }

  private getOverlayWidth(config?: BottomSheetAndDialogConfig | null): string {
    const shouldBeADialog = this.getDialogMode(config);

    return shouldBeADialog ? config?.dialogModeWidth || this.desktopWidth : this.mobileWidth;
  }

  private getOverlayPositionStrategy(config?: BottomSheetAndDialogConfig | null): PositionStrategy {
    const shouldBeADialog = this.getDialogMode(config);

    return shouldBeADialog ? this.getDialogPositionStrategy() : this.getBottomSheetPositioningStrategy();
  }

  private getDialogMode(config?: BottomSheetAndDialogConfig | null): boolean {
    return config?.alwaysDialog || (!config?.alwaysBottomSheet && this.breakpointService.upMd);
  }

  private getBottomSheetPositioningStrategy(): PositionStrategy {
    return this._overlay.position().global().centerHorizontally().bottom('0');
  }

  private getDialogPositionStrategy(): PositionStrategy {
    return this._overlay.position().global().centerHorizontally().centerVertically();
  }
}
