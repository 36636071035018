import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HeroVM } from '@shop/pages/home/components/hero/hero.vm';
import { HomeVM } from '../home.vm';
import { featureKeyHome, FeatureStateHome } from './home.reducer';

const selectHomeFeature = createFeatureSelector<FeatureStateHome>(featureKeyHome);

export const selectHomepage = createSelector(selectHomeFeature, (state) => state.homepage);
export const selectHero = createSelector(selectHomeFeature, (state) => state.hero);

export const selectHomepageVM = createSelector(selectHomepage, (homepage): HomeVM | undefined => {
  return homepage.data ?? undefined;
});
export const selectHeroVM = createSelector(selectHero, (hero): HeroVM | undefined => {
  return hero.data
    ? {
        ...hero.data,
        heroImage: {
          ...hero.data.heroImage,
          focalPoint: hero.data.heroImage.focalPoint
            ? {
                x: hero.data.heroImage.focalPoint.x * 100,
                y: hero.data.heroImage.focalPoint.y * 100
              }
            : undefined
        }
      }
    : undefined;
});
