import { createFeatureSelector, createSelector } from '@ngrx/store';
import { mapNewsletterContentToNewsletterVM } from '../../../../domain/mappers/map-newsletter-content';
import { featureKeyNewsletter, FeatureStateNewsletter } from './newsletter.reducer';

const selectNewsletterFeature = createFeatureSelector<FeatureStateNewsletter>(featureKeyNewsletter);

export const selectNewsletterSubscription = createSelector(selectNewsletterFeature, (state: FeatureStateNewsletter) => {
  return state.subscribed;
});

export const selectNewsletterContent = createSelector(selectNewsletterFeature, (state: FeatureStateNewsletter) => {
  return state.newsletterContent.data ? mapNewsletterContentToNewsletterVM(state.newsletterContent.data) : undefined;
});
