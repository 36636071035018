import { RegisterCustomerAndCheckoutRequest } from '@dd/shop-client-sdk';
import { createAction, props } from '@ngrx/store';
import { ApiError } from 'src/domain/api-error';
import { ProfileAndCheckout } from 'src/domain/profile/profile-and-checkout';

const feature = '[Checkout]';
export const registerAndCheckoutWithSubscription = createAction(
  `${feature} register and checkout`,
  props<{ registerAndCheckoutRequest: RegisterCustomerAndCheckoutRequest }>()
);
export const registerAndCheckout = createAction(
  `${feature} register and checkout`,
  props<{ registerAndCheckoutRequest: RegisterCustomerAndCheckoutRequest; subscribeToNewsletter: boolean }>()
);

export const registerAndCheckoutSuccess = createAction(
  `${feature} register and checkout success`,
  props<{ profileAndCheckout: ProfileAndCheckout }>()
);

export const registerAndCheckoutError = createAction(`${feature} register and checkout error`, props<{ error: ApiError }>());
