import { Injectable } from '@angular/core';
import { combineLatest, map, Observable, of, timeout } from 'rxjs';
import { Route, Routes } from '@angular/router';
import { DatoCampaignPageDataService } from './campaign-page-data.service';
import { DatoSecondCampaignDataService } from './second-campaign-data.service';

const API_TIMEOUT = 10_000;

@Injectable({
  providedIn: 'root'
})
export class DatoCampaignRoutesService {
  public campaignRoutes: Routes = [];
  constructor(
    private readonly datoCampaignPageDataService: DatoCampaignPageDataService,
    private readonly datoSecondCampaignDataService: DatoSecondCampaignDataService
  ) {}

  public fetchRoutes$(): Observable<Routes> {
    return combineLatest([
      this.datoCampaignPageDataService.getCampaignPageSlugs$().pipe(timeout({ each: API_TIMEOUT, with: () => of([]) })),
      this.datoSecondCampaignDataService.getCampaignPageSlugs$().pipe(timeout({ each: API_TIMEOUT, with: () => of([]) }))
    ]).pipe(
      map(([campaignPageSlugs, secondCampaignPageSlugs]) => {
        const mappedCampaignPageSlugs = campaignPageSlugs.map((slug): Route => {
          return {
            path: slug,
            data: { campaignBreadcrumb: true },
            loadChildren: () => import('@shop/pages/campaign/campaign.module').then((m) => m.CampaignModule)
          };
        });

        const mappedSecondCampaignPageSlugs = secondCampaignPageSlugs.map((slug): Route => {
          return {
            path: slug,
            loadChildren: () => import('@shop/pages/second-campaign/second-campaign.module').then((m) => m.SecondCampaignModule)
          };
        });

        this.campaignRoutes = [...mappedCampaignPageSlugs, ...mappedSecondCampaignPageSlugs];
        return this.campaignRoutes;
      })
    );
  }
}
