import { NgModule } from '@angular/core';
import { CtaBadgeModule } from '@shared/modules/cta-badge/cta-badge.module';
import { appCheckIcon } from '@icons/check';
import { SharedModule } from '@shared/shared.module';
import { provideSvgIcons } from '@ngneat/svg-icon';
import { AddToCartComponent } from './add-to-cart.component';

@NgModule({
  declarations: [AddToCartComponent],
  imports: [SharedModule, CtaBadgeModule],
  providers: [provideSvgIcons([appCheckIcon])],
  exports: [AddToCartComponent]
})
export class AddToCartModule {}
