import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  Optional,
  Self,
  ViewChild
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { ControlValueAccessor, FormControl } from '@ngneat/reactive-forms';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioComponent implements ControlValueAccessor, AfterContentInit, AfterViewInit {
  @Input() public name = '';
  @Input() public id: string | number = '';
  @Input() public value = '';
  @Input() public hint?: string;
  @Input() public error?: string;
  @Input() public size: 'small' | 'normal' = 'normal';

  @ViewChild('input') public input!: ElementRef<HTMLInputElement>;

  public onChange: ((_: unknown) => void) | undefined;
  public onTouched: (() => void) | undefined;

  public constructor(
    @Optional() @Self() public ngControl: NgControl,
    private readonly cdr: ChangeDetectorRef
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public get hasError(): boolean {
    return Boolean(this.control?.touched) && Boolean(this.control?.invalid) && Object.values(this.control?.errors || []).length > 0;
  }

  public get control(): FormControl<string | null> {
    return this.ngControl.control as FormControl<string | null>;
  }

  public ngAfterContentInit(): void {
    this.control.touch$.subscribe((_) => this.cdr.markForCheck());
  }

  public ngAfterViewInit(): void {
    this.input.nativeElement.value = this.value;
  }

  public onInputChange(): void {
    this.control.setValue(this.value);
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  public writeValue(): void {
    // The value of Radio Buttons never changes, but we check for changes to update the view.
    this.cdr.markForCheck();
  }

  public registerOnChange(function_: (_: unknown) => unknown): void {
    this.onChange = function_;
  }

  public registerOnTouched(function_: () => unknown): void {
    this.onTouched = function_;
  }

  public onLabelClick(event: Event): void {
    if (this.control.disabled) {
      return;
    }
    event.preventDefault();
    this.control.setValue(this.value);
  }
}
