import { ChangeProfilePasswordRequest, PersonalDetails } from '@dd/shop-client-sdk';
import { createAction, props } from '@ngrx/store';
import { ApiError } from 'src/domain/api-error';
import { MailingAddress, Profile } from 'src/domain/profile/profile';

const feature = '[Profile]';

export const getProfileIfNeeded = createAction(`${feature} get profile if needed`);

export const getProfile = createAction(`${feature} get profile`);
export const getProfileSuccess = createAction(`${feature} get profile success`, props<{ profile: Profile }>());
export const getProfileError = createAction(`${feature} get profile error`, props<{ error: ApiError }>());

export const updatePersonalDetails = createAction(`${feature} update personal details`, props<{ personalDetails: PersonalDetails }>());
export const updatePersonalDetailsSuccess = createAction(`${feature} update personal details success`, props<{ profile: Profile }>());
export const updatePersonalDetailsError = createAction(`${feature} update personal details error`, props<{ error: ApiError }>());

export const updateAddress = createAction(`${feature} update address`, props<{ address: MailingAddress; redirect: boolean }>());
export const updateAddressSuccess = createAction(`${feature} update address success`, props<{ profile: Profile }>());
export const updateAddressError = createAction(`${feature} update address error`, props<{ error: ApiError }>());

export const changePassword = createAction(`${feature} change password`, props<{ changePasswordRequest: ChangeProfilePasswordRequest }>());
export const changePasswordSuccess = createAction(`${feature} change password success`);
export const changePasswordError = createAction(`${feature} change password error`, props<{ error: ApiError }>());

export const resetState = createAction(`${feature} reset profile state`);
