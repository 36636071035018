<div class="cookie-policy color-text100">
  <div class="container d-flex flex-column justify-content-center align-items-center align-items-sm-start p-3">
    <div class="content">
      <span class="body-large-bold mb-1">{{ 'cookie-policy.title' | translate }}</span>
      <p class="body-small-regular mb-3">
        {{ 'cookie-policy.short-text' | translate }}
      </p>
      <div class="buttons d-flex gap-2">
        <button ddButton size="small" level="secondary" [inverse]="true" (click)="onPreferencesClick()">
          {{ 'cookie-policy.preferences' | translate }}
        </button>
        <button ddButton size="small" [inverse]="true" (click)="onAcceptClick()">
          {{ 'cookie-policy.accept' | translate }}
        </button>
      </div>
    </div>
    <app-cookie-policy-image class="image"></app-cookie-policy-image>
  </div>
</div>
