export const onlyNumberKeys = (event: KeyboardEvent, allowedChars = /\d/): void => {
  const allowedCtrlChars = /[acvx]/; // Allow copy, paste, cut, select all.
  const allowedOtherKeys = [
    'ArrowLeft',
    'ArrowUp',
    'ArrowRight',
    'ArrowDown',
    'Home',
    'End',
    'Insert',
    'Delete',
    'Backspace',
    'Tab',
    'Enter'
  ];

  if (
    !allowedChars.test(event.key) &&
    !(event.ctrlKey && allowedCtrlChars.test(event.key)) &&
    !(event.metaKey && allowedCtrlChars.test(event.key)) &&
    !allowedOtherKeys.includes(event.key)
  ) {
    event.preventDefault();
  }
};
