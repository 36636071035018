import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
  signal,
  TemplateRef,
  TrackByFunction,
  ViewEncapsulation
} from '@angular/core';
import { BreakpointService } from '@core/services/breakpoint.service';
import { DropdownRef } from '@shared/components/dropdown/dropdown-ref';
import { DROPDOWN_DATA } from '@shared/components/dropdown/dropdown-trigger-config';
import { RouteSegment } from '@shared/enums/route-segment.enum';
import { TagEvent } from '@shared/enums/tags/tag-event.enum';
import { LinkRecordType } from '@core/services/dato/interfaces/link-record-type.enum';
import { DynamicMenuEntry } from '../dynamic-menu-item/dynamic-menu-item.interface';
import { NavigationConfig } from './interfaces/navigation-config.interface';
import { NavigationItem } from './interfaces/navigation-items.interface';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class NavigationComponent {
  @Input() public items!: (NavigationItem | DynamicMenuEntry)[];
  @Input() public showLanguagePicker = false;
  @Input() public extraItems?: TemplateRef<HTMLElement>[];
  @Input() public selectedMenuItemId?: string | null;

  @Output() public readonly dynamicMenuOpen = new EventEmitter<{
    elementRef: ElementRef;
    menuItem: DynamicMenuEntry;
    preventClose?: boolean;
  }>();

  public readonly RouteSegment = RouteSegment;
  public readonly TagEvent = TagEvent;
  constructor(
    @Optional() private readonly dropdownRef: DropdownRef,
    @Optional() @Inject(DROPDOWN_DATA) public readonly config: NavigationConfig,
    private readonly destroyRef: DestroyRef,
    public readonly breakpointService: BreakpointService
  ) {
    if (config) {
      this.showLanguagePicker = config.showLanguagePicker;
      this.items = config.items;
      this.extraItems = config.extraItems;
    }
  }

  protected selectedMenuItem = signal<DynamicMenuEntry | null>(null);

  public trackById: TrackByFunction<NavigationItem> = (_, item) => item.id;

  public closeDropdown(): void {
    this.dropdownRef?.dismiss();
  }

  protected setSelectedMenuItem(menuItem?: DynamicMenuEntry): void {
    if (menuItem) {
      this.selectedMenuItem.set(menuItem);
    } else {
      this.selectedMenuItem.set(null);
    }
  }
  protected readonly LinkRecordType = LinkRecordType;

  protected setSelectedMenuItemWithEmission(event: { elementRef: ElementRef; menuItem: DynamicMenuEntry }, preventClose?: boolean): void {
    this.dynamicMenuOpen.emit({ ...event, preventClose });
  }
}
