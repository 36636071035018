import { TestStep } from '@shop/modules/test-process/test-step.interface';
import { TestProcess as DatoTestProcess } from '@core/services/dato/interfaces/test-process.interface';
import { Service } from '../homepage';
import { TestProcess } from '../test-process';

export const mapTestProcess = (testProcess: DatoTestProcess): TestProcess => {
  return {
    testProcessTitle: testProcess.testProcessTitle,
    testProcessCTA: testProcess.testProcessCta[0],
    testSteps: mapServicesToTestSteps(testProcess.testProcess)
  };
};

export const mapServicesToTestSteps = (testSteps: Service[]): TestStep[] => {
  return testSteps.map((testStep) => {
    return {
      title: testStep.title,
      icon: testStep.icon,
      description: testStep.content
    };
  });
};
