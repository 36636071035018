import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Product, ProductDataService } from '@dd/shop-client-sdk';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { catchError, combineLatest, filter, forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { mapApiErrorResponse } from 'src/domain/mappers/error';
import { DatoHomeDataService } from '@core/services/dato/data-services/home-data.service';
import { UserExperienceStory } from '@shop/pages/home/components/user-experience/user-experience-story.interface';
import { HomeVM } from '@shop/pages/home/home.vm';
import { ProductItem } from '@shared/interfaces/product.interface';
import { ApolloError } from '@apollo/client';
import { Homepage } from '../../../../../domain/homepage';
import { mapProductToProductItem } from '../../../../../domain/mappers/map-product';
import { mapVideoSubtitleToTracks, mapVideoUrlToSource } from '../../../../../domain/mappers/map-video';
import { mapDatoApiErrorResponse } from '../../../../../domain/mappers/dato-error';
import {
  getHeroData,
  getHeroDataError,
  getHeroDataSuccess,
  getHomepageData,
  getHomepageDataError,
  getHomepageDataSuccess
} from './home.actions';

const productCache: Map<string, Product> = new Map();

@Injectable()
export class HomeEffects {
  // eslint-disable-next-line unicorn/consistent-function-scoping
  public getHeroData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getHeroData),
      switchMap(() => {
        return this.datoHomeDataService.getHeroData$(this.translateService.currentLang).pipe(
          map((datoHero) => {
            return getHeroDataSuccess({
              hero: {
                id: datoHero.id,
                title: datoHero.heroTitle,
                content: datoHero.heroContent,
                cta: datoHero.heroCta,
                backgroundColor: datoHero.backgroundColor.hex,
                heroImage: datoHero.heroImage,
                heroBoxBackgroundColor: datoHero.heroBoxBackgroundColor.hex,
                introductionBlock: {
                  title: datoHero.introductionBlockTitle,
                  content: datoHero.introductionBlockContent
                }
              }
            });
          }),
          catchError((error: ApolloError) => of(getHeroDataError({ error: mapDatoApiErrorResponse(error) })))
        );
      })
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public getHomepageData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getHomepageData),
      switchMap(() => {
        return this.datoHomeDataService
          .getHomepageData$(this.translateService.currentLang)
          .pipe(switchMap((homepage) => this.mappedHomepage$(homepage)))
          .pipe(
            map((homepage) => {
              return getHomepageDataSuccess({ homepage });
            }),
            catchError((error: HttpErrorResponse) => {
              return of(getHomepageDataError({ error: mapApiErrorResponse(error) }));
            })
          );
      })
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly productDataService: ProductDataService,
    private readonly datoHomeDataService: DatoHomeDataService,
    private readonly translateService: TranslateService
  ) {}

  public mappedHomepage$(homepage: Homepage): Observable<HomeVM> {
    const getProductByHandle = (sku: string): Observable<Product> => {
      return productCache.has(sku) ? of(productCache.get(sku)!) : this.productDataService.getProductByHandle(sku);
    };
    return combineLatest([
      forkJoin(
        homepage.recommendedProducts
          .filter((productItem) => !!productItem.sku)
          .map((productItem) => {
            return getProductByHandle(productItem.sku).pipe(
              filter((product) => !!product),
              map((product): ProductItem => {
                productCache.set(product.sku, product);
                return mapProductToProductItem(productItem, product);
              })
            );
          })
      ),
      forkJoin(
        homepage.testimonials
          .filter((testimonial) => testimonial.product[0].sku!)
          .map((testimonial) => {
            return getProductByHandle(testimonial.product[0].sku).pipe(
              map((product): UserExperienceStory => {
                productCache.set(product.sku, product);

                return {
                  id: testimonial.id,
                  productSubtitle: testimonial.productSubtitle,
                  storyCustomerAge: testimonial.age,
                  backgroundImage: testimonial.testimonialImage.responsiveImage?.src ?? testimonial.testimonialImage.url,
                  productActionText: testimonial.productCta,
                  story: testimonial.content,
                  storyCustomerFirstName: testimonial.title,
                  product: mapProductToProductItem(testimonial.product[0], product)
                };
              })
            );
          })
      )
    ]).pipe(
      map(([recommendedProducts, userStories]): HomeVM => {
        const mediaBlock = homepage.mediaBlock[0];
        const video = mediaBlock.video;
        return {
          recommendedProductsBlock: {
            recommendedProductsTitle: homepage.recommendedProductsTitle,
            recommendedProducts,
            recommendedProductsCta: homepage.recommendedProductsCta[0]
          },
          userStories,
          servicesTitle: homepage.servicesTitle,
          services: [...homepage.services],
          mediaBlock: {
            ...mediaBlock,
            cta: mediaBlock.cta ? mediaBlock.cta[0] : undefined,
            video: video
              ? {
                  ...video,
                  videoThumbnail: video.videoFile.videoThumbnail,
                  videoFile: {
                    sources: mapVideoUrlToSource([video.videoFile]),
                    videoSubtitles: video.videoSubtitles ? mapVideoSubtitleToTracks(video.videoSubtitles) : []
                  }
                }
              : undefined
          },
          testProcess: {
            testProcessTitle: homepage.testProcessTitle,
            testProcessCTA: homepage.testProcessCta[0],
            testSteps: homepage.testProcess.map((testStep) => {
              return {
                title: testStep.title,
                icon: testStep.icon,
                description: testStep.content
              };
            })
          },
          newsletter: {
            title: homepage.newsletterTitle,
            content: homepage.newsletterContent,
            subscribedTitle: homepage.newsletterSubscribedTitle,
            subscribedContent: homepage.newsletterSubscribedContent
          },
          faq: []
        };
      })
    );
  }
}
