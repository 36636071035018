import { AfterViewInit, ChangeDetectionStrategy, Component, createNgModule, Injector, ViewChild, ViewContainerRef } from '@angular/core';
import { BreakpointService } from '@core/services/breakpoint.service';
import { slideDownAnimation } from '@shared/modules/cookie-policy/animations/cookie-policy.animations';
import { CookiePolicyService } from '@shared/modules/cookie-policy/cookie-policy.service';
import { fabRevealAnimation } from '@shop/modules/cart/animations/cart-animations';
import { slideUpAnimation } from '@shop/pages/available-tests/pages/product/animations/sticky-animations';
import { ShopService } from '@shop/services/shop.service';
import { BehaviorSubject } from 'rxjs';
import { PromoBarModule } from '@shop/modules/promo-bar/promo-bar.module';

@Component({
  selector: 'app-shop-layout',
  templateUrl: './shop-layout.component.html',
  styleUrls: ['./shop-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fabRevealAnimation, slideDownAnimation, slideUpAnimation]
})
export class ShopLayoutComponent implements AfterViewInit {
  @ViewChild('promoBar', { read: ViewContainerRef }) public promoBar!: ViewContainerRef;
  public readonly cookieConsentDone$ = this.cookiePolicyService.consentDone$;
  public readonly product$ = this.shopService.product$;
  public readonly scrolledPastAddToCartButton$ = this.shopService.scrolledPastAddToCartButton$;
  public stickySnappedToFooter$ = new BehaviorSubject<boolean>(false);

  constructor(
    public readonly breakpointService: BreakpointService,
    private readonly shopService: ShopService,
    private readonly injector: Injector,
    private readonly cookiePolicyService: CookiePolicyService
  ) {}

  public ngAfterViewInit(): void {
    this.loadContent();
  }

  public loadContent(): void {
    import('../modules/promo-bar/promo-bar.module').then((m) => {
      const lazyLoadedModule = m.PromoBarModule;
      const moduleRef = createNgModule<PromoBarModule>(lazyLoadedModule, this.injector);
      this.promoBar.createComponent(moduleRef.instance.getPromoBarComponent());
    });
  }

  public onFooterIntersection(intersections: IntersectionObserverEntry[]): void {
    this.stickySnappedToFooter$.next(intersections[0].isIntersecting);
  }
}
