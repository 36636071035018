import { FlexibleConnectedPositionStrategy, Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { ElementRef, Injectable, Injector, StaticProvider, TemplateRef } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TOAST_MESSAGE_DATA } from './toast-message.tokens';
import { ToastMessageConfig } from './toast-message-config.interface';
import { ToastMessageData } from './toast-message-data.interface';
import { ToastMessageRef } from './toast-message-ref';
import { ToastMessageType } from './toast-message-type.enum';
import { ToastMessageWrapperComponent } from './toast-message-wrapper.component';
import { ToastMessageComponent } from './toast-message.component';

@Injectable()
export class ToastMessageService {
  private readonly DEFAULT_DURATION = 5000;
  private readonly DEFAULT_CONFIG: ToastMessageConfig = {
    type: ToastMessageType.Success,
    duration: this.DEFAULT_DURATION,
    closeable: true,
    maxWidth: '100%',
    topOffset: '140px',
    panelClass: ['align-self-end']
  };

  private overlayRef?: OverlayRef;
  private openRef?: ToastMessageRef;
  private elementRef?: ElementRef;
  private positionStrategy?: FlexibleConnectedPositionStrategy;

  constructor(
    private readonly overlay: Overlay,
    private readonly injector: Injector,
    private readonly overlayPositionBuilder: OverlayPositionBuilder
  ) {}

  public registerAnchorElement(elementRef: ElementRef): void {
    if (this.elementRef) {
      this.updatePositionStrategy(elementRef);
    }
    this.elementRef = elementRef;
  }

  public open(content$: Observable<string> | TemplateRef<unknown>, config?: ToastMessageConfig): ToastMessageRef {
    const configWithDefaults = { ...this.DEFAULT_CONFIG, ...config };
    const overlayRef = this.createOverlay(configWithDefaults);
    const ref = new ToastMessageRef();
    const injector = this.createInjector(configWithDefaults, ref);
    const toastMessage = overlayRef.attach(new ComponentPortal(ToastMessageComponent, undefined, injector));

    if (content$ instanceof TemplateRef) {
      toastMessage.instance.attachTemplatePortal(new TemplatePortal(content$, toastMessage.instance.viewContainerRef));
    } else {
      const componentRef = toastMessage.instance.attachComponentPortal(new ComponentPortal(ToastMessageWrapperComponent));
      componentRef.instance.content$ = content$;
    }

    if (this.openRef) {
      this.openRef.afterClosed$().subscribe(() => toastMessage.instance.enter());
      this.openRef.close();
    } else {
      toastMessage.instance.enter();
    }
    this.overlayRef = overlayRef;
    ref.afterClosed$().subscribe(() => {
      overlayRef.detach();

      delete this.openRef;
    });

    timer(configWithDefaults.duration ?? this.DEFAULT_DURATION)
      .pipe(takeUntil(ref.afterClosed$()))
      .subscribe(() => ref.close());

    this.openRef = ref;

    return ref;
  }

  public close(): void {
    this.openRef?.close();
  }

  private updatePositionStrategy(elementRef: ElementRef): void {
    this.positionStrategy = this.createPositionStrategy(elementRef);
    if (this.overlayRef) {
      this.overlayRef.updatePositionStrategy(this.positionStrategy);
    }
  }

  private createPositionStrategy(elementRef: ElementRef): FlexibleConnectedPositionStrategy {
    return this.overlayPositionBuilder
      .flexibleConnectedTo(elementRef)
      .setOrigin(elementRef)
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top'
        }
      ]);
  }

  private createOverlay(config: ToastMessageConfig): OverlayRef {
    return this.overlay.create({
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy: this.elementRef
        ? this.createPositionStrategy(this.elementRef)
        : this.overlayPositionBuilder.global().centerHorizontally().top(config.topOffset),
      hasBackdrop: false,
      panelClass: config.panelClass,
      maxWidth: config.maxWidth,
      width: config.width
    });
  }

  private createInjector(config: ToastMessageConfig, ref: ToastMessageRef): Injector {
    const data: ToastMessageData = { config, ref };

    const providers: StaticProvider[] = [{ provide: TOAST_MESSAGE_DATA, useValue: data }];

    return Injector.create({ parent: this.injector, providers });
  }
}
