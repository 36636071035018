<div class="payment-icon rounded-1" [class.big-icons]="bigIcons">
  <svg-icon title="PayPal" key="pay-pal" [fontSize]="(breakpointService.upMd$ | async) ? '28px' : '22px'"></svg-icon>
</div>
<div class="payment-icon rounded-1" [class.big-icons]="bigIcons">
  <svg-icon title="iDEAL" key="ideal" [fontSize]="(breakpointService.upMd$ | async) ? '28px' : '22px'"></svg-icon>
</div>
<div class="payment-icon rounded-1" [class.big-icons]="bigIcons">
  <svg-icon title="Mastercard" key="master-card" [fontSize]="(breakpointService.upMd$ | async) ? '28px' : '22px'"></svg-icon>
</div>
<div class="payment-icon rounded-1" [class.big-icons]="bigIcons">
  <svg-icon title="Visa" key="visa" [fontSize]="(breakpointService.upMd$ | async) ? '28px' : '22px'"></svg-icon>
</div>
