<!-- eslint-disable @angular-eslint/template/use-track-by-function -->
<ng-container *ngrxLet="vm$; let vm">
  <footer class="container body-tiny pt-3">
    <hr class="mb-6 color-bg700" />
    <div class="d-flex flex-column flex-md-row justify-content-between align-items-md-between gap-6">
      <div class="d-flex flex-column justify-content-between align-items-md-between gap-6">
        <div class="d-flex flex-column flex-md-row justify-content-between gap-7">
          <ng-container *ngTemplateOutlet="footerLogos"></ng-container>
        </div>
        <ng-container *ngIf="breakpointService.upMd$ | async">
          <ng-container *ngTemplateOutlet="footerSocial"></ng-container>
        </ng-container>
      </div>

      <div class="d-flex flex-column justify-content-between align-items-md-start gap-3">
        <ng-container *ngTemplateOutlet="footerMenu"></ng-container>
        <div class="d-flex flex-column-reverse flex-md-row justify-content-between w-100">
          <ng-container *ngIf="(breakpointService.upMd$ | async) === false">
            <ng-container *ngTemplateOutlet="footerSocial"></ng-container>
          </ng-container>
          <div
            [class.flex-column-reverse]="vm.textContents | invoke: doesTextContentHaveMutlipleIcons"
            class="d-flex flex-md-row flex-column justify-content-between align-items-md-center gap-3 gap-md-7 mb-7 mb-md-3 w-100"
          >
            <div class="d-flex align-items-start mt-0">
              <app-payment-icons></app-payment-icons>
            </div>
            <ng-container *ngTemplateOutlet="footerAccreditations"></ng-container>
          </div>
        </div>
      </div>
    </div>
  </footer>

  <ng-template #footerLogos>
    <div class="logos d-flex flex-column gap-3">
      <div
        *ngFor="let logo of vm.logos"
        class="dd-logo"
        waIntersectionObserver
        appLazyLoadBackground
        [image]="logo.responsiveImage?.src ?? logo.url"
      ></div>
    </div>
  </ng-template>

  <ng-template #footerSocial>
    <div class="d-flex flex-column">
      <div class="social d-flex gap-2">
        <a
          *ngFor="let link of vm.socialMediaLinks"
          [href]="link.url"
          target="_blank"
          [title]="link.title"
          [appPushTagOnClick]="{
            event: TagEvent.SocialClick,
            type: 'facebook'
          }"
        >
          <img class="social-media-icon" loading="lazy" [src]="link.icon.url" [alt]="link.icon.url" />
          <span class="visually-hidden">{{ link.title }}</span>
        </a>
      </div>
      <span class="mt-2 body-small-medium">{{ 'footer.copyright' | translate }}</span>
    </div>
  </ng-template>

  <ng-template #footerMenu>
    <nav class="menu body-small body-tiny-md">
      <div class="d-flex flex-column" *ngFor="let group of vm.groups">
        <app-footer-group [group]="group"></app-footer-group>
      </div>
    </nav>
  </ng-template>

  <ng-template #footerAccreditations>
    <div class="d-flex flex-column justify-content-end d-md-grid gap-3">
      <div
        *ngFor="let textContent of vm.textContents"
        class="d-flex align-items-center gap-1-5"
        [class.flex-row-reverse]="textContent?.imageSide === 'right'"
      >
        <div class="d-flex gap-1">
          <img
            *ngFor="let image of textContent.images"
            loading="lazy"
            class="certificates-logo flex-shrink-0 mb-0"
            [src]="image?.url"
            [alt]="image?.alt"
          />
        </div>
        <div class="d-flex flex-column gap-2">
          <h2 *ngIf="textContent.title" class="body-normal-bold mb-0">{{ textContent.title }}</h2>
          <div
            class="inner-html-paragraph body-small-md body-tiny text-content-container"
            [innerHTML]="textContent.content | safeHtml"
          ></div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
