<ng-container *ngIf="product.inStock; else notifyMeButton">
  <button
    *ngrxLet="itemCount$ as itemCount"
    ddButton
    class="button d-flex flex-1 position-relative"
    leftIcon="shopping-cart"
    size="normal"
    [inverse]="inverseButton"
    [disabled]="itemCount >= product.quantityAvailable"
    (click)="onAddToCartClick($event)"
  >
    {{ 'product.add-to-cart' | translate }}
  </button>
</ng-container>

<ng-template #notifyMeButton>
  <button
    *ngIf="enableInventoryNotification"
    ddButton
    class="button flex-1"
    [inverse]="inverseButton"
    size="normal"
    (click)="onNotifyMeClick($event)"
  >
    {{ 'inventory-notification.notify-me-button' | translate }}
  </button>
</ng-template>
