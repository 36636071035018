import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { BreakpointService } from '@core/services/breakpoint.service';
import { LanguageService } from '@core/modules/language/language.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DropdownConfig } from '@shared/components/dropdown/dropdown-trigger-config';
import { DropdownTriggerDirective } from '@shared/components/dropdown/dropdown-trigger.directive';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import { TagEvent } from '@shared/enums/tags/tag-event.enum';
@UntilDestroy()
@Component({
  selector: 'app-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguagePickerComponent implements OnInit, AfterViewInit {
  @Output() public languagePickerOpened: EventEmitter<void> = new EventEmitter<void>();
  @Output() public languagePickerClosed: EventEmitter<void> = new EventEmitter<void>();
  @Output() public languageChanged: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild(DropdownTriggerDirective) private readonly dropdownTrigger!: DropdownTriggerDirective;
  public dropdownConfig: DropdownConfig = {
    viewContainerRef: this.vcr,
    panelClass: ['shadow-normal', 'rounded-3', 'background-bg100', 'p-1'],
    hasBackdrop: false,
    disposeOnOutsideClick: true,
    offsetY: 22
  };
  public currentLanguageFlag$: BehaviorSubject<string> = new BehaviorSubject<string>('en');
  public isOpen$ = new BehaviorSubject<boolean>(false);
  public readonly TagEvent = TagEvent;

  constructor(
    private readonly languageService: LanguageService,
    private readonly vcr: ViewContainerRef,
    public readonly breakpointService: BreakpointService
  ) {}

  public ngOnInit(): void {
    this.subscribeToLanguageChange();
  }

  public ngAfterViewInit(): void {
    if (this.dropdownTrigger) {
      this.isOpen$ = this.dropdownTrigger.isDropdownOpen$;
    }
  }

  public selectedFlag(selectedLanguage?: string): string {
    switch (selectedLanguage) {
      case 'en': {
        return 'uk-flag';
      }
      case 'nl': {
        return 'nl-flag';
      }
      default: {
        return 'uk-flag';
      }
    }
  }

  public switchLanguage(language: string): void {
    this.languageService.switchLanguage(language);
    this.currentLanguageFlag$.next(this.selectedFlag(language));
    this.languageChanged.emit();
    this.dropdownTrigger?.toggleDropdown();
  }

  private subscribeToLanguageChange(): void {
    this.languageService.currentLanguage$.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe((language) => {
      this.currentLanguageFlag$.next(this.selectedFlag(language));
    });
  }
}
