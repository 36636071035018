import { createReducer, on } from '@ngrx/store';
import { LoadableStateReducerHelper } from '@shared/helpers/loadable-state-reducer.helper';
import { Loadable } from '@shared/interfaces/loadable.interface';
import { ProductItem } from '@shared/interfaces/product.interface';
import { DatoArticle } from '@core/services/dato/interfaces/article.interface';
import * as Actions from './article.actions';

export const featureKeyKnowledgeBaseArticle = 'knowledgeBaseArticle';

export interface FeatureStateKnowledgeBaseArticle {
  article: Loadable<DatoArticle>;
  relatedProduct: Loadable<ProductItem>;
}

export interface AppState {
  [featureKeyKnowledgeBaseArticle]: FeatureStateKnowledgeBaseArticle;
}

export const initialStateKnowledgeBaseArticle: FeatureStateKnowledgeBaseArticle = {
  article: { isLoading: false },
  relatedProduct: { isLoading: false }
};

export const reducerKnowledgeBaseArticle = createReducer(
  initialStateKnowledgeBaseArticle,
  on(Actions.getArticle, (state) => ({
    ...state,
    article: LoadableStateReducerHelper.startLoad<DatoArticle>()
  })),
  on(Actions.getArticleSuccess, (state, { article }) => ({
    ...state,
    article: LoadableStateReducerHelper.loadSuccess(article),
    // eslint-disable-next-line unicorn/no-useless-undefined
    relatedProduct: initialStateKnowledgeBaseArticle.relatedProduct
  })),
  on(Actions.getArticleError, (state, { error }) => ({
    ...state,
    article: LoadableStateReducerHelper.loadError(state.article, error.message)
  })),
  on(Actions.getArticleRelatedProductSuccess, (state, { product }) => ({
    ...state,
    relatedProduct: LoadableStateReducerHelper.loadSuccess(product)
  })),
  on(Actions.getArticleRelatedProductError, (state, { error }) => ({
    ...state,
    relatedProduct: LoadableStateReducerHelper.loadError(state.relatedProduct, error.message)
  }))
);
