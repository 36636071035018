import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouteSegment } from '@shared/enums/route-segment.enum';
import { FooterGroupRecordType } from '@core/services/dato/interfaces/footer-group-record-type.enum';
import { LinkRecordType } from '@core/services/dato/interfaces/link-record-type.enum';
import { FooterGroupType } from '@core/services/dato/types/footer-group.type';

@Component({
  selector: 'app-footer-group',
  templateUrl: './footer-group.component.html',
  styleUrls: ['./footer-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterGroupComponent {
  @Input() public group!: FooterGroupType;
  @Input() public isDeepGroup = false;

  public readonly RouteSegment = RouteSegment;
  public readonly FooterGroupRecordType = FooterGroupRecordType;
  public readonly FooterLinkRecordType = LinkRecordType;
}
