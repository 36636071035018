import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Product } from '@dd/shop-client-sdk';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { mapApiErrorResponse } from 'src/domain/mappers/error';
import { KiyohReviewProviderService } from '@shared/services/review-provider/review-provider.service';
import { mapToReviewStarsConfig } from '../../../../../domain/mappers/map-review';
import { getStoreReviews, getStoreReviewsError, getStoreReviewsSuccess } from './reviews.actions';

const productCache: Map<string, Product> = new Map();

@Injectable()
export class ReviewsEffects {
  // eslint-disable-next-line unicorn/consistent-function-scoping
  public getStoreReviews$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getStoreReviews),
      switchMap(() => {
        return this.reviewProviderService.getPageReviews$().pipe(
          map((storeReviews) => {
            return getStoreReviewsSuccess({ storeReviews: mapToReviewStarsConfig(storeReviews) });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(getStoreReviewsError({ error: mapApiErrorResponse(error) }));
          })
        );
      })
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly reviewProviderService: KiyohReviewProviderService
  ) {}
}
