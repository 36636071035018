import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { CONFIG_TOKEN } from 'config-token';
import { AppModule } from './app/app.module';
import { Config } from './app/config';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', async (): Promise<void> => {
  const response = await fetch('config.json');
  const config: Config = await response.json();
  platformBrowserDynamic([
    {
      provide: CONFIG_TOKEN,
      useValue: config
    }
  ])
    .bootstrapModule(AppModule)
    .catch((error: Error): void => console.error(error));
});
