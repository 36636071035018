import { createAction, props } from '@ngrx/store';
import { DatoFooter } from '@core/services/dato/interfaces/footer.interface';
import { DatoApiError } from '../../../../../domain/dato-api-error';

const feature = '[Footer]';

export const getFooter = createAction(`${feature} get footer`);

export const getFooterSuccess = createAction(`${feature} get footer success`, props<{ footer: DatoFooter }>());

export const getFooterError = createAction(`${feature} get footer error`, props<{ error: DatoApiError }>());
