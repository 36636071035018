<svg viewBox="0 0 136 135" [attr.width]="size" [attr.height]="size" class="d-flex" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="64.5" cy="64" r="64" fill="#FFF6ED" />
  <path
    d="M71.17 88.78c-17.13 1.43-28.8-8.82-30.37-18.68-2.15-.37-6.45-1.8-6.45-4.6 0-3.5 8.5-11.5 12.5-20.5s7.5-5.5 18.5-5.5 23.5 13 26.84 15.8c1.56 10.13-3.11 32-21.02 33.48Z"
    fill="#fff"
  />
  <circle r="2.36" transform="scale(-1 1) rotate(-1.43 2259.93 3111.36)" fill="#42649B" />
  <circle r="2.36" transform="scale(-1 1) rotate(-1.43 2338.43 2146.77)" fill="#42649B" />
  <path
    d="M65.9 57.12c.88.88 2.1 2.3 2.95 3.69.43.7.73 1.35.86 1.88.13.55.06.82-.04.96l1.67 1.1c.53-.79.5-1.73.31-2.53a9 9 0 0 0-1.09-2.45 23.38 23.38 0 0 0-3.24-4.06l-1.41 1.41Zm3.77 6.53a4.1 4.1 0 0 1-3.06 1.89v2c1.06 0 3.27-.59 4.73-2.78l-1.67-1.11Z"
    fill="#42649B"
  />
  <path d="M62.72 75.1c1.82.78 6.07 1.1 8.57-.77" stroke="#42649B" stroke-width="2" />
  <path
    d="M53.38 50.19c6.85 0 9.08-4.93 10.12-8.57 2.6 3.64 10.9 8.57 17.13 8.57 6.23 0 10.38 4.67 11.68 7 3.89-3.89 9.34-12.92 0-17.9C84.52 27.6 72.06 21.38 63.5 25.27c-6.85 3.12-9.6 5.97-10.12 7.01-5.97-1.56-18.7-1.25-21.8 12.46C27.68 61.87 26.12 79 44.8 79l-3.9-6.23c-1.29.26-4.35-.63-6.22-6.23-1.87-5.6 4.41-5.97 7.79-5.45.77-2.34 4.04-10.9 10.9-10.9Z"
    fill="#B5CBEE"
  />
  <path
    d="m68.17 86.78.08 1-.08-1ZM36.82 68.25c1.65 10.5 13.9 20.99 31.43 19.53l-.16-2c-16.73 1.4-27.84-8.6-29.3-17.84l-1.97.31Zm31.43 19.53c9.43-.79 15.33-6.96 18.64-14.08 3.3-7.1 4.1-15.3 3.3-20.55l-1.99.3c.75 4.87 0 12.67-3.13 19.4-3.11 6.72-8.5 12.23-16.98 12.94l.16 1.99Z"
    fill="#42649B"
  />
  <path
    d="m32.35 60.36.95.31-.95-.3Zm5.82 8.36a9.9 9.9 0 0 1-4.1-2.85c-1-1.28-1.5-2.95-.77-5.2l-1.9-.62a7.23 7.23 0 0 0 1.08 7.04 11.87 11.87 0 0 0 4.95 3.5l.74-1.87Zm-4.87-8.05a3.2 3.2 0 0 1 1.34-1.84c.54-.32 1.2-.45 1.94-.4 1.52.08 3.05.86 3.66 1.42l1.36-1.46a8.6 8.6 0 0 0-4.9-1.96 5.33 5.33 0 0 0-3.08.68 5.15 5.15 0 0 0-2.22 2.94l1.9.62Z"
    fill="#42649B"
  />
  <path
    d="M81.85 81c8.5 0 14 9.5 20 25.5M75.35 85c-2 3.5-8.2 8.7-15 1.5M44.03 79c-5.97 3.63-12.18 4.5-17.18 25.5"
    stroke="#42649B"
    stroke-width="2"
  />
  <path
    d="m60.38 38.5.82-.57-1.2-1.69-.58 2 .96.27ZM89.2 54.09l-.87.49.64 1.16.94-.94-.7-.7Zm0-17.9-.83.55.14.2.22.13.47-.89ZM60.4 22.15l.4.9-.4-.9Zm-10.13 7-.25.97.78.2.37-.72-.9-.44Zm-21.8 12.46.98.23-.98-.23Zm30.96-3.39a12.95 12.95 0 0 1-2.65 5.25c-1.36 1.48-3.36 2.6-6.5 2.6v2c3.7 0 6.23-1.35 7.97-3.25a14.91 14.91 0 0 0 3.1-6.05l-1.92-.55Zm-9.16 7.84c-3.89 0-6.68 2.44-8.53 4.99a25.26 25.26 0 0 0-3.32 6.6l1.9.63a23.27 23.27 0 0 1 3.03-6.05c1.7-2.32 3.96-4.17 6.92-4.17v-2Zm9.31-6.98c1.43 2 4.3 4.23 7.55 5.95 3.25 1.72 7.05 3.03 10.4 3.03v-2c-2.9 0-6.36-1.15-9.46-2.8-3.12-1.65-5.7-3.7-6.86-5.34l-1.63 1.16Zm17.94 8.98c5.72 0 9.6 4.33 10.8 6.5l1.76-.97c-1.4-2.5-5.82-7.53-12.56-7.53v2ZM89.9 54.8c2.02-2.02 4.5-5.42 5.24-9.06.37-1.84.3-3.78-.55-5.62-.85-1.85-2.44-3.5-4.93-4.82l-.94 1.77c2.19 1.16 3.42 2.52 4.05 3.89.64 1.37.72 2.86.4 4.39-.62 3.1-2.8 6.16-4.68 8.03l1.41 1.42Zm.13-19.17c-3.98-5.97-9.17-10.57-14.5-13.2-5.33-2.6-10.91-3.28-15.56-1.17l.83 1.82c3.92-1.78 8.85-1.3 13.84 1.15 4.98 2.45 9.91 6.8 13.72 12.5l1.67-1.1ZM59.97 21.25a39.43 39.43 0 0 0-7.63 4.4c-1.64 1.26-2.6 2.34-2.97 3.07l1.79.9c.15-.32.82-1.18 2.4-2.39a37.47 37.47 0 0 1 7.24-4.16l-.83-1.82Zm-9.45 6.95a21.93 21.93 0 0 0-12.58.53c-4.61 1.71-8.82 5.48-10.45 12.67l1.95.45c1.48-6.51 5.19-9.76 9.2-11.24A19.94 19.94 0 0 1 50 30.13l.5-1.93ZM27.49 41.4c-1.95 8.55-3.37 17.33-1.85 24 .76 3.37 2.3 6.28 4.95 8.33 2.64 2.05 6.29 3.15 11.1 3.15v-2c-4.51 0-7.68-1.03-9.88-2.73-2.19-1.7-3.53-4.14-4.22-7.2-1.4-6.17-.1-14.52 1.85-23.1l-1.95-.45Z"
    fill="#42649B"
  />
  <path d="M49.85 29c.5-6.5-3.4-8.3-7-5.5" stroke="#42649B" stroke-width="2" />
  <circle cx="72" cy="71" r="63" stroke="#FDDBBB" stroke-width="2" />
</svg>
