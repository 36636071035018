import { Injectable } from '@angular/core';
import { DatoService } from '@core/services/dato/dato.service';
import { gql } from '@apollo/client';
import { map, Observable } from 'rxjs';
import { PageService } from '@shared/services/page.service';
import { DatoProductsPage } from '@core/services/dato/interfaces/products-page.interface';

@Injectable({
  providedIn: 'root'
})
export class DatoProductsPageDataService {
  constructor(
    private readonly datoService: DatoService,
    private readonly pageService: PageService
  ) {}

  public getProductsPageData$(locale: string = 'en', disableCache = false): Observable<DatoProductsPage> {
    return this.datoService
      .get$<{ productsPage: DatoProductsPage }>(
        gql`
          query getProductsPage {
            productsPage(locale: ${locale})  {
              title
              subtitle
              enablePromoBar
              promoBarText
              showReviewStars
              promoIcon {
                url
                alt
              }
              backgroundColor {
                hex
              }
              meta: _seoMetaTags {
                content
                attributes
                tag
              }
            }
          }
        `,
        disableCache,
        { locale }
      )
      .pipe(
        map((res) => {
          this.pageService.setMeta(res.productsPage.meta);
          return res.productsPage;
        })
      );
  }
}
