import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/auth/auth.guard';
import { LanguageParamGuard } from '@core/modules/language/guards/language-param.guard';
import { RouteSegment } from '@shared/enums/route-segment.enum';
import { of } from 'rxjs';
import { languagePrefixMatcher } from '@core/modules/language/matchers/language-prefix-matcher';
import { ShopModule } from '@shop/shop.module';

const routes: Routes = [
  {
    matcher: languagePrefixMatcher,
    children: [
      {
        path: RouteSegment.Dashboard,
        loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard]
      },
      {
        path: RouteSegment.Root,
        loadChildren: () => of(ShopModule)
      }
    ],
    canActivate: [LanguageParamGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      paramsInheritanceStrategy: 'always'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
