import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectProfileVM } from '@shared/store/profile/profile.selectors';
import {
  getNewsletterContent,
  getSubscriptionStatus,
  subscribeToNewsletter,
  subscribeToNewsletterReset,
  unsubscribeFromNewsletter
} from '@shared/store/newsletter/newsletter.actions';
import { selectNewsletterContent, selectNewsletterSubscription } from '@shared/store/newsletter/newsletter.selectors';
import { filter } from 'rxjs';
import { isNotUndefined } from '@shared/utils/not-undefined';

@Injectable()
export class NewsletterService {
  public vm$ = this.store.select(selectNewsletterSubscription);
  public newsletterContent$ = this.store.select(selectNewsletterContent).pipe(filter(isNotUndefined));
  public profile$ = this.store.select(selectProfileVM);

  constructor(private readonly store: Store) {}

  public getNewsletterContent(): void {
    this.store.dispatch(getNewsletterContent());
  }

  public getSubscriptionStatus(): void {
    this.store.dispatch(getSubscriptionStatus());
  }

  public subscribeToNewsletter(email: string, showToast = false): void {
    this.store.dispatch(subscribeToNewsletter({ email, showToast }));
  }

  public unsubscribeFromNewsletter(showToast = false): void {
    this.store.dispatch(unsubscribeFromNewsletter({ showToast }));
  }

  public subscribeToNewsletterReset(): void {
    this.store.dispatch(subscribeToNewsletterReset());
  }
}
