import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { MetaReducer, StoreModule, USER_PROVIDED_META_REDUCERS } from '@ngrx/store';
import { localStorageSyncReducer } from 'src/app/stores/meta-reducers/storage-sync.meta-reducer';
import { ProfileEffects } from './profile.effects';
import { featureKeyProfile, reducerProfile } from './profile.reducer';

export const metaReducerFactory = (): MetaReducer<unknown>[] => {
  return [localStorageSyncReducer()];
};

@NgModule({
  declarations: [],
  imports: [StoreModule.forFeature(featureKeyProfile, reducerProfile), EffectsModule.forFeature([ProfileEffects])],
  providers: [
    {
      provide: USER_PROVIDED_META_REDUCERS,
      useFactory: metaReducerFactory
    }
  ]
})
export class ProfileStoreModule {}
