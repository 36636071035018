<div [class.disabled]="control.disabled" [class.error]="hasError">
  <div class="position-relative">
    <label *ngIf="label" title="{{ label }}" (click)="focusInput()" class="form-label body-tiny-medium">
      {{ label }}
      <span class="color-text300" *ngIf="showOptionalLabel && !required">({{ 'common.optional' | translate }})</span>
    </label>
    <textarea
      #textarea
      class="form-input"
      [(ngModel)]="nativeInputValue"
      (ngModelChange)="onValueChange($event)"
      [attr.disabled]="control.disabled || null"
      [attr.minLength]="minLength"
      [attr.maxlength]="maxLength"
      [placeholder]="placeholder"
      [style.resize]="resize"
      [rows]="rows"
      (focus)="focused = true"
      (blur)="focused = false; onBlur()"
    ></textarea>
    <div class="suffix body-tiny" [style.width]="suffixWidth$ | async" *ngIf="(maxLength || minLength) && !control.disabled">
      <ng-container *ngIf="maxLength && !minLength; then maxLengthCounter"></ng-container>
      <ng-container *ngIf="minLength && !maxLength && nativeInputValue.length < minLength; then minLengthCounter"></ng-container>
      <ng-container *ngIf="minLength && maxLength">
        <ng-container *ngIf="nativeInputValue.length < minLength; then minLengthCounter; else maxLengthCounter"></ng-container>
      </ng-container>
    </div>
    <small *ngIf="hint || (hasError && error)" [style.width]="suffixWidth$ | async" class="form-hint body-tiny">
      {{ hasError ? error : hint }}
    </small>
  </div>
</div>

<ng-template #maxLengthCounter>
  <span class="suffix-content">{{ nativeInputValue.length || 0 }}/{{ maxLength }}</span>
</ng-template>
<ng-template #minLengthCounter>
  <span class="suffix-content">{{ 'min-characters' | translate: { minLength } }}</span>
</ng-template>
