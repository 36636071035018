import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { TagPusherService } from '@core/services/tag-pusher.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, fromEvent } from 'rxjs';
import { Tag } from '../../interfaces/tags/tag.type';

@UntilDestroy()
@Directive({
  selector: '[appPushTagOnClick]'
})
export class PushTagOnClickDirective implements OnInit {
  @Input() public appPushTagOnClick?: Tag;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly tagPusherService: TagPusherService
  ) {}

  public ngOnInit(): void {
    fromEvent<MouseEvent>(this.elementRef.nativeElement, 'click', {
      capture: true
    })
      .pipe(
        filter(() => !!this.appPushTagOnClick),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.tagPusherService.pushTag(this.appPushTagOnClick!);
      });
  }
}
