import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-empty-illustration',
  templateUrl: './empty-illustration.component.html',
  styleUrls: ['./empty-illustration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyIllustrationComponent {
  @Input() public cloudColor = '#F3F7FD';
  @Input() public shadowColor = '#E8F0FD';
}
