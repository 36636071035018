<div #content class="scroll-content" [ngClass]="contentClass">
  <ng-content></ng-content>
</div>
@if (hasHorizontalBar$ | async) {
  <div class="d-flex justify-content-center">
    <div #horizontalBar class="bar bar-horizontal mb-4" (mousedown)="onScrollbarClick($event)">
      <div
        #horizontal
        class="thumb"
        [class.thumb-active]="horizontalThumbActive"
        (mousedown)="onHorizontalStart($event)"
        (touchstart)="onHorizontalTouchStart($event)"
      ></div>
    </div>
  </div>
}
@if (hasVerticalBar$ | async) {
  <div class="d-flex vertical-bar-container align-items-center">
    <div #verticalBar class="bar bar-vertical" (mousedown)="onVerticalScrollbarClick($event)">
      <div
        #vertical
        class="thumb"
        [class.thumb-active]="verticalThumbActive"
        (mousedown)="onVerticalStart($event)"
        (touchstart)="onVerticalTouchStart($event)"
      ></div>
    </div>
  </div>
}
