import { Pipe, PipeTransform } from '@angular/core';

/**
 * A pipe that takes two params: a translation key and a counting number. From the counting number,
 * it determines whether the plural, singular or none form of the key should be used.
 *
 * @example
 * {{ 'cart.header' | pluralTranslate: vm.itemCount | translate: { itemCount: vm.itemCount } }}
 *
 * @example
 * i18n file:
 *   "header": {
 *    "none": "You have no items in your order"
 *    "singular": "You have {{ itemCount }} item in your order",
 *    "plural": "You have {{ itemCount }} items in your order"
 *  },
 */
@Pipe({
  name: 'pluralTranslate'
})
export class PluralTranslatePipe implements PipeTransform {
  public transform(key: string, count: number): string {
    if (count === 0) {
      return `${key}.none`;
    }
    return `${key}.${count === 1 ? 'singular' : 'plural'}`;
  }
}
