import { Overlay, OverlayConfig, OverlayRef, PositionStrategy, ScrollStrategy } from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  ElementRef,
  Inject,
  Injectable,
  InjectionToken,
  Injector,
  OnDestroy,
  Optional,
  StaticProvider,
  TemplateRef
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, map, merge, Observable } from 'rxjs';
import { DropdownContainerComponent } from './dropdown-container/dropdown-container.component';
import { DropdownConfig, DROPDOWN_DATA } from './dropdown-trigger-config';
import { DropdownRef } from './dropdown-ref';

export const DROPDOWN_TRIGGER_DEFAULT_OPTIONS = new InjectionToken<DropdownConfig>('dropdown-trigger-default-options');

/**
 * Applies default options to the dropdown config.
 *
 * @param defaults Object containing the default values to which to fall back.
 * @param config The configuration to which the defaults will be applied.
 * @returns The new configuration object with defaults applied.
 */
const applyConfigDefaults = (defaults: DropdownConfig, config?: DropdownConfig): DropdownConfig => {
  return { ...defaults, ...config };
};

@UntilDestroy()
@Injectable()
export class DropdownService implements OnDestroy {
  private isDropdownOpen = false;
  private overlayRef!: OverlayRef;
  private currentDropdownTriggerRef!: DropdownRef;
  private containerComponent!: DropdownContainerComponent;
  private elementRef!: ElementRef<HTMLElement>;
  private currentDropdownConfig: DropdownConfig = {};
  private readonly openDropdownRefs$ = new BehaviorSubject<DropdownRef<unknown>[]>([]);

  constructor(
    private readonly overlay: Overlay,
    private readonly injector: Injector,
    @Optional()
    @Inject(DROPDOWN_TRIGGER_DEFAULT_OPTIONS)
    private readonly defaultOptions?: DropdownConfig
  ) {}

  public toggleDropdown(elementRef: ElementRef<HTMLElement>, config: DropdownConfig): DropdownRef {
    if (this.isDropdownOpen) {
      this.destroyDropdown();
    } else {
      this.elementRef = elementRef;
      this.openDropdown(config);
    }
    this.currentDropdownConfig = config;
    return this.currentDropdownTriggerRef;
  }

  public ngOnDestroy(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  public openDropdown(config: DropdownConfig, elementRef?: ElementRef): DropdownRef {
    if (this.isDropdownOpen) {
      return this.currentDropdownTriggerRef;
    }
    if (elementRef) {
      this.elementRef = elementRef;
    }
    this.isDropdownOpen = true;
    const _config = applyConfigDefaults(this.defaultOptions || new DropdownConfig(), config);
    this.createOverlay(_config);
    const container = this.attachContainer(this.overlayRef, _config);
    const ref = new DropdownRef(container, this.overlayRef);

    if (_config.componentOrTemplateRef instanceof TemplateRef) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      container.attachTemplatePortal(new TemplatePortal(_config.componentOrTemplateRef, null!, {}));
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const portal = new ComponentPortal(_config.componentOrTemplateRef!, null!, this.createInjector(_config, ref));
      const contentRef = container.attachComponentPortal(portal);
      ref.instance = contentRef.instance;
    }

    this.dropdownClosingActions$()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.destroyDropdown();
        this.openDropdownRefs$.next(this.openDropdownRefs$.getValue().filter((openDialogRef) => openDialogRef !== ref));
      });

    this.currentDropdownTriggerRef = ref;
    this.containerComponent = container;
    this.openDropdownRefs$.next([...this.openDropdownRefs$.getValue(), ref]);
    return ref;
  }

  public destroyDropdown(): void {
    if (!this.overlayRef || !this.isDropdownOpen) {
      return;
    }

    this.isDropdownOpen = false;
    this.overlayRef.detach();
  }

  public hasOpenDropdown$(): Observable<boolean> {
    return this.openDropdownRefs$.pipe(map((dropdownRefs) => dropdownRefs.length > 0));
  }

  // Update the dropdown's position with a newly provided config
  public updateDropdownPosition(config: DropdownConfig): void {
    if (!this.overlayRef) {
      return;
    }
    const _config = applyConfigDefaults(this.defaultOptions || new DropdownConfig(), config);
    this.overlayRef.updatePositionStrategy(this.getPositionStrategy(_config));
  }

  private dropdownClosingActions$(): Observable<MouseEvent | void> {
    const backdropClick$ = this.overlayRef.backdropClick();
    const detachment$ = this.overlayRef.detachments();

    return merge(backdropClick$, detachment$);
  }

  private createInjector<T>(config: DropdownConfig, dropdownTriggerRef: DropdownRef<T>): Injector {
    const userInjector = config && config.viewContainerRef && config.viewContainerRef.injector;
    const providers: StaticProvider[] = [
      { provide: DropdownRef, useValue: dropdownTriggerRef },
      { provide: DROPDOWN_DATA, useValue: config.data$ || config.data }
    ];

    return Injector.create({ parent: userInjector || this.injector, providers });
  }

  private attachContainer(overlayRef: OverlayRef, config: DropdownConfig): DropdownContainerComponent {
    const injector = Injector.create({
      parent: this.injector,
      providers: [{ provide: DropdownConfig, useValue: config }]
    });

    const containerPortal = new ComponentPortal(DropdownContainerComponent, undefined, injector);
    const containerRef: ComponentRef<DropdownContainerComponent> = overlayRef.attach(containerPortal);
    return containerRef.instance;
  }

  private getScrollStrategy(config: DropdownConfig): ScrollStrategy {
    switch (config.scrollStrategy) {
      case 'reposition': {
        return this.overlay.scrollStrategies.reposition();
      }
      case 'block': {
        return this.overlay.scrollStrategies.block();
      }
      case 'noop': {
        return this.overlay.scrollStrategies.noop();
      }
      case 'close': {
        return this.overlay.scrollStrategies.close();
      }
      default: {
        return this.overlay.scrollStrategies.reposition();
      }
    }
  }

  private getEndPositionStrategy(marginTop: number = 0): PositionStrategy {
    return this.overlay.position().global().end().top(`${marginTop}px`);
  }

  private getFlexiblePositionStrategy(config: DropdownConfig): PositionStrategy {
    return this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .setOrigin(this.elementRef)
      .withFlexibleDimensions(true)
      .withPositions([
        {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          originX: config.originX!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          originY: config.originY!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          overlayX: config.overlayX!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          overlayY: config.overlayY!,
          offsetX: config.offsetX,
          offsetY: config.offsetY
        }
      ])
      .withLockedPosition(config.lockPosition);
  }

  private getPositionStrategy(config: DropdownConfig): PositionStrategy {
    return config.endPositionStrategy ? this.getEndPositionStrategy(config.offsetY) : this.getFlexiblePositionStrategy(config);
  }

  private createOverlay(config: DropdownConfig): void {
    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      scrollStrategy: this.getScrollStrategy(config),
      maxHeight: config.maxHeight,
      maxWidth: config.maxWidth,
      minWidth: config.minWith,
      width: config.width,
      disposeOnNavigation: config.disposeOnNavigation,
      positionStrategy: config.positionStrategy ?? this.getPositionStrategy(config),
      panelClass: config.overlayPanelClass
    });
    this.overlayRef = this.overlay.create(overlayConfig);
  }
}
