import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  Optional,
  Self,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { FormControl, ControlValueAccessor } from '@ngneat/reactive-forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent extends ControlValueAccessor<boolean> implements AfterContentInit, AfterViewInit, OnChanges {
  @Input() public name = '';
  @Input() public hint?: string;
  @Input() public error?: string | null;
  @Input() public indeterminate = false;
  @Input() public switch = false;
  @Input() public size: 'small' | 'normal' = 'normal';

  @ViewChild('input') public input!: ElementRef<HTMLInputElement>;

  public focused = false;
  public disabled?: boolean;

  public constructor(
    @Optional() @Self() public ngControl: NgControl,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public get hasError(): boolean {
    return Boolean(this.control?.touched) && Boolean(this.control?.invalid) && Object.values(this.control?.errors || []).length > 0;
  }

  public get control(): FormControl<boolean> {
    return this.ngControl.control as FormControl<boolean>;
  }

  public ngAfterContentInit(): void {
    this.control.touch$.subscribe((_) => this.cdr.markForCheck());
  }

  public ngAfterViewInit(): void {
    if (this.input) {
      this.input.nativeElement.indeterminate = this.indeterminate;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.indeterminate && this.input) {
      this.input.nativeElement.indeterminate = changes.indeterminate.currentValue;
    }
  }

  public onBlur(): void {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  public onCheckboxChange(event: MouseEvent): void {
    if (this.control.disabled) {
      return;
    }

    const elementTarget = event.target as HTMLInputElement;
    const isChecked = elementTarget.checked;

    if (this.indeterminate) {
      this.indeterminate = false;
    }

    if (this.onChange) {
      this.onChange(isChecked);
    }
  }

  public writeValue(value: boolean): void {
    if (this.input) {
      this.input.nativeElement.checked = value;
    }
    this.cdr.markForCheck();
  }

  public onLabelClick(event: MouseEvent): void {
    if (this.control.disabled) {
      return;
    }
    if (!(event.target instanceof HTMLAnchorElement)) {
      event.preventDefault();
      this.control.setValue(!this.input.nativeElement.checked);
    }
  }
}
