import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TagPusherService } from '@core/services/tag-pusher.service';
import { NewsletterDataService } from '@dd/shop-client-sdk';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { TagEvent } from '@shared/enums/tags/tag-event.enum';
import { ToastMessageType } from '@shared/modules/toast-message/toast-message-type.enum';
import { ToastMessageService } from '@shared/modules/toast-message/toast-message.service';
import { catchError, filter, map, of, switchMap } from 'rxjs';
import { mapApiErrorResponse } from 'src/domain/mappers/error';
import { DatoHomeDataService } from '@core/services/dato/data-services/home-data.service';
import { ApolloError } from '@apollo/client';
import { Store } from '@ngrx/store';
import { mapDatoApiErrorResponse } from '../../../../domain/mappers/dato-error';
import { selectProfile } from '../profile/profile.selectors';
import {
  getNewsletterContent,
  getNewsletterContentError,
  getNewsletterContentSuccess,
  getSubscriptionStatus,
  getSubscriptionStatusError,
  getSubscriptionStatusSuccess,
  subscribeToNewsletter,
  subscribeToNewsletterError,
  subscribeToNewsletterSuccess,
  unsubscribeFromNewsletter,
  unsubscribeFromNewsletterError,
  unsubscribeFromNewsletterSuccess
} from './newsletter.actions';

@Injectable()
export class NewsletterEffects {
  // eslint-disable-next-line unicorn/consistent-function-scoping
  public getSubscriptionStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getSubscriptionStatus),
      concatLatestFrom(() => this.store.select(selectProfile)),
      filter(([, profile]) => !!profile.data),
      switchMap(() => {
        return this.newsletterDataService.getSubscriptionStatus().pipe(
          map((status) => getSubscriptionStatusSuccess({ status })),
          catchError((error: HttpErrorResponse) => {
            return of(getSubscriptionStatusError({ error: mapApiErrorResponse(error) }));
          })
        );
      })
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public getNewsletterContent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getNewsletterContent),
      switchMap(() => {
        return this.datoHomeDataService.getNewsletterContent$(this.translateService.currentLang).pipe(
          map((content) => {
            return getNewsletterContentSuccess({ content });
          }),
          catchError((error: ApolloError) => {
            return of(getNewsletterContentError({ error: mapDatoApiErrorResponse(error) }));
          })
        );
      })
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public subscribe$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(subscribeToNewsletter),
      switchMap(({ email, showToast }) => {
        return this.newsletterDataService.subscribeToNewsletter(email).pipe(
          map(() => {
            if (showToast) {
              this.showSuccessMessage();
            } else {
              this.tagPusherService.pushTag({
                event: TagEvent.NewsletterFormSent
              });
            }
            return subscribeToNewsletterSuccess();
          }),
          catchError((error: HttpErrorResponse) => {
            if (showToast) {
              this.showErrorMessage();
            } else {
              this.tagPusherService.pushTag({
                event: TagEvent.NewsletterFormError
              });
            }
            return of(subscribeToNewsletterError({ error: mapApiErrorResponse(error) }));
          })
        );
      })
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public unsubscribe$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(unsubscribeFromNewsletter),
      switchMap(({ showToast }) => {
        return this.newsletterDataService.unsubscribeFromNewsletter().pipe(
          map(() => {
            if (showToast) {
              this.showSuccessMessage();
            }
            return unsubscribeFromNewsletterSuccess();
          }),
          catchError((error: HttpErrorResponse) => {
            if (showToast) {
              this.showErrorMessage();
            }
            return of(unsubscribeFromNewsletterError({ error: mapApiErrorResponse(error) }));
          })
        );
      })
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly newsletterDataService: NewsletterDataService,
    private readonly datoHomeDataService: DatoHomeDataService,
    private readonly toastMessageService: ToastMessageService,
    private readonly translateService: TranslateService,
    private readonly tagPusherService: TagPusherService
  ) {}

  private showSuccessMessage(): void {
    this.toastMessageService.open(this.translateService.stream('profile.changes-saved'));
  }

  private showErrorMessage(): void {
    this.toastMessageService.open(this.translateService.stream('profile.an-error-occurred'), { type: ToastMessageType.Error });
  }
}
