import { Inject, Injectable, Injector } from '@angular/core';
import { NO_ANALYTICS_TOKEN } from '@core/tokens/no-analytics.token';
import { Tag } from '@shared/interfaces/tags/tag.type';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { CONFIG_TOKEN } from 'config-token';
import { Config } from 'protractor';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class TagPusherService {
  public clientId!: string;
  public sessionId!: string;
  private readonly googleTagManagerService?: GoogleTagManagerService;

  constructor(
    @Inject(CONFIG_TOKEN) private readonly config: Config,
    private readonly injector: Injector,
    private readonly cookieService: CookieService,
    @Inject(NO_ANALYTICS_TOKEN) private readonly noAnalytics: boolean
  ) {
    if (this.config.googleTagManagerId && !this.noAnalytics) {
      this.googleTagManagerService = this.injector.get(GoogleTagManagerService);
      this.googleTagManagerService.addGtmToDom().then(() => {
        this.disableConsentMode();
        this.getClientAndSessionId();
      });
    }
  }

  public gtag(...restArgs: unknown[]): void {
    if (!this.googleTagManagerService) {
      return;
    }

    // eslint-disable-next-line prefer-rest-params
    this.googleTagManagerService?.getDataLayer().push(arguments);
  }

  public pushTag(tag: Tag): void {
    if (!this.googleTagManagerService || !tag) {
      return;
    }

    this.googleTagManagerService?.pushTag(tag);
  }

  public getClientAndSessionId(): void {
    if (!this.googleTagManagerService) {
      return;
    }

    this.gtag('get', this.config.googleMeasurementId, 'client_id', (client_id: string) => {
      // Store the client ID in a variable.
      this.clientId = client_id;
    });
    this.gtag('get', this.config.googleMeasurementId, 'session_id', (session_id: string) => {
      // Store the session ID in a variable.
      this.sessionId = session_id;
    });
  }

  private disableConsentMode(): void {
    if (!this.googleTagManagerService) {
      return;
    }

    this.gtag('consent', 'default', {
      ad_storage: 'granted',
      analytics_storage: 'granted',
      wait_for_update: 500
    });
  }
}
